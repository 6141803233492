import { React, useState } from 'react';
import styles from './contextMenu.module.css'
import { Dropdown, Button } from 'antd';
import { MoreOutlined } from '@ant-design/icons';


function ContextMenu(props) {
  const menuOptions = props.menuOptions
  const [isVisible, setIsVisible] = useState(false)
 
  function handleButtonClick(event) {
    event.stopPropagation();
    setIsVisible(!isVisible)
  }

  return (
    <Dropdown className={styles.contextMenu}  visible={isVisible} overlay={menuOptions} >
      <Button onClick={handleButtonClick} icon={<MoreOutlined />}/>
    </Dropdown>
  );
};

export default ContextMenu
