import React from 'react';
import FormPayment from '../components/Payment/FormPayment';

function PaymentPage(props) {
  const { user, addCourseToUser } = props;
  return (
        <FormPayment user={ user } addCourseToUser={ addCourseToUser}/>
  )
}

export default PaymentPage