import React from 'react';
import DetaillCourse from '../components/DetaillCourse/DetailCourse.js';

function detaillCourse() {
  return (
    <>
      <DetaillCourse />
    </>
  )
}

export default detaillCourse;
