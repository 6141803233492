import clientAxios from "../../../../../config/clientAxios";
import { FilePdfTwoTone, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, message, Upload } from "antd"
import { useEffect, useState } from 'react';

export const PDFAttachment = ({ setPDFData, previousEmbebed }) => {
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [file, setFile] = useState();
  const [showFilePicker, setShowFilePicker] = useState(true);
  const { Dragger } = Upload;

  const savePDFToState = async({ file }) => {
    let base64Data = "";
    let reader = new FileReader();
    reader.onload = () => {
      base64Data = reader.result;
      uploadToAWS3(base64Data);
    };
    reader.readAsDataURL(file);
  }

  const uploadToAWS3 = (base64) => {
    setShowFilePicker(false);
    clientAxios.post(`/course/course-pdf/id`, { base64Data: base64 })
      .then(function (response) {
        setPDFData(response.data);
        setFile(response.data);
      })
      .catch(function (error) {
        setShowFilePicker(true);
        setDefaultFileList([])
        message.error(error.message)
        console.debug(error);
      });
  }

  const deleteFromAWS3 = () => {
    setDefaultFileList([]);
    setShowFilePicker(true);
    const key = file.split(".com/")[1]
    clientAxios.delete(`/course/course-pdf/${key}`, { key })
      .then(function (response) {
        setPDFData('');
        setFile('');
      })
      .catch(function (error) {
        setDefaultFileList([])
        message.error(error.message)
        console.debug(error);
      });
  }

  useEffect(() => {
    console.log(previousEmbebed, 'bs')
    if(previousEmbebed !== ''){
      setFile(previousEmbebed)
      setShowFilePicker(false)
    }
  }, [previousEmbebed])

  return(
    <>
      {
        !showFilePicker ?
          <>
            <p>Este pdf ya fue subido en... 
              <a href={file}>{file}</a><Button icon={<CloseCircleOutlined />} onClick={deleteFromAWS3} />
            </p>
          </>
        : <Col>
            <Dragger
              style={{height:'auto', marginBottom: '0.5rem'}}
              accept=".pdf"
              onChange={savePDFToState}
              beforeUpload={(file, fileList) => setDefaultFileList(fileList)}
              customRequest={(files) => savePDFToState(files)}
              fileList={defaultFileList}
              multiple={false}
              maxCount="1"
              onRemove={() => setDefaultFileList([])}
            >
              <p className="ant-upload-drag-icon">
                <FilePdfTwoTone />
              </p>
              <p className="ant-upload-text">Clickea o arrastra el archivo a esta area para ser subido</p>
              <p className="ant-upload-hint">
                Soporte de un archivo
              </p>
            </Dragger>
          </Col>
      }
    </>
  )
}