import React from 'react';
import { Row, Col } from 'antd';
import {
  MailOutlined,
  AimOutlined,
  PhoneOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styles from './footerPage.module.css';
import Logo from '../../assets/img/logo.png';

function FooterPage() {
  if (!process.env.REACT_APP_PLATFORM_LEGACYPAYMENTS_MODE)
  return (
    <div>
      <Row justify='center' align='middle' className={styles.infoRow}>
        <Col>
          <figure className={styles.centrado}>
            <img src={Logo} alt='rollingCode Logo' className={styles.logo} />
          </figure>
        </Col>
        <Col className={styles.icons}>
          <div className={styles.iconsContainer}>
            <p>
              <AimOutlined style={{ fontSize: '1.5rem' }} /> Gral. Paz 576, piso
              9 of 2
            </p>
            <p>
              <PhoneOutlined style={{ fontSize: '1.5rem' }} /> +54 381 5 783 030
            </p>
            <p>
              <MailOutlined style={{ fontSize: '1.5rem' }} /> hi@rollingcode.co
            </p>
            <p>
              <InstagramOutlined style={{ fontSize: '1.5rem' }} />{' '}
              RollingCodeSchool
            </p>
          </div>
        </Col>
      </Row>
      <Row justify='center' className={styles.copyrightRow}>
        <Col xs={12} className={styles.footer}>
          Copyright &copy; 2022 RollingCode.
        </Col>
        </Row>
    </div>  ) 
    else {
      return (<div></div>)
    }
}

export default FooterPage;
