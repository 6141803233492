import React, { useState, useEffect } from 'react';
import { List, Button, PageHeader, message, Popconfirm } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import clientAxios from '../../../config/clientAxios';

const ListQuizes = () => {
  const titleText = 'Listado de Quizes';
  const [quizes, setQuizes] = useState([])

  const getAllQuizzes = () => {
    clientAxios.get(`/quiz`)
      .then((response) => {
        setQuizes(response.data);
      })
      .catch((error) =>  message.error(error.message));
  }

  useEffect(() => {
    getAllQuizzes();
  }, [])

  const deleteQuiz = async (id) => {
    await clientAxios.delete(`/quiz/${id}`)
    getAllQuizzes();
  };

  const quizesToShow = quizes.filter(quiz => {return !quiz.deleted && quiz});
  
  return (
    <div>
      <PageHeader
          title={titleText}
          extra={[
            <Link to='/admin/quiz'>
            <Button 
              key="pageHeader-1" 
              type='primary' 
              shape='round' 
              icon={<PlusCircleOutlined />}
            >
                Crear
            </Button>
              </Link>
            ]}
      />
      <List
        bordered
        dataSource={quizesToShow}
        renderItem={item => (
          <List.Item
            actions={[
              <>
                  <Link to={`/admin/quiz/update/${item._id}`}>
                    <Button type='link'><EditOutlined /></Button>
                  </Link>
                <Popconfirm
                  title="¿Estas seguro que quieres borrar esta categoria completa?"
                  onConfirm={() => deleteQuiz(item._id)}
                >
                  <Button type='link' ><DeleteOutlined /></Button>
                </Popconfirm>
              </>
            ]}
          >
            <span>{item.title}</span>
          </List.Item>
        )}
        />
    </div>
  )
}

export default ListQuizes