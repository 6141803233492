import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Menu, message, Popconfirm, Progress, Row, Col, Typography, Rate, Tag } from "antd";
import {
  ShareAltOutlined,
  DeleteOutlined,
  StarOutlined,
  EditOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { DollarCircleOutlined } from "@ant-design/icons";
import styles from "./courseCard.module.css"
import ContextMenu from "../ContextMenu/ContextMenu";
import ROLE from "../../../config/roles";
import clientAxios from '../../../config/clientAxios';

const { Title, Paragraph, Text } = Typography;
const base64Placeholder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPkFFxcDwACJgE+S4F7fQAAAABJRU5ErkJggg==";

function CourseCard(props) {
  const progressCourse = Math.round(props.course.totalProgress);
  const course = props.course.course || props.course;
  const { setReloadCoursesFlag, reloadCoursesFlag } = props.flag;
  const { name, description, coverThumbnail, _id, monetization } = course;
  const ranking = props.course.ranking;
  const user = props.user && props.user.user ? props.user.user : props.user;
  const [isdisabled, setIsdisabled] = useState(false);
  const isLoading = props.isLoading;
  const navigate = useNavigate();
  const pathName = { myCourses: '/my-courses', admin: '/admin/course/list' };

  const success = () => {
    message.success('Curso borrado con éxito');
  };

  function handleCardClick(_id) {
    navigate(`/course/${_id}`);
  };

  function handleMenuClick(event) {
    event.domEvent.stopPropagation();
  };

  function confirmDelete(event) {
    event.stopPropagation();
    deleteCourseById(_id)
  }

  function changePrice(event, _id){
    event.stopPropagation();
    navigate(`/admin/course/monetization/${_id}`); 
  };
  
  function handleEditClick(event, _id) {
    event.domEvent.stopPropagation();
    navigate(`/admin/course/edit/${_id}`);
  };

  function changeRate(value){
    setIsdisabled(true);
    const courseId = _id;
    clientAxios.patch(`/ranking/${courseId}`, { value: value })
    .then(response => {
      message.success("Ranking actualizado")
      setReloadCoursesFlag(!reloadCoursesFlag)
      setIsdisabled(false)
    })
    .catch((err) => {
      setIsdisabled(false)
      message.error("Ha ocurrido un problema. intenta mas tarde")
      console.log(err)
    })
  };

  const deleteCourseById = async (_id) => {
    try {
      const response = await clientAxios.delete(`/course/${_id}`);
      if(response.status === 200) {
        success()
        props.updateCourseList();
      }
    }
    catch (error) {
      if(error.response.status === 500) {
        message.error("Error...vuelve a intentar luego.");
      }
    }
  }

  const menuOptions = (
    <Menu onClick={handleMenuClick}>
      { user && user.role === ROLE.ADMIN && (
        <Menu.Item
        onClick={(event) => handleEditClick(event, _id)}
        key='menu-item-1'
        icon={<EditOutlined style={{ fontSize: "16px", color: "blue" }} />}
      >
        Editar
      </Menu.Item>
      )
      }
      <Menu.Item key='menu-item-2' icon={<ShareAltOutlined />}>
        Enviar
      </Menu.Item>
      <Menu.Item key='menu-item-3' icon={<StarOutlined />}>
        Añadir a favoritos
      </Menu.Item>
      {user && user.role === ROLE.ADMIN && (
        <Popconfirm
          placement="top"
          title='¿Confirmas que deseas eliminar este curso?'
          okText='Si'
          onConfirm={(event)=>confirmDelete(event)}
          cancelText='No'
        >
          <Menu.Item
            key='menu-item-4'
            icon={<DeleteOutlined style={{ fontSize: "16px", color: "red" }} />}
          >
            Eliminar
          </Menu.Item>
        </Popconfirm>
      )}
    </Menu>
  );

  return (
    <Card 
      onClick={() => {
        handleCardClick(_id);
      }}
      loading={isLoading ? 1 : 0}
      hoverable
      className={styles.card}
      cover={
        <img
          loading={isLoading ? 1 : 0}
          alt='course card cover thumbnail'
          src={coverThumbnail || base64Placeholder}
        />
      }
    >
      {window.location.pathname === pathName.admin && user.role === ROLE.ADMIN && (
        <Row justify='space-between'>
          <Col>
            <Button
              type='green-6'
              onClick={(event) => changePrice(event, _id)}
              icon={<DollarCircleOutlined />}
              size='small'
              />
          </Col>
          {!course.published && <Tag color="#f50">Draft</Tag>}
        </Row>
      )}
      {window.location.pathname !== pathName.myCourses && (
        <Row>
          {monetization && (
            <Col className={styles.containerPrice}>
              {monetization.finalPrice === 0 || monetization.discount === 100 || monetization.price === 0 ? (
                <Title level={3}>¡GRATIS!</Title>
              ) : (
                <>
                  <Title level={4} className={styles.finalPrice}>
                    ${monetization.finalPrice}
                  </Title>
                  <Title level={4} delete={true} className={styles.price}>
                    ${monetization.price}
                  </Title>
                  <Title level={5} className={styles.discount}>
                    {monetization.discount}% off
                  </Title>
                </>
              )}
            </Col>
          )}

        </Row>
      )}
      { user && user.role === ROLE.STUDENT_PLATFORM && (
        <div onClick={(e) => e.stopPropagation()}>
          <Rate value={ranking} disabled={isdisabled} onChange={(value) => changeRate(value)}/>
        </div>
      )}
      <div className={styles.cardBody}>
        <ContextMenu menuOptions={menuOptions} />
        <div className={styles.meta}>
          <Title className={styles.metaTitle} level={5}>{name}</Title>
          <Paragraph ellipsis={{rows: 5}}>
            <Text>{description}</Text>
          </Paragraph>
        </div>
        { user && user.role === ROLE.STUDENT_PLATFORM && window.location.pathname === pathName.myCourses && (
          <div className={styles.contentProgress}>
            <Progress
              className={styles.progress}
              percent={progressCourse}
              format={(percent) => progressCourse === 100 ? <CheckOutlined /> : `${percent}%`}              
            />
          </div>
        )}
      </div>
    </Card>
  );
}

export default CourseCard;
