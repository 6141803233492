import { React, useState, useEffect } from 'react';
import { Form, Input, Button, Checkbox, Row, Col, Collapse, Card, Divider, Skeleton, Alert } from 'antd';
import styles from './formPayment.module.css';
import clientAxios from '../../config/clientAxios';
import { MailOutlined, UserOutlined, PhoneOutlined } from "@ant-design/icons";
import MercadoPagoForm from '../MercadoPago/MercadoPagoForm';
import { useParams, useNavigate } from "react-router-dom";
import cardIlustration from '../../assets/img/undraw_pay_online_re_aqe6.svg'; 
import parse from 'html-react-parser';


function FormPayment(props) {
  const { user, addCourseToUser } = props;
  const [rules, setRules] = useState("<b>Cargando...</b>")
  const [isLogin, setIsLogin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [resultError, setResultError] = useState('')
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [course, setCourse] = useState({monetization: {price:0}});
  const { Panel } = Collapse;
  const { id } = useParams();
  const navigate = useNavigate();
  
  useEffect( () => {
    getRules();
    getCourse();
  }, []);

  useEffect( () =>  {
    user ? setIsLogin(true) : setIsLogin(false);
  }, [user]);

  useEffect( () => {
    if (resultError) {
      document.getElementById('basic').scrollIntoView();
    }
  }, [resultError]);

  const getRules =  async () => {
    try{
      const response = await clientAxios.get(`/legal/public/ToS`)
      setRules(response.data.content);
    }
    catch(error){
      console.log(error)
    }
    };

  const paymentSuccess =  (data)  => {
    addCourseToUser(course._id);
    navigate(`/checkout/success/${data.id}`);
  }
 
  const getCourse = () =>  {
    setIsLoading(true);
    clientAxios.get(`/course/public/${id}`).then((response) => {
      setCourse(response.data);
      setIsLoading(false);
    });
  }

  const onFinish = (values) => {
    setPaymentProcessing(true);
    document.getElementById("form-checkout__submit").click();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <>
    <Row type="flex" justify='center' style={{minHeight:'100vh', marginTop: '20px'}}>
    {isLoading ? <div>
      <Skeleton avatar paragraph={{ rows: 4 }} />
    </div> :
      <Col span={24}>
        <Row justify='space-evenly'>
          <Col xs={{span: 24}} lg={{span: 12}}>
            <Form
              name="basic"
              layout="vertical"
              labelCol={{
                span: 6,
              }}
              wrapperCol={{
                span: 12,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
            {isLogin
            ?
              <></>
            :
              <>
                <div className={styles.contP}>
                  <div className={styles.contIcon}>
                    <h1 className={styles.icon}>1</h1>
                    <h1>CLIENTE</h1>
                  </div>
                  <h4>¿Estas como invitado? Podrás guardar tus datos para crear una cuenta con nosotros más tarde</h4> 
                </div>
                <Form.Item
                  label="Correo electronico"
                  name="emailNoLog"
                  rules={[
                    {
                      type: 'email',
                      message: 'No es un email válido',
                    },
                    {
                      required: true,
                      maxLength: 320,
                      message: 'Revise su correo electónico.',
                    },
                  ]}
                >
                  <Input type="email" prefix={ <MailOutlined/> } />
                </Form.Item>

                <div className={styles.contP}>
                  <div className={styles.contIcon}>
                    <h1 className={styles.icon}>2</h1>
                    <h1>TUS DATOS</h1>
                  </div>
                </div>

                <Form.Item
                  label="Nombre y apellido"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor revisa tu nombre y apellido.',
                    },
                  ]}
                >
                  <Input type="text" prefix={ <UserOutlined /> }/>
                </Form.Item>

                <Form.Item
                  label="Telefono"
                  name="telephone"
                  rules={[
                    {
                      required: true,
                      min: 7,
                      max: 15,
                      message: 'Por favor revisa su numero de telefono.',
                    },
                  ]}
                >
                  <Input type="number" prefix={ <PhoneOutlined /> }/>
                </Form.Item>
              </>
            }

              <div className={styles.contP}>
                <div className={styles.contIcon}>
                  <h1 className={styles.icon}>{ isLogin? 1 : 3 }</h1>
                  <h1 id="step-card">PAGO</h1>
                </div>
                  {resultError && <Alert  className={styles.alert} message={resultError} type="error"/>}
              </div>
             
              <MercadoPagoForm course={course} setResultError={setResultError} paymentSuccess={paymentSuccess} setPaymentProcessing={setPaymentProcessing}/>

              <Collapse className={styles.collaps} accordion='false' >
                <Panel header="Terminos y condiciones" key="1">
                <div>{parse(rules)}</div>
                </Panel>
              </Collapse>

              <Form.Item
                name="agreement"
                valuePropName="checked"
                wrapperCol={{
                  offset: 0,
                  span: 12,
                }}
                rules={[
                  {
                    required: true,
                    message: "Debes aceptar los términos y condiciones para poder continuar."
                  },
                ]}
                
              >
                <Checkbox id='checkboxForm'>Aceptar los terminos y condiciones</Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" id='buttonSubmit' htmlType="submit" loading={paymentProcessing}>
                  Pagar
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={{span: 24}} lg={{span: 8}}>
            <Row justify='center'>
              <Card title="Resumen" className={styles.card}>
                <Row justify='space-between'>
                  <Col xs={19}>
                    <p>Sub total:</p>
                  </Col>
                  <Col xs={3}>
                    <p>${course.monetization.price}</p>
                  </Col>
                  { course?.monetization?.discount != null && course.monetization.discount > 0 
                    && 
                      <>
                        <Col xs={19}>
                          <p>Descuento:</p>
                        </Col>
                        <Col xs={3}>
                          <p>-${((course.monetization.price * course.monetization.discount)/ 100).toFixed(2)}</p>
                        </Col>
                      </>
                  } 
                </Row>
                <Divider></Divider>
                <Row justify='space-between'>
                  <Col xs={19}>
                  <strong>Orden total:</strong>
                  </Col>
                  <Col xs={3}>
                    {course?.monetization?.discount === 0 || course?.monetization?.discount === null
                      ? <strong>${course?.monetization?.price}</strong>
                      : <strong>${(course?.monetization?.price - ((course?.monetization?.price * course?.monetization?.discount)/ 100)).toFixed(2)}</strong>
                    }
                  </Col>
                </Row>
              </Card>
              <img alt="card ilustration" src={cardIlustration}></img>
            </Row>
          </Col>
        </Row>
      </Col> }
    </Row>
    </>
  );
};

export default FormPayment
