import React from 'react';
import { Button, Card, Col, message, Row } from "antd";
import clientAxios from '../../../config/clientAxios';

const Restart = ({user, course, quizId, title, setShowRestart, getQuiz}) => {
  
  const restartQuiz = () => {
    clientAxios.patch(`/users/resetProgress/${user.id}`, 
    {
      quizId: quizId,
      courseId: course
    })
    .then((res) => {
      setShowRestart(false);
      message.info('Felicidades, sus intentos fueron reiniciados...!');
    })
    .catch((err) => console.err(err))

    getQuiz();
  }

  return (
    <Row justify="center">
      <Card>
        <Col span={24}>
          <h1>Superaste la cantidad máxima de intentos propuesto para este quiz: {title}</h1>
        </Col>
        <Col span={24}>
        <h3 style={{textAlign: 'center'}}>¿Desea reiniciar sus intentos?</h3>
        </Col>
        <Col span={24}>
          <span style={{textAlign: 'center'}}>⚠️ Atención perderá la calificación obtenida en todos los intentos anteriores y no podrá ser recuperada.</span>
          <p style={{marginTop: 20, marginBottom: 0}}><b>¿Desea continuar?</b></p>
        </Col>
        <Col span={24} style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
          <Button onClick={restartQuiz}>Aceptar</Button>
        </Col>
      </Card>
    </Row>
  )
}

export default Restart