import { React, useEffect } from "react";
import "./universeLoading.css";

import { useNavigate } from 'react-router-dom';
import { TimelineLite, Power0 } from "gsap";

function UniverseLoading() {

  const navigate = useNavigate();
  useEffect(() => {
    const starLayers = document.querySelectorAll('.star-layer');
    
    starLayers.forEach(layer => {
      for (let i = 0; i < 60; i++) {
        const star = document.createElement('li');
        star.className = 'star';
        layer.appendChild(star);
      }
    })
    const stars = document.querySelectorAll('.star');

     //Randomize star position
    stars.forEach(star => {
        star.style.top = Math.floor(Math.random() * 99) + "%";
        star.style.left = Math.floor(Math.random() * 99) + "%";
    })

    //Randomize exhaust fume position
    const fumes = document.querySelectorAll('.smoke-cloud');

    fumes.forEach(cloud => {
        cloud.style.top = Math.floor(Math.random() * 50) + "%";
        cloud.style.left = Math.floor(Math.random() * 101) + "%";
    })

    setInterval(function() {

        fumes.forEach(cloud => {
            cloud.style.display = "none";
            cloud.style.top = Math.floor(Math.random() * 50) + "%";
            cloud.style.left = Math.floor(Math.random() * 101) + "%";
            cloud.style.display = "block";
        })
    }, 2950);
    
    const $starLayers = document.getElementsByClassName("star-layer");
    const $body = document.getElementsByTagName("body");
    const $rocket = document.getElementById("svg-rocket");
    const $moon = document.getElementById("moon");
    const $fire = document.getElementById("fire");
    const $trail = document.getElementById("trail");
    const $skipIntro = document.getElementById("skipIntro");
    const $galaxy = document.getElementById("svg-galaxy");
    const $flash = document.getElementById("flash");
    const $milestones = document.getElementById("milestone-wrapper");
    const $title = document.getElementById("title");
    const $undraw = document.getElementById("undraw");
    const $options = document.querySelectorAll(".planet");
    const universeContainer = document.getElementById('universeContainer');

    const tl = new TimelineLite({});
    tl.to($fire, 0.3, {
            opacity: 0,
            repeat: 3
        })
        .to($trail, 0.2, {
            opacity: 1
        })
        .to($skipIntro, 0.2, {
          opacity: 1
         })
        .call(function() {
            $rocket.classList.remove("rocket-hover");
            $rocket.classList.add("rocket-shake");
        })
     
        .to($rocket, 1, {
            bottom: 150,
            delay: 0.6
        })
        .to($trail, 0.5, {
            height: 400
        }, "-=1")
        .to($moon, 3, {
            transform: "scale(7)",
            top: "50%",
            right: -1200,
            ease: Power0.easeIn,
            delay: 2
        }, "-=2")
        .to($galaxy, 2, {
            transform: "scale(1)",
            left: -400,
            top: "40%"
        }, "-=1")
        .to($rocket, 0.5, {
            bottom: "120%"
        }, "-=0.5")
        .to($trail, 0.5, {
            height: 1800
        }, "-=0.5")
        .to($flash, 0.3, {
            width: 10,
            height: "100%"
        }, "-=0.2")
        .to($flash, 0.3, {
            width: "100%",
            height: "100%"
        })
        .to(universeContainer, 0.4, {
          opacity: 0.5,
          delay: 0.6,
          duration: 2
        })
        .add(async function() {
          universeContainer.classList.add('fadeOutAnitmation');
          await new Promise((resolve) => setTimeout(resolve, 800));
          navigate(`/login`);
        })
        .to($title, 1, {
            opacity: 1
        }, "-=1")
    
    document.querySelector(".planet-wrapper").click(function() {
        document.querySelector(".planet-wrapper").classList.add("choice-selected");
        document.querySelector(".planet-wrapper").classList.remove("current-choice");
        document.querySelector(this).classList.add("current-choice");
        document.querySelector("#milestone-wrapper").classList.add("choice-selected");
        var tlData = document.querySelector(this).data("tl");
        var data = window[tlData];
        document.querySelector("#tl").html("");
        document.querySelector("#tl").classList.add("tl-show");
        data.forEach(function(item) {
            var date = item.date ? "<h2 class='date-line'>" + item.date + "</h2>" : "";
            document.querySelector("#tl").insertAdjacentHTML("beforeend","<div class='timeline-item'><div class='timeline-icon'><i class='fas fa-star'></i></div><div class='timeline-content'>" + date + "<p>" + item.content + "</p></div></div>");
        });
        document.querySelector("#tl").insertAdjacentHTML("beforeend",'<div class="scroll-top"><i class="fas fa-chevron-up"></i></div>');
      document.querySelector(".scroll-top").click(function() {
        window.scrollTo(0,0);
      })
        const timelineItems = document.querySelector('.timeline-item');
        timelineItems.each(function(index) {
          if (index > 0) {
            if (document.querySelector(this).offset().top > document.querySelector(window).scrollTop + document.querySelector(window).height() * 0.9) {
                document.querySelector(this).querySelector('.timeline-content, .timeline-icon').classList.add('hidden');
            }
          }
            
        });

        document.querySelector("#tl").hide().fadeIn();
      

        document.querySelector(window).addEventListener('scroll', function() {
            timelineItems.each(function() {
                if (document.querySelector(this).offset().top <= document.querySelector(window).scrollTop + document.querySelector(window).height() * 0.9 && document.querySelector(this).querySelector('.timeline-content, .timeline-icon').classList.contains('hidden')) {
                    document.querySelector(this).querySelector('.timeline-content, .timeline-icon').classList.remove('hidden').classList.add('bounce');
                }
            
            });
           
        });

    })
  }, []);

  const skipIntroAction = () => { 
    navigate(`/login`);
  };

  return (
    <div className="universeContainer" id="universeContainer">
   <div id="flash"></div>
 <div id="milestone-wrapper" className="noise">
  <ul className="star-layer" id="final-stars">
  </ul>

 <div id="choices-wrapper">   
   <div id="title"><h1>Milestones</h1></div>

   <div id="tl-choices">
     
      <div className="planet-wrapper" data-tl="demos">
       <span className="planet-label">Public Engagement</span>
   <div className="planet planet1">
    <div id="planet-clouds1">
     <div className="planet-cloud planet-cloud1"></div>
     <div className="planet-cloud planet-cloud2"></div>
     <div className="planet-cloud planet-cloud3"></div>
     <div className="planet-cloud planet-cloud4"></div>
     <div className="planet-cloud planet-cloud5"></div>
     <div className="planet-cloud planet-cloud6"></div>
     <div className="planet-cloud planet-cloud7"></div>
    </div>  
    <div id="planet-clouds2">
      <div className="planet-cloud planet-cloud5"></div>
      <div className="planet-cloud planet-cloud6"></div>
      <div className="planet-cloud planet-cloud2"></div>
      <div className="planet-cloud planet-cloud7"></div>
      <div className="planet-cloud planet-cloud1"></div>
      <div className="planet-cloud planet-cloud2"></div>
     </div>
   </div>
   </div>
     
     <div className="planet-wrapper" data-tl="development">
       <span className="planet-label">Product Development</span>
       <div className="planet planet2">
         <div className="planet-ring"></div>
         <div className="planet-crater planet-crater1"></div>
         <div className="planet-crater planet-crater2"></div>
         <div className="planet-crater planet-crater3"></div>
         <div className="planet-crater planet-crater4"></div>
         <div className="planet-crater planet-crater5"></div>
       </div>
     </div>
     
    <div className="planet-wrapper" data-tl="rollout">
      <div id="planet-moon"></div>
     <span className="planet-label">Regional Rollout</span>
     <div className="planet planet3">
       <div className="planet-line1"></div>
       <div className="planet-line2"></div>
       <div className="planet-line3"></div>
       <div className="planet-line4"></div>
        <div className="planet-line5"></div>
       <div id="red-spot"></div>
     </div>
     </div>  
  </div>
   
   </div>

  <section className="timeline" id="tl">
   </section>
  </div>

<div id="space" className="noise">
      
<div id="moon">
  <div className="crater crater1"></div>
  <div className="crater crater2"></div>
  <div className="crater crater3"></div>
  <div className="crater crater4"></div>
  <div className="crater crater5"></div>
  </div>
  
 <div id="svg-galaxy">
   <img src="https://res.cloudinary.com/dgeb3iekh/image/upload/v1544456585/galaxy-svgrepo-com_rskrzs.svg" alt="" />
  </div>
  <div className="red-covering-loading">
  </div>
  <p id="skipIntro" className="skipIntro fadeOutAnitmation5secs" onClick={() => skipIntroAction()}>Skip Intro</p>
  <div id="svg-rocket" className="rocket-hover">
    <div id="fire"></div>
    <div id="trail">
    </div>
  
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.628 46.409"
    xmlSpace="preserve"
    width={280}
    height={280}
    
  >
    <g
      style={{
        opacity: 0.2,
      }}
    >
      <path
        d="M32.1 39.7C30.5 41.2 26 52.2 26 52.2c-.7 1.2.3 2.1 2.6.9l8.1-4.4c4.4-2.4 5.2-3.1 5.2-8l-.8-5.9c-.3-2-1.8-2.4-3.3-.8z"
        style={{
          fill: "#231f20",
        }}
        transform="rotate(-43.146 15.267 52.109)"
      />
    </g>
    <g
      style={{
        opacity: 0.2,
      }}
    >
      <path
        d="M27.1 34.7c-1.6 1.6-12.5 6.1-12.5 6.1-1.2.7-2.1-.3-.9-2.6L18 30c2.4-4.4 3.1-5.2 8-5.2l5.9.8c2 .3 2.4 1.8.8 3.3z"
        style={{
          fill: "#231f20",
        }}
        transform="rotate(-43.146 15.267 52.109)"
      />
    </g>
    <path
      d="M32.1 37.7C30.5 39.2 26 50.2 26 50.2c-.7 1.2.3 2.1 2.6.9l8.1-4.4c4.4-2.4 5.2-3.1 5.2-8l-.8-5.9c-.3-2-1.8-2.4-3.3-.8zM27.1 32.7c-1.6 1.6-12.5 6.1-12.5 6.1-1.2.7-2.1-.3-.9-2.6L18 28c2.4-4.4 3.1-5.2 8-5.2l5.9.8c2 .3 2.4 1.8.8 3.3z"
      style={{
        fill: "#c75c5c",
      }}
      transform="rotate(-43.146 15.267 52.109)"
    />
    <g
      style={{
        opacity: 0.2,
      }}
    >
      <path
        d="M43.6 37.1c-7.8 7.8-19.8 5.7-19.8 5.7s-2.1-12 5.7-19.8c7.8-7.8 19.6-8.6 21.2-7.1 1.5 1.5.7 13.4-7.1 21.2z"
        style={{
          fill: "#231f20",
        }}
        transform="rotate(-43.146 15.267 52.109)"
      />
    </g>
    <path
      d="M43.6 35.1c-7.8 7.8-19.8 5.7-19.8 5.7s-2.1-12 5.7-19.8c7.8-7.8 19.6-8.6 21.2-7.1 1.5 1.5.7 13.4-7.1 21.2z"
      style={{
        fill: "#e0e0d1",
      }}
      transform="rotate(-43.146 15.267 52.109)"
    />
    <g
      style={{
        opacity: 0.2,
      }}
    >
      <path
        d="M21 47c-.8.8-1.3 1.5-2.1.7s-.1-1.3.7-2.1l9.9-12.7c.8-.8 4.2-1.5 4.9-.7.8.8.1 4.2-.7 4.9z"
        style={{
          fill: "#231f20",
        }}
        transform="rotate(-43.146 15.267 52.109)"
      />
    </g>
    <path
      d="M21 45c-.8.8-1.3 1.5-2.1.7s-.1-1.3.7-2.1l9.9-12.7c.8-.8 4.2-1.5 4.9-.7.8.8.1 4.2-.7 4.9z"
      style={{
        fill: "#c75c5c",
      }}
      transform="rotate(-43.146 15.267 52.109)"
    />
    <g
      style={{
        opacity: 0.2,
      }}
      transform="rotate(-43.146 15.267 52.109)"
    >
      <circle
        cx={39.6}
        cy={27.1}
        r={4}
        style={{
          fill: "#231f20",
        }}
      />
    </g>
    <g
      style={{
        opacity: 0.2,
      }}
      transform="rotate(-43.146 15.267 52.109)"
    >
      <circle
        cx={45.3}
        cy={21.5}
        r={2}
        style={{
          fill: "#231f20",
        }}
      />
    </g>
    <circle
      cx={39.6}
      cy={25.1}
      r={4}
      style={{
        fill: "#4f5d73",
      }}
      transform="rotate(-43.146 15.267 52.109)"
    />
    <circle
      cx={45.3}
      cy={19.5}
      r={2}
      style={{
        fill: "#4f5d73",
      }}
      transform="rotate(-43.146 15.267 52.109)"
    />
  </svg>

  </div>
  
  <ul id="smoke">
    <li className="smoke-cloud smoke-sm"></li>
    <li className="smoke-cloud smoke-md"></li>
    <li className="smoke-cloud smoke-sm"></li>
    <li className="smoke-cloud smoke-md"></li>
    <li className="smoke-cloud smoke-sm"></li>
    <li className="smoke-cloud smoke-md"></li>
    <li className="smoke-cloud smoke-sm"></li>
    <li><div className="main-smoke"></div></li>
  </ul>
  
  <div id="galaxy"></div>
  
    <ul className="star-layer star-layer1">
    </ul>
    <ul className="star-layer star-layer2">
    </ul>
    <ul className="star-layer star-layer3">
    </ul>
</div>

</div>

  
  );
}

export default UniverseLoading;
