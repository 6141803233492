import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Breadcrumb} from 'antd';
import { Sidebar } from './Sidebar';

import './Adminpannel.css';

function AdminStart({ user }) {
  const [actualPage] = useState('');
  return (
    <>
      <div className='generalContainer'>
        <div className='sidebarContainer'>
          <Sidebar></Sidebar>
        </div>
        <div className='outletContainer'>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default AdminStart;
