import { Button, Row, Col, Skeleton, Collapse, message, Select, Divider } from 'antd';
import useCategoriesFetch from "../../../hooks/useCategoriesFetch";
import { DeleteOutlined, } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clientAxios from '../../../config/clientAxios';
import styles from './Categories.module.css';
import ListCategory from './ListCategory';
import ListSubCategory from './ListSubCategory';
const { Option } = Select;

function Categories() {

  const [reloadCollaps, setReloadCollaps] = useState(false);
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const { data, isLoading, getCategory, subCategories, getSubCategories } = useCategoriesFetch()

  useEffect(() => {
    getCategory();
    getSubCategories();
  }, [reloadCollaps]);

  const deleteSubCat = (id, subId) => {
    clientAxios.patch(`category/${id}/delete`,{ subCategoryId: subId })
    .then(response => {
      setReloadCollaps(!reloadCollaps);

      if (response.status === 200) {
        message.success('Eliminado con exito');
      } else {
        message.error('Ha ocurrido un error. intenta mas tarde');
      }
    })
  };

  const subCategoryChange = (categoryId) => (subCategoryId) => {
    clientAxios.patch(`category/${categoryId}/add`, { subCategoryId: subCategoryId })
    .then(response => {
      setReloadCollaps(!reloadCollaps);
      if (response.status === 200) {
        message.success('Se agrego sub categoria con exito');
      } else {
        message.error('Ha ocurrido un error. intenta mas tarde');
      }
    })
    .catch((err) => {
      console.log(err.response.data.msg);
      message.error("No puedes asociar la misma sub categoria de nuevo")
    })
  };

  return (
    <>
      <Row justify='center'>
        <Col>
          <h1>Administrar categorias</h1>
        </Col>
      </Row>
      <Row justify='center'>
        <Col className={styles.buttonsAdd}>
          <ListCategory flag={ {setReloadCollaps , reloadCollaps} }/>
        </Col>
        <Col offset={2}>
          <ListSubCategory flag={ {setReloadCollaps , reloadCollaps} }/>
        </Col>
      </Row>
      {
        isLoading
        ?
          <Skeleton active />
        : 
        (
          <Row justify='center'>
            <Col span={20}>
              <Collapse defaultActiveKey={['1']}>
                {
                  data.map((category, index) => { 
                    return (
                      <Panel header={category.name} key={`header-${index}`}>
                        <div className={styles.subCategoryList}>
                          <Select
                          placeholder='Seleccionar Subcategoria'
                          style={{
                            width: 220,
                          }}
                          onChange={subCategoryChange(category._id)}
                          >
                            {
                              subCategories.map((subCategory, indexSubCategory) => (
                                <Option key={indexSubCategory} value={subCategory._id}>{subCategory.name}</Option>
                              ))
                            }
                          </Select>
                        </div>
                        <Divider />
                        {category.subCategories.map((subCategoryPrint, indexSubCategoryPrint) => (
                          <div key={indexSubCategoryPrint} className={styles.subCategoryList}>
                            <strong>{subCategoryPrint.name}</strong>
                            <div>
                              <Button className={styles.buttonsOptions} type="primary" danger onClick={() => deleteSubCat(category._id, subCategoryPrint._id)}><DeleteOutlined /></Button>
                            </div>
                          </div>)
                        )}
                      </Panel>
                    )
                  })
                }
              </Collapse>
            </Col>
          </Row>

        )
      }
    </>
  )
};

export default Categories;
