import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, Row, Col, message, Select } from "antd";
import styles from "./register.module.css";
import clientAxios from "../../config/clientAxios";
import "react-phone-number-input/style.css";
import {
  signInWithEmail,
  sendEmailVerificationWithUser,
} from "../../config/firebase";
import PhoneInput from "react-phone-number-input";
import { PATTERN } from "../../constants/pattern";

const { Option } = Select;

function Register({ dropdown, admin, setIsModalVisible }) {
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  let values = window.location.search;
  let urlParams = new URLSearchParams(values);
  let courseParams = urlParams.get("redirecToTocheckout");

  const onFinish = (data) => {
    if (data.confirmPassword === data.password) {
      setisLoading(true);
      delete data.confirmPassword;
      const { firstName, lastName, email, password, telephone, role } = data;
      clientAxios
        .post("/users", {
          firstName,
          lastName,
          email,
          password,
          telephone,
          role,
        })
        .then(async (response) => {
          if (response.status === 201) {
            setisLoading(false);
            message.success("Registrado con exito");
            const { user } = await signInWithEmail(email, password);
            sendEmailVerificationWithUser(user);
            courseParams
              ? navigate(`/login/?redirecToTocheckout=${courseParams}`)
              : !admin && navigate(`/sign-up/success/?email=${data.email}`);
          } else {
            message.error("ha ocurrido un error, intenta mas tarde");
          }
          setIsModalVisible(false);
        })
        .catch((error) => {
          setisLoading(false);
          if (error?.response?.data?.message) {
            message.error(error.response.data.message);
          }
        });
    } else {
      setisLoading(false);
      message.error("Las contraseñas no coinciden");
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Por favor revisa los campos");
  };

  return (
    <Row className={styles.container} justify="center" align="middle">
      <Col>
        <h1 className={styles.register}>
          {!admin ? "Registrarme" : "Registrar"}
        </h1>
      </Col>
      <Col xs={24} md={24} lg={24}>
        <Form
          name="basic"
          labelCol={{
            span: 9,
          }}
          wrapperCol={{
            span: 13,
          }}
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Nombre"
            name="firstName"
            rules={[
              {
                required: false,
                message: "Por favor ingresa tu nombre.",
              },
            ]}
          >
            <Input maxLength={60} />
          </Form.Item>

          <Form.Item
            label="Apellido"
            name="lastName"
            rules={[
              {
                required: false,
                message: "Por favor ingresa tu apellido.",
              },
            ]}
          >
            <Input maxLength={60} />
          </Form.Item>

          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                pattern: "[a-z0-9]+@[a-z]+.[a-z]{2,3}",
                message: "Por favor ingresa un email.",
              },
            ]}
          >
            <Input maxLength={40} />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            autoComplete="new-password"
            rules={[
              {
                required: true,
                pattern: PATTERN,
                message:
                  "La contraseña debe tener al menos 8 caracteres e incluir un número, una letra mayúscula y un simbolo(#?!@$%^&*-)",
              },
            ]}
          >
            <Input.Password maxLength={20} />
          </Form.Item>

          <Form.Item
            label="Confirmar contraseña"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Por favor ingresa una contraseña.",
              },
            ]}
          >
            <Input.Password maxLength={20} />
          </Form.Item>
          {admin && dropdown && (
            <Form.Item
              label="Categoría"
              name="role"
              rules={[
                {
                  required: true,
                  message: "Por favor seleccione su categoría.",
                },
              ]}
            >
              <Select style={{ width: 260 }}>
                <Option value="studentplatform">Student</Option>
                <Option value="mentor">Mentor</Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item label="Telefono" name="telephone">
            <PhoneInput country="AR" className={styles.telephone} />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox>
              Me interesa recibir información sobre promociones y descuentos
            </Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Registrarme
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default Register;
