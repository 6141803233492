import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Collapse, Typography, Button, Input, Skeleton, Row, Col, Popconfirm, message, Tabs } from "antd";
import { EditOutlined, DeleteOutlined, CarryOutOutlined } from "@ant-design/icons";
import Vimeo from "@u-wave/react-vimeo";
import clientAxios from "../../config/clientAxios";
import styles from "../Course/VideoFrame/videoFrame.module.css";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../helpers/localStorageHelper";
import {
  getNativeVideoPlayer,
  updateVideoProgress,
} from "../../helpers/resumeVideo";
import TextEditor from "../textEditor/TextEditor";
import parse from "html-react-parser";
import ModalForVideoProgress from "../../components/ModalForVideoProgress/ModalForVideoProgress";
const { Title } = Typography;
const { Panel } = Collapse;

const LiveVideoComp = ({ user }) => {
  const { comision_ID } = useParams();
  const [classroom, setClassroom] = useState(null);
  const [videos, setVideos] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateVideoIndex, setUpdateVideoIndex] = useState(false);
  const [videoId, setVideoId] = useState();
  const [videoName, setVideoName] = useState();
  const [videoProgress, setVideoProgress] = useState(0);
  const [timeLapsedIsModalActive, setTimeLapsedIsModalActive] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const [isAddingReview, setIsAddingReview] = useState(false);
  const [updateVideo, setUpdateVideo] = useState();

  const { role } = user;

  const getVideos = async () => {
    try {
      const { data } = await clientAxios.get(
        `liveVideo/live/videos/${comision_ID}`
      );
      setVideos(data);
      setLoadingContent(false);
      if (data.length > 0) {
        setClassroom(data[0]?.classroom);
      }
    } catch (e) {
      setLoadingContent(false);
      console.log(e);
    }
  };

  const confirm = async (videoId) => {
    try {
      const { data } = await clientAxios.delete(`/liveVideo/${videoId}`);
      message.success('Video eliminado correctamente');
      getVideos();
    } catch (error) {
      if (error.status === 500) {
        message.error(
          'Ocurrió un problema inesperado, por favor intente más tarde',
        );
      }
    }
  };

  useEffect(() => {
    getVideos();
  }, [isUpdating]);

  const genExtra = (video, index) => (
    <span>
      <Button
        type='primary'
        onClick={() => {
          setIsUpdating(true);
          setUpdateVideoIndex(index);
          setVideoId(video._id);
        }}
      >
        <EditOutlined />
      </Button>
      <span className={styles.space}>
        <Popconfirm
          title='¿Deseas eliminar este video?'
          okText='Si'
          cancelText='No'
          onConfirm={()=>{confirm(video._id)}}
        >
          <DeleteOutlined />
        </Popconfirm>
      </span>
    </span>
  );

  const updateVideoName = async (event, videoName, videoId) => {
    event.stopPropagation();
    setIsUpdating(true);
    await clientAxios.patch(
      `liveVideo/live/videos`,
      { displayName: videoName,
      videoId: videoId }
    );
    setIsUpdating(false);
    getVideos();
  };

  const convertSecondsToMinutes = () => {
    let minute = Math.floor((videoProgress / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    let second = videoProgress % 60;
    second = Math.round(second < 10 ? "0" + second : second);
    return minute + ":" + second;
  };

  const confirmReturnToPlayedTime = () => {
    const player = getNativeVideoPlayer();
    setTimeLapsedIsModalActive(false);
    if (player) {
      player.play();
    }
  };
  const startVideoFromBeginning = () => {
    const player = getNativeVideoPlayer();
    if (player) {
      player.setCurrentTime(0);
      player.play();
    }
    setLocalStorage("liveVideoCurrentTime", {
      time: { seconds: 0 },
      idVideo: videoId,
    });
    setVideoProgress(0);
    updateVideoProgress(setVideoProgress);
    setTimeLapsedIsModalActive(false);
  };

  useEffect(() => {
    updateVideoProgress(setVideoProgress);
    convertSecondsToMinutes();
  }, []);

  return (
    <div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Clases grabadas" key="1">
          <Row>
            <Col span={18} offset={3}>
              <Title style={{ marginTop: "1rem" }} level={4}>
                {classroom ? `comision ${classroom}` : ""}
              </Title>
              {loadingContent && <Skeleton active />}
              {!loadingContent && (!videos || videos.length == 0) && (
                <p>
                  No se encontraron videos para la comisión activa, por favor
                  intente de nuevo mas tarde
                </p>
              )}
              {!loadingContent && videos && videos.length > 0 && (
                <Collapse
                  destroyInactivePanel={true}
                  accordion
                  defaultActiveKey={0}
                >
                  {videos.map((video, index) => {
                    return (
                      <Panel
                        header={
                          isUpdating && index === updateVideoIndex ? (
                            <Input.Group compact>
                              <Input
                                style={{
                                  width: "calc(100% - 200px)",
                                }}
                                defaultValue={video.displayName || video.name}
                                bordered={true}
                                onChange={(e) => setVideoName(e.target.value)}
                                autoFocus={true}
                                onClick={(e) => e.stopPropagation()}
                              />
                              <Button
                                type="secondary"
                                onClick={() => {
                                  setVideoName(video.displayName || video.name);
                                  setIsUpdating(false);
                                }}
                              >
                                Cancelar
                              </Button>
                              <Button
                                type="primary"
                                onClick={(e) => updateVideoName(e, videoName, video._id)}
                              >
                                Confirmar
                              </Button>
                            </Input.Group>
                          ) : (
                            video.displayName || video.name
                          )
                        }
                        key={index}
                        extra={role === "mentor" && genExtra(video, index)}
                      >
                        <div className={styles.videoContainer}>
                          <Vimeo
                            video={`${video.uri.slice(8)}`}
                            responsive={true}
                            onReady={() => {
                              if (
                                getLocalStorage("liveVideoCurrentTime").time
                                  .seconds > 0 &&
                                getLocalStorage("liveVideoCurrentTime")
                                  .idVideo === video.uri.slice(8)
                              ) {
                                setTimeLapsedIsModalActive(true);
                              }
                            }}
                            start={
                              getLocalStorage("liveVideoCurrentTime")
                                ?.idVideo === video.uri.slice(8)
                                ? videoProgress
                                : 0
                            }
                            onProgress={(timeValue) => {
                              setLocalStorage("liveVideoCurrentTime", {
                                time: timeValue,
                                idVideo: video.uri.slice(8),
                              });
                            }}
                          />
                          <ModalForVideoProgress
                            openModal={timeLapsedIsModalActive}
                            confirmReturn={confirmReturnToPlayedTime}
                            startVideo={startVideoFromBeginning}
                            handleClickCancel={startVideoFromBeginning}
                            handleClickOK={confirmReturnToPlayedTime}
                            secondsToShow={convertSecondsToMinutes()}
                            title={"Video en Progreso"}
                            closable={false}
                            maskClosable={false}
                            textButtonCancel={"Continuar"}
                            textButtonOK={"Ver desde el comienzo."}
                          />
                          {role === "mentor" && (
                            <Button
                              type="primary"
                              shape="round"
                              className={styles.commentsButton}
                              onClick={() => {
                                setIsAddingReview(true);
                              }}
                            >
                              {video.summary
                                ? "Editar comentario"
                                : "(+) Agregar comentario o reseña"}
                            </Button>
                          )}
                          <>{video.summary && parse(parse(video?.summary))}</>
                          {isAddingReview && (
                            <TextEditor
                              setIsAddingReview={setIsAddingReview}
                              videoId={video._id}
                              setUpdateVideo={setUpdateVideo}
                            />
                          )}
                        </div>
                      </Panel>
                    );
                  })}
                </Collapse>
              )}
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Mi Asistencia" key="2">
          <Row>
            <Col span={18} offset={3}>
              <Button
                disabled={true}
                type="primary"
                shape="round"
                icon={<CarryOutOutlined />}
                size={"large"}
              >
                Buscar asistencias en curso
              </Button>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default LiveVideoComp;
