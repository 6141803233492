import React from 'react';
import Explore from '../components/Explore/Explore';

function explorePage() {
  return (
    <Explore />
  )
};

export default explorePage;
