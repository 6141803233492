import { useState, useEffect } from "react";
import {
  BookOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, Col, Menu, Row } from "antd";
import { getProfilePic, isProfilePicSet } from "../../helpers/randomAvatar";
import Animal from "react-animals";
import styles from "./userProfile.module.css";
import { Link } from "react-router-dom";
import ChangePassword from "../ChangePassword/ChangePassword";

function getItem(label, key, icon, disabled, children, type) {
  return {
    key,
    icon,
    disabled,
    children,
    label,
    type,
  };
}

const items = [
  getItem("Mi Perfil", "1", <UserOutlined />),
  getItem("Notificaciones", "2", <BookOutlined />, true),
  getItem("Seguridad", "3", <KeyOutlined />),
];

const UserProfile = ({ user }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [cardToRender, setCardToRender] = useState("1");
  const [profileCard, setProfileCard] = useState(true);
  const [updatePassCard, setUpdatePassCard] = useState(false);
  const [myCoursesCard, setMyCoursesCard] = useState(false);
  const [selectedKey, setSelectedKey] = useState("1")
  const showAvatarOrAnimal = isProfilePicSet();
  const defaultProfilePicture = getProfilePic();
  const { email } = user;
  
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const selectCardToRender = ({ key }) => {
    setCardToRender(key);
  };

  useEffect(() => {
    if (cardToRender === "1") {
      setProfileCard(true);
      setUpdatePassCard(false);
      setMyCoursesCard(false);
      setSelectedKey("1");

    } else if (cardToRender === "2") {
      setProfileCard(false);
      setMyCoursesCard(true);
      setUpdatePassCard(false);
      setSelectedKey("2");
    } else {
      setProfileCard(false);
      setMyCoursesCard(false);
      setUpdatePassCard(true);
      setSelectedKey("3");
    }
  }, [cardToRender]);
  
  return (
    <Row style={{ flexWrap: "nowrap" }}>
      <Col lg={3}>
        <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{
            marginBottom: 10,
          }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <Menu
          style={{ minHeight: "80vh" }}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          inlineCollapsed={collapsed}
          items={items}
          onSelect={selectCardToRender}
          selectedKeys={selectedKey}
        />
      </Col>
      <div>
        <Col className={styles.profileContainer} lg={20} span={20} offset={2}>
          <div className={styles.avatarPic}>
            <div style={{ minWidth: 200, marginTop: "2rem" }}>
              <Badge.Ribbon text={user.role}>
                {profileCard && (
                  <Card title="Mi Perfil">
                    {showAvatarOrAnimal ? (
                      <img
                        className={styles.avatarPic}
                        src={defaultProfilePicture}
                        referrerpolicy="no-referrer"
                      ></img>
                    ) : (
                      <Animal name={defaultProfilePicture} size="120px" />
                    )}
                    <div className={styles.cardText}>
                      <p>
                        <b>Nombre:</b> {user.firstName}
                      </p>
                      <p>
                        <b>Apellido:</b> {user.lastName}
                      </p>
                      <p className={styles.wordWrap}>
                        <b>Email:</b> {user.email}
                      </p>
                      <p>
                        <b>Teléfono:</b>{" "}
                        {user.phoneNumber ? user.phoneNumber : "-"}
                      </p>
                    </div>
                    <Link to="/profile/edit">
                      <Button type="primary" block disabled>
                        Editar
                      </Button>
                    </Link>
                  </Card>
                )}
                {myCoursesCard && 
                  <h1>Hola Mundo</h1>
                }
                {updatePassCard &&
                  <ChangePassword userEmail={email} setCardToRender={setCardToRender} />
                }
              </Badge.Ribbon>
            </div>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default UserProfile;
