import { React, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Space,
  Divider,
  message,
} from "antd";
import { GoogleOutlined, FacebookFilled } from "@ant-design/icons";
import { setLocalStorage } from "../../helpers/localStorageHelper";
import { setRandomAnimal, setProfilePic } from "../../helpers/randomAvatar";
import styles from "./login.module.css";
import { signInWithEmail, auth } from "../../config/firebase";
import clientAxios from "../../config/clientAxios";
import ROLE from "../../config/roles";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function Login({ user, setUser }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef();
  let values = window.location.search;
  let urlParams = new URLSearchParams(values);
  let courseParams = urlParams.get('redirecToTocheckout');
  
  const onFinish = async (values) => {
    setIsLoading(true);
    const { email, password } = values;
    // executes recaptcha
    const recaptchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
      try {
        const {
          user: { accessToken },
        } = await signInWithEmail(email, password);
        const isEmailVerified = await clientAxios.post('users/email-login',{
          accessToken,
          recaptchaToken
        });
        if(isEmailVerified.data){
          const {
            data: { token, user },
          } = await clientAxios.post("auth/login", {
            accessToken,
          });
          setUser(user);
          setLocalStorage("token", token);
          setLocalStorage("user", user);
          setRandomAnimal();
          message.success({
            content: "Bievenido!",
            duration: 3,
          });
          if(courseParams){
            navigate(`/explore/course/${courseParams}`)
          } else {
            user.role === ROLE.ADMIN ? navigate("/admin/course/list") : navigate("/my-courses");
          }
        } else {
          navigate(`/sign-up/success/?email=${email}`);
        }
      } catch (error) {
        setIsLoading(false);
        if (error.code === "auth/wrong-password") {
          message.error({
            content: "Usuario o Contraseña incorrecta",
            duration: 4,
          });
        } else if (error.code === "auth/user-not-found") {
          message.error({
            content: "Usuario o Contraseña incorrecta",
            duration: 4,
          });
        } else if (error.code === "auth/invalid-email") {
          message.error({
            content: "Usuario o Contraseña incorrecta",
            duration: 4,
          });
        } else {
          message.error({
            content:
              "Error al iniciar sesión, por favor verifica tus credenciales e intenta mas tarde",
            duration: 4,
          });
        }
      }
  };

  const googleProvider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const {
        user: { accessToken, uid, photoURL },
      } = await signInWithPopup(auth, googleProvider);
      const response = await clientAxios.post("users/social-login", {
        idToken: accessToken,
        uid,
      });
      const {
        data: { token, user },
      } = response;
      setLocalStorage("token", token);
      setLocalStorage("user", user);
      setUser(user);
      setProfilePic(photoURL);
      if(token && uid) {
        user.role === ROLE.ADMIN ? navigate("/admin/course/list") : navigate("/my-courses");
      }
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        message.error({
          content: "Popup cerrado por el usuario",
          duration: 3,
        });
      }
    }
  };

  const facebookProvider = new FacebookAuthProvider();

  const signInWithFacebook = async () => {
    try {
      const {
        user: { accessToken, uid, photoURL },
      } = await signInWithPopup(auth, facebookProvider);
      const response = await clientAxios.post("users/social-login", {
        idToken: accessToken,
        uid,
      });
      const {
        data: { token, user },
      } = response;
      setLocalStorage("token", token);
      setLocalStorage("user", user);
      setUser(user);
      token && uid && window.location.replace("/my-courses");
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        message.error({
          content: "Popup cerrado por el usuario",
          duration: 3,
        });
      }
    }
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "80vh" }}
    >
      <Col xs={12} md={10} lg={6}>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <h2>Iniciar Sesion</h2>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Ingrese su email, por favor" }]}
          >
            <Input placeholder="Ingrese su email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Ingrese su contraseña, por favor" },
            ]}
          >
            <Input.Password placeholder="Ingrese su contraseña" />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Recuerdame</Checkbox>
          </Form.Item>
          <div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
              hl='es-419'
              size='invisible'
            />
          </div>
          <Form.Item>
            <Button
              block
              type="primary"
              htmlType="submit"
              className={styles.shadowBtn}
              disabled={isLoading}
            >
              Iniciar Sesion
            </Button>
            <Divider>ó</Divider>
            <Space
              direction="vertical"
              size="middle"
              style={{
                display: "flex",
              }}
            >
              {!process.env.REACT_APP_PLATFORM_LIVE_COURSES && ( 
              <Button
                onClick={signInWithGoogle}
                disabled={isLoading}
                className={styles.googleBtn}
                icon={
                  <GoogleOutlined
                    style={{ color: "#ff4d4f", fontSize: "1rem" }}
                  />
                }
                block
              >
                Continuar con Google
              </Button> )}
              {!process.env.REACT_APP_PLATFORM_LIVE_COURSES && ( 
              <Button
                onClick={signInWithFacebook}
                disabled={isLoading}
                className={styles.shadowBtn}
                icon={
                  <FacebookFilled
                    style={{ color: "#096dd9", fontSize: "1rem" }}
                  />
                }
                block
              >
                Continuar con Facebook
              </Button>
              )}
              <Link to='/restore-password'><p style={{textAlign: 'center'}}>Olvidé mi contraseña</p></Link>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default Login;
