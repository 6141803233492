import { Button, Card, Col, message, Row, Spin } from "antd";
import { useEffect } from "react";
import clientAxios from "../../../config/clientAxios";
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from "react";
import styles from './ViewQuiz.module.css';

export default function Results({ user, id, courseId, setShowResult, setFinished, numberOfAttemps }){
  const [result, setResult] = useState()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  const getResults = () => {
    clientAxios.get(`/users/${user.id}`)
      .then(({data}) => {
        let getCourse = data.courses.filter(course => course.course === courseId)
        let getQuiz = getCourse[0].quiz.filter(quiz => quiz.id === id)
        if(getQuiz.length !== 0){
          setResult(getQuiz[getQuiz.length - 1])
        }else{
          message.error('No tienes acceso a este resultado')
          navigate("/my-courses") 
        }
      })
      .catch(err => {
        console.log(err.response.data.msg)
        message.error(err.response.data.msg)
      })
      .finally(() => setLoading(false))
  }

  const redirectToQuiz = () => {
    setFinished();
    setShowResult(false)
  }

  useEffect(() => {
    getResults()
  }, [user]);

  return(
    loading ? 
    <div className={`${styles.minimalView} ${styles.center}`}>
      <Row justify='center' className={styles.margin}>
        <Col span={24}>
          <Spin></Spin>
          <p>Cargando resultados...</p>
        </Col>
      </Row>
    </div>
    :
    result ? 
      <div className={`${styles.minimalView} ${styles.center}`}>
        <Row justify="center" className={styles.margin}>
          <Card>
            <Col span={24}>
              <h3>Resultados</h3>
            </Col>
            <Col span={24}>
              Intento numero: {result.attempt}
            </Col>
            <Col span={24}>
              Porcentaje de suceso: {result.successPercentage}%
            </Col>
            <Button className={styles.marginTop} onClick={() => {
              setShowResult(false); 
              numberOfAttemps()
              }}>Reintentar</Button>
            <Button className={styles.marginTop} onClick={() => {
              redirectToQuiz(); 
              numberOfAttemps()
              }}>Continuar</Button>
          </Card>
        </Row>
      </div>
      :
        <Navigate to={"/my-courses"}/>
  )
}
