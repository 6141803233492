import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Avatar,
  List,
  Space,
  Row,
  Col,
  message,
  Slider,
  Skeleton,
  Typography,
  Rate,
  Select,
  Spin,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './explore.module.css';
import clientAxios from '../../config/clientAxios';
import 'react-phone-number-input/style.css';
import courseDefaultCover from '../../assets/img/defaultCover.png';
import { Option } from 'antd/lib/mentions';

function Explore() {
  const { Title } = Typography;
  const navigate = useNavigate();
  const [coursesList, setCoursesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [coursePrice, setCoursePrice] = useState(20200);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sortValue, setSortValue] = useState();
  const courseName = searchParams.get('q');
  const categoryId = searchParams.get('category');
  const subCatId = searchParams.get('subcategory');
  const SORT_DESCENDANT = -1;
  const SORT_ASCENDANT = 1;

  useEffect(() => {
    loadCoursesData();
  }, [courseName, categoryId, subCatId, sortValue, coursePrice]);

  function loadCoursesData() {
    setIsLoading(true);
    clientAxios.get(`/course/public?name=${courseName}&categoryId=${categoryId}&subCatId=${subCatId}&price=${coursePrice}&sort=${sortValue}&order=${SORT_DESCENDANT}`)
      .then((response) => {
        setCoursesList(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 500) {
          message.error('Error...vuelve a intentar luego.');
        }
      });
  }

  function goToDetailPage(id) {
    navigate(`/explore/course/${id}`);
  }


  return (
    <div>
      <Row
        style={{ minHeight: '80vh' }}
        className={styles.container}
        align='top'
      >
        <Col className={styles.sidebar}>
          <div>
            <Space> </Space>
            <h2>Precio menor a: ${coursePrice}</h2>
            <Slider
              defaultValue={coursePrice}
              step={2000}
              min={200}
              max={20200}
              tooltip={{
                open: true,
              }}
              onAfterChange={(price) => {
                setCoursePrice(price);
              }}
            />
            <span>Ordenar por: </span>
            <Select
              style={{
                width: 120,
              }}
              onChange={(value) => {
                setSortValue(value);
              }}
            >
              <Option value='ranking'>Más populares</Option>
              <Option value='createdAt'>Más recientes</Option>
            </Select>
          </div>
        </Col>
        {isLoading ? (
          <Col style={{width: '60vw', marginTop: '10vh', marginLeft: '20vh' ,textAlign: 'center'}}>
            <Skeleton  active />
          </Col>
        ) : (
          <Col span={18}>
            <List
              itemLayout='vertical'
              size='large'
              dataSource={coursesList}
              renderItem={(item) => (
                <List.Item
                  key={item.name}
                  extra={
                    <img
                      width={272}
                      alt='logo'
                      src={item.coverThumbnail || courseDefaultCover}
                    />
                  }
                >
                  <List.Item.Meta
                    avatar={<Avatar />}
                    title={
                      <div className={styles.cardTitle}>
                        {item.monetization.finalPrice === 0 ||
                        item.monetization.discount >= 100 ? (
                          <Space size='middle'>
                            <a
                              level={5}
                              onClick={() => {
                                goToDetailPage(item._id);
                              }}
                            >
                              {item.name}
                            </a>
                            <Rate disabled defaultValue={item.ranking} />
                            <Title level={3}>¡Gratis!</Title>
                          </Space>
                        ) : (
                          <Space size='middle'>
                            <a
                              level={5}
                              onClick={() => {
                                goToDetailPage(item._id);
                              }}
                            >
                              {item.name}
                            </a>
                            <Rate disabled defaultValue={item.ranking} />
                            <Title level={5}>
                              ${item.monetization.finalPrice}
                            </Title>
                            <Title level={5} delete={true}>
                              ${item.monetization.price}
                            </Title>
                            <Title level={5}>
                              {item.monetization.discount}% off
                            </Title>
                          </Space>
                        )}
                      </div>
                    }
                    description='by RollingCode'
                  />
                  {item.description}
                </List.Item>
              )}
            />
          </Col>
        )}
      </Row>
      <div className={styles.clearfix}></div>
    </div>
  );
}

export default Explore;
