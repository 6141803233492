import Player from '@vimeo/player';
import { getLocalStorage } from './localStorageHelper';

export const getNativeVideoPlayer = () => {
  const iframe = document.querySelector('iframe');
  if (iframe && iframe.src.includes('vimeo')) {
    const player = new Player(iframe);
    return player;
  }
  return false;
};

export const updateVideoProgress = (setVideoProgress) => {
  setVideoProgress(getLocalStorage('liveVideoCurrentTime')?.time?.seconds);
}