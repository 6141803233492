import axios from "axios";
import React, { useEffect, useState } from "react";
import { Layout, Alert, message } from 'antd';
import { useParams } from "react-router-dom";
import "./certified.css";

const { Content } = Layout;

function Certified() {
  const { id } = useParams();
  const [data, setData] = useState();
  const getCertificate = () => {
    axios.get(`${process.env.REACT_APP_CERTIFICATE_URL}/api/certificate/${id}`)
    .then((response) => {
      setData(response.data);
    })
    .catch((error) => message.error("No se encontró un certificado disponible."))
  }

  useEffect(() => {
    getCertificate();
  }, []);

  return (
    data ?
      (<div className="defaultHeight">
        <div className="content">
          <div className="verified local">
            <div className="content-wrapper">
              <div className="content icon-only">
                <div className="check"></div>
              </div>
            </div>
            <div className="badge-wrapper">
              <div className="badge-layer"></div>
              <div className="badge-layer grow"></div>
              <div className="badge-layer shadow"></div>
            </div>
          </div>
        </div>  
        <h1 className="certified">{data.userName} {data.userLastName}</h1>
        <h1 className="certified">{data.courseName}</h1>
      </div>)
    : (
        <Content style={{padding: '50px 10px', marginBottom: '50vh'}}>
          <Alert message="No tiene un certificado disponible" type="warning"/>
        </Content>
      )
  );
}

export default Certified;
