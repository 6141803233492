import React from 'react'
import LiveVideoComp from '../components/liveVideo/LiveVideoComp'

const LiveVideo = ({user}) => {
  return (
    <div style={{minHeight: '80vh'}}>
      <LiveVideoComp user={user} />
    </div>
  )
}

export default LiveVideo