import { Form, Select, message } from "antd"
import { useEffect, useState } from "react";
import clientAxios from "../../../../../config/clientAxios";

export const Quiz = ({ chapters, indexChapter, setChapterFormData, indexTopics, quizError, setQuizError }) => {
    const [options, setOptions] = useState([])

    const { Option } = Select;

    const getAllQuizzes = () => {
        clientAxios.get(`/quiz`)
          .then((response) => {
            setOptions(response.data)
          })
          .catch((error) => {
            message.error(error.message)
            console.debug(error);
          });
    }

    useEffect(() => {
        getAllQuizzes()
    }, [])

    function setCourseDataSelects(selectData) {
        const fieldName = selectData.name;
        const updatedChapter = chapters;
        updatedChapter[indexChapter].topics[indexTopics][fieldName] = selectData.value;
        setChapterFormData([...updatedChapter]);
    }

    return(
        <Form.Item
            label="Nombre de Quiz"
            validateStatus={quizError.icon}
            help={quizError.description}
            rules={[
              {
                required: true,
                message: "Select a quiz is required",
              },
            ]}
        >
            <Select
                className="selects"
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                placeholder='Seleccionar Quiz'
                onChange={(e, values) => {
                    setCourseDataSelects(values);
                    setQuizError({
                        icon: "",
                        description: "",
                    });
                }}
                id='quiz'
                value={ 
                    chapters[indexChapter].topics[indexTopics].quizId ? 
                    chapters[indexChapter].topics[indexTopics].quizId :
                    ''
                }
            >
                {
                    options.map((quiz) => <Option name="quizId" value={quiz._id}>{quiz.title}</Option>)
                }
            </Select>
        </Form.Item>
    )
}