import React, { useState } from "react";
import { Input, AutoComplete, Avatar } from "antd";
import clientAxios from "../../config/clientAxios";
import { useNavigate } from "react-router-dom";
import styles from "./autoSearch.module.css";

const searchResult = (data) =>
  data.map((course) => {
    return {
      value: course.name,
      key: course._id,
      label: (
        <div
          key={course._id}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <span>{course.name}</span>
          </div>
          <Avatar shape="square" size={64} src={course.coverThumbnail} />
        </div>
      ),
    };
  });

function AutoSearch({user}) {
  const [options, setOptions] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const handleSearch = (value) => {
    if (value.length >= 3) {
      clientAxios.get(`/course/public?name=${value}`).then((response) => {
        setOptions(searchResult(response.data));
      });
    } else {
      setOptions(searchResult([]));
    }
  };

  const onSelect = (value, option) => {
    const courseId = option.label.key;
    setSearchInput("");
    if(user?.courses && user.courses.length>0 && findCourse(user.courses, courseId)) {
        navigate(`/course/${courseId}`);
    }
    else {
      navigate(`/explore/course/${courseId}`);
    }
  };

  const findCourse = (courselist, courseId) => {
    return courselist.find((obj) => {
      return obj.course === courseId;
    });
  }

  const onEnterAction = (e) => {
    if (e.key === 'Enter') {
      navigate(`/explore?q=${searchInput}`);
    }
  };


  return (
    <AutoComplete
      dropdownMatchSelectWidth={402}
      className={styles.autoSearch}
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
      value={searchInput}
      onChange={(e) => setSearchInput(e)}
    >
      <Input.Search  onKeyDown={onEnterAction} style={{ minWidth: 330 ,maxWidth: 400 }} size="large" placeholder="Buscar cursos" enterButton />
    </AutoComplete>
  );
}

export default AutoSearch;
