import { useState, useEffect } from "react";
import { Row, Button, Drawer, Space, Dropdown, Menu, Col, List } from "antd";
import {
  MenuOutlined,
  ExportOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Header } from "antd/lib/layout/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mainLogo from "../../assets/img/rollingCodeSchoolLogo.png";
import styles from "./navbar.module.css";
import AutoSearch from "../AutoSearch/AutoSearch";
import { removeLocalStorage } from "../../helpers/localStorageHelper";
import { getProfilePic, isProfilePicSet } from "../../helpers/randomAvatar";
import ROLE from "../../config/roles";
import Animal from "react-animals";
import clientAxios from "../../config/clientAxios";

function Navbar({ user, setUser }) {
  const navigate = useNavigate();
  const location = useLocation();
  const hideSearchBar = location.pathname === "/login";
  const showAvatarOrAnimal = isProfilePicSet();
  const defaultProfilePicture = getProfilePic();
  const [visible, setVisible] = useState(false);
  const [categories, setCategories] = useState([])

  const getCategory = () => {
    clientAxios.get('/category')
    .then((response) => {
      setCategories(response.data)
    })
  };

  useEffect(() => {
    getCategory()
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const logout = () => {
    try {
      removeLocalStorage("user");
      removeLocalStorage("token");
      setUser(null);
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const goToRegister = () => {
    navigate("/sign-up");
  };

  const itemsList = () => {
    const items = categories.map((item, categoryIndex) => {
      return { 
        key: item._id + categoryIndex,
        label: (<span onClick={() => redirect(item._id)}>{item.name}</span>),
        ...( item.subCategories.length !== 0 ? {children: item.subCategories.map((sub, subCategoryIndex ) => {
        return {
          key: ( sub._id + categoryIndex + subCategoryIndex ),
          label: (<List onClick={ () => redirect(item._id, sub._id) }>{sub.name}</List>),
        }
      })}: null)
    }
    });
    return <Menu
      items={items}
    />
  };

  const redirect = (categoryId, subCategoryId) => {
    if (subCategoryId) {
      navigate(`/explore?category=${categoryId}&subcategory=${subCategoryId}`);
    } else {
      navigate(`/explore?category=${categoryId}`)
    }
  };

  const menu = (
    <Menu className={styles.menuDropdown}>
      <Menu.Item key="user-email" icon={<UserOutlined />}>
        <Link to='/profile'>Mi perfil</Link>
      </Menu.Item>
      <Menu.Item key="logout" icon={<ExportOutlined />} onClick={logout} danger>
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className={styles.header}>
      <Row justify="space-between" style={{ height: "100%" }}>
        <Col className={styles.logoContainer}>
          <Link to="/home">
            <img src={mainLogo} alt="rollingCode Logo" />
          </Link>
          {!process.env.REACT_APP_PLATFORM_LEGACYPAYMENTS_MODE && !process.env.REACT_APP_PLATFORM_LIVE_COURSES &&
          <Dropdown className={styles.hidden} overlay={itemsList}>
              <Space>
                Categorias
                <DownOutlined />
              </Space>
          </Dropdown> }
        </Col>
        {!process.env.REACT_APP_PLATFORM_LEGACYPAYMENTS_MODE && !process.env.REACT_APP_PLATFORM_LIVE_COURSES &&
        <Col className={styles.mobileHidden}>
          {!hideSearchBar && <AutoSearch user={user} />}
        </Col> }
          {!process.env.REACT_APP_PLATFORM_LEGACYPAYMENTS_MODE && 
        <Col className={styles.mobileHidden}>
          <ul className={styles.menu}>
            <Space size={"middle"}>
              {user && user.role === ROLE.ADMIN && (
                <li>
                  <Link to="/admin/course/list">Admin Dashboard</Link>
                </li>
              )}
              {user && (
                <li>
                  <Link to="/my-courses">Mis Cursos</Link>
                </li>
              )}
              {user ? (
                <Dropdown overlay={menu} arrow>
                  <div className={styles.avatarContainer}>
                    <div>
                      {showAvatarOrAnimal ? (
                        <img class={styles.avatarPic} src={defaultProfilePicture} referrerpolicy="no-referrer"></img>
                      ) : (
                        <Animal name={defaultProfilePicture} size="40px" />
                      )}
                    </div>
                  </div>
                </Dropdown>
              ) : (
                <Row>
                  <Space size={"middle"}>
                    <li>
                      <Button className={styles.btnNav} onClick={goToLogin}>
                        Ingresar
                      </Button>
                    </li>
                    {!process.env.REACT_APP_PLATFORM_LIVE_COURSES && ( 
                    <li>
                      <Button
                        className={styles.btnNav}
                        type="primary"
                        onClick={goToRegister}
                      >
                        Registrarse
                      </Button>
                    </li> )}
                  </Space>
                </Row>
              )}
            </Space>
          </ul>
        </Col> }
        {!process.env.REACT_APP_PLATFORM_LEGACYPAYMENTS_MODE && !process.env.REACT_APP_PLATFORM_LIVE_COURSES &&
        <div className={styles.mobileShow}>
          <Button
            type="primary"
            icon={<MenuOutlined />}
            onClick={showDrawer}
          ></Button>
        </div> }
        {!process.env.REACT_APP_PLATFORM_LEGACYPAYMENTS_MODE && !process.env.REACT_APP_PLATFORM_LIVE_COURSES &&
        <Drawer
          title="Menú"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          <ul style={{ paddingLeft: 0 }}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              <li className={styles.menuItems}>
                <Dropdown  overlay={itemsList}>
                  <Space>
                    Categorias
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </li>
              <li className={styles.menuItems}>
                <AutoSearch user={user} />
              </li>
              {user && user.role === ROLE.ADMIN && (
                <li className={styles.menuItems}>
                  <Link to="/admin/course/list">Admin Dashboard</Link>
                </li>
              )}
              {user && (
                <li className={styles.menuItems}>
                  <Link to="/my-courses">Mis Cursos</Link>
                </li>
              )}
              {user ? (
                <>
                  <div className={styles.avatarContainer}>
                    <Animal size="40px" />
                  </div>
                  <Button
                    onClick={() => logout()}
                    type="danger"
                    className={styles.btnNav}
                  >
                    Logout
                  </Button>
                </>
              ) : (
                <div>
                  <Space direction="vertical" size="middle">
                    <li className={styles.menuItems}>
                      <Button className={styles.btnNav} onClick={goToLogin}>
                        Ingresar
                      </Button>
                    </li>
                    {!process.env.REACT_APP_PLATFORM_LIVE_COURSES && (
                    <li className={styles.menuItems}>
                      <Button
                        className={styles.btnNav}
                        type="primary"
                        onClick={goToRegister}
                      >
                        Registrarse
                      </Button>
                    </li> )}
                  </Space>
                </div>
              )}
            </Space>
          </ul>
        </Drawer> }
      </Row>
    </Header>
  );
}

export default Navbar;
