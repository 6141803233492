import { React, useState, useEffect, useRef } from "react";
import { Input, Select, Skeleton, Table } from "antd";
import { FileDoneOutlined, CreditCardOutlined } from "@ant-design/icons";
import "./home.css";
import { useMercadopago } from "react-sdk-mercadopago";

import clientAxios from "../config/clientAxios";
import { Row, Col, Button, Divider, message, Typography } from "antd";
const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;

function HomeLegacyPayments() {
  const [user, setUser] = useState();
  const [installments, setInstallments] = useState();
  const [payData, setPayData] = useState();
  const [loading, setLoading] = useState(true);
  const [installmentsLoading, setInstallmentsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const emailInputRef = useRef(null);
  const [selectedComission, setSelectedComission] = useState({
    classRoom: 0,
    selectedClassRoom: "",
    course: "",
    email: "",
    displayName: "",
  });

  const renderDate = (text) => {
    const date = new Date(text);
    return <span>{Intl.DateTimeFormat("es").format(date)}</span>;
  };

  const renderMoney = (amount) => {
    return <span>{`$${amount}`}</span>;
  };

  const mercadopago = useMercadopago.v2(process.env.REACT_APP_PUBLIC_KEY_MP, {
    locale: "en-US",
  });

  const columns = [
    {
      title: "Cuotas Pagadas Anteriormente",
      dataIndex: "monthlyInstallment",
      key: "monthlyInstallment",
    },
    {
      title: "Monto",
      dataIndex: "amount",
      key: "amount",
      render: (text) => renderMoney(text),
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      render: (text) => renderDate(text),
    },
  ];

  const fetchData = (value) => {
    setIsLoading(!isLoading);
    setUser();
    setPayData();
    let isDni = /^[1-9]+[0-9]*$/.test(value);
    if (value.includes("@")) {
      clientAxios
        .get(`/legacy/account-balance?email=${value}`)
        .then((response) => {
          const userData = response.data;
          if(userData.classroom[userData.classroom.length-1] === '') {
            userData.classroom.pop();
          }
          setUser(userData);
          setIsLoading(isLoading);
          setStatus("");
        })
        .catch((error) => {
          message.error(
            "No encontramos resultado para la búsqueda realizada, por favor verifique los datos ingresados."
          );
          console.debug(error);
          setIsLoading(isLoading);
          setStatus("error");
          setIsLoading(isLoading);
        });
    } else if (isDni && value.length >= 8) {
      clientAxios
        .get(`/legacy/account-balance?dni=${value}`)
        .then((response) => {
          setUser(response.data);
          setIsLoading(isLoading);
          setStatus("");
        })
        .catch((error) => {
          message.error(
            "No encontramos resultado para la búsqueda realizada, por favor verifique los datos ingresados."
          );
          console.debug(error);
          setIsLoading(isLoading);
          setStatus("error");
          setIsLoading(isLoading);
        });
    } else {
      setStatus("error");
      setIsLoading(isLoading);
    }
  };

  async function selectCommission({ value }) {
    const classroomIndex = await user.classroom
      .map((bill) => bill)
      .indexOf(value);
    setSelectedComission({
      classRoom: value,
      selectedClassRoom: classroomIndex,
      userId: user._id,
      courseName: user.course[classroomIndex],
      email: user.email,
      displayName: `${user.name} ${user.surname}`,
    });
    setInstallmentsLoading(true);
    clientAxios
      .post(`/legacy/account-balance/`, {
        studentId: user._id,
        selectedData: {
          classroom: user.classroom[classroomIndex],
          monthlyInstallments: user.monthlyInstallments[classroomIndex],
          price: user.price[classroomIndex],
          classroomIndex: classroomIndex,
        },
      })
      .then(({ data }) => {
        setInstallments(data.installments);
        setInstallmentsLoading(false);
        setPayData({
          paymentNumber: parseInt(data.installments[0].monthlyInstallment) + 1,
          paymentAmount: data.amountToPay,
          installmentsQuantity: data.monthlyInstallments,
          installment: data.installment,
        });
      })
      .catch((error) => { 
        setInstallmentsLoading(false);
        if(error.request?.status === 404) {
          message.error("No se registran pagos en la comision seleccionada");
          setInstallments([]);
        }
        else {
        message.error(error.message);
        console.debug(error);
      }
      });
  }

  const createPreference = () => {
    setLoading(false);
  };

  const redirectMP = () => {
    setLoading(true);

    clientAxios
      .post(`/legacy/preference`, {
        email: user.email,
        misc: selectedComission,
        description: `Cuota ${payData?.paymentNumber} - ${selectedComission.classRoom}`,
        price: parseInt(payData?.paymentAmount),
        quantity: 1,
        installment: payData?.paymentNumber,
        installmentsQuantity: payData?.installmentsQuantity,
        studentId: user._id,
        selectedData: {
          classroom: selectedComission.classRoom,
          monthlyInstallments: payData?.installmentsQuantity,
          classroomIndex: selectedComission.selectedClassRoom,
        },
      })
      .then(({ data }) => {
        if (mercadopago) {
          mercadopago.checkout({
            preference: {
              id: data.id,
            },
            render: {
              container: ".cho-container",
              label: "Pay",
            },
            autoOpen: true,
          });
        }
        //if(data) window.location.replace(data.sandbox_init_point)
        setLoading(false);
      })
      .catch((error) => {
        message.error(error.message);
        console.debug(error);
      });
  };

  useEffect(() => {
    emailInputRef.current.focus();
    if (payData && Object.keys(payData).length !== 0) createPreference();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payData]);

  useEffect(() => {
    if (user) {
      const value = { value: user.classroom[user.classroom.length - 1] };
      selectCommission(value);
    }
  }, [user]);

  return (
    <div className="defaultHeight">
      <Row type="flex" justify="center" className="marginTop cho-container">
        <Col sm={22} md={18} lg={12}>
          <Title>Pago de cuotas Online!</Title>
          <p className="helpParagraph">
            Ahora podes pagar <FileDoneOutlined /> y recibir tus recibos de
            manera online sin demoras y en simples pasos! Busca tu estado por{" "}
            <strong>Email</strong>, <strong>DNI </strong>
            (Argentina) o numero de identificacion Nacional para otros paises y
            selecciona el boton <strong>Pagar</strong>
          </p>
          <Row type="flex" justify="center">
            <Col sm={22} md={18} lg={12}>
              <Search
                loading={isLoading}
                placeholder="Email o DNI"
                onSearch={(value) => fetchData(value)}
                className="searchStyle"
                status={status}
                style={{ marginBottom: "0" }}
                ref={emailInputRef}
              />
              {status && (
                <Text style={{ color: "#ff4d4f" }}>
                  Por favor, verifica los datos que ingresaste.
                </Text>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {!user ? (
        <></>
      ) : (
        <>
          <Divider plain>
            <Title level={2}>
              {user.name} {user.surname}
            </Title>
          </Divider>
          <Row justify="center" gutter={[12]} style={{ paddingLeft: "50px" }}>
            <Col className="gutter-row" md={4} lg={2} align="center">
              <Title level={5}>Comisión:</Title>
            </Col>
            <Col className="gutter-row" md={4} lg={3}>
              <Select
                className="selectWidth"
                name="selectedCommission"
                placeholder="Selecciona una comisión"
                defaultValue={user.classroom[user.classroom.length - 1]}
                onChange={(e, values) => {
                  selectCommission(values);
                }}
              >
                {user.classroom?.map((classroom, index) => (
                  <Option key={index} value={classroom}>
                    {classroom}
                  </Option>
                ))}
              </Select>
            </Col>
            {!payData && installmentsLoading && <Skeleton active />}
            {payData && (
              <Col className="gutter-row" md={6} lg={3}>
                <Button
                  onClick={redirectMP}
                  loading={loading}
                  type="primary"
                  icon={<CreditCardOutlined />}
                >
                  Pagar cuota {payData?.paymentNumber}: $
                  {payData?.paymentAmount}
                </Button>
              </Col>
            )}
          </Row>
          {installments && (
            <>
              <Row align="center" className="tableTop">
                <Col sm={18} lg={16}>
                  <Table
                    dataSource={installments}
                    columns={columns}
                    pagination={{ pageSize: 10 }}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default HomeLegacyPayments;
