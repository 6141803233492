import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, message, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const auth = getAuth();

const RestorePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const info = () => {
    message.info(
      'Si el email ingresado se encuentra en nuestra base de datos, recibirás un email con instrucciones para recuperar tu contraseña',
    );
  };

  const error = () => {
    message.error('Algo salió mal, por favor intenta de nuevo en unos minutos');
  };

  const onFinish = (values) => {
    setIsLoading(true);
    const { email } = values;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        info();
        setIsLoading(false);
        navigate('/login');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        error();
      });
  };

  return (
    <Row
      type='flex'
      justify='center'
      style={{ minHeight: '80vh', marginTop: '1.3rem' }}
    >
      <Col xs={12} md={10} lg={6}>
        <h2>Recuperar Contraseña</h2>
        <Form
          name='restore-password'
          layout='vertical'
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete='off'
        >
          <Form.Item
            label='Email:'
            extra='Recibiras un email para restablecer tu contraseña'
            name='email'
            rules={[
              {
                required: true,
                pattern: '[a-z0-9]+@[a-z]+.[a-z]{2,3}',
                message: 'Por favor ingresa un email!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          {isLoading && (
            <Form.Item style={{textAlign: 'center'}}>
              <Spin indicator={antIcon} />
            </Form.Item>
          )}
          <Form.Item>
            <Button type='primary' htmlType='submit' disabled={isLoading} block>
              Enviar Email
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default RestorePassword;
