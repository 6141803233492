import React from 'react';
import { Button } from "antd";
import { UpOutlined } from '@ant-design/icons';

const ButtonUp = () => {
  return (
    <Button type="text" onClick={() => document.getElementById("top").scrollIntoView()}>
      <UpOutlined />
    </Button>
  )
}

export default ButtonUp
