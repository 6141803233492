import { React, useState, useEffect } from 'react';
import clientAxios from '../../../config/clientAxios';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Skeleton, Row, Col, message, Switch } from 'antd';
import styles from './monetization.module.css';
import CardPreview from './CardPreview/CardPreview';



function Monetization() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [course, setCourse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState ();
  const [discount, setDiscount] = useState ();
  const [finalPrice, setFinalPrice] = useState ();
  const [isPublished, setIsPublished] = useState();
  const [isLoadingButton, setIsLoadingButton] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const maxPriceValue = 999999.00;
  const maxDiscountPercentage = 100;
  const minPriceValue = 0;
  const minDiscountPercentage = 0;

  const success = (msg) => {
    message.success({
      content: msg,
      className: 'custom-class',
    });
  };
  
  const error = (msg) => {
    message.error({
      content: msg,
      className: 'custom-class',
    });
  };

  useEffect(() => {
    function loadCourse() {
      clientAxios.get(`/course/${id}`).then((response) => {
        if (!response || response.data.courseData.content.length === 0) {
          if (response.status === 404) {
            error('Ups...ha ocurrido un problema. intenta mas tarde.');
            navigate('/admin');
          }
        }
        if (response.data) {
          setCourse(response.data.courseData);
          setIsPublished(response.data.courseData.published);
          setIsLoading(true);
          setPrice(response.data.courseData.monetization.price);
          setDiscount(response.data.courseData.monetization.discount);
          setFinalPrice(response.data.courseData.monetization.finalPrice);
        }
      });
    }
    loadCourse();
  },[]);

  useEffect(() => {
    const loadPreview = () => {
      setFinalPrice((price - (price * discount) / 100).toFixed(2));
    };
    loadPreview();

  }, [price, discount]);

  const onFinish = () => {
    if (price <= maxPriceValue && discount <= maxDiscountPercentage) {
      course.monetization.price = price.toFixed(2);
      course.monetization.finalPrice = finalPrice;
      course.monetization.discount = discount;
      setIsLoadingButton(true);
      clientAxios.patch(`/course/${course._id}`, {
        monetization: course.monetization,
        published: isPublished,
      })
      .then((response) => {
        if(response.status === 200) {
          success("Cambio con exito");
        } else {
          error("ha ocurrido un error, intenta mas tarde");
        }
        setIsLoadingButton(false);
      });
    } else {
      error("Por favor revisa los parametros ingresados");
    }
  };

  const publishedCheck = (check) => {
    setIsPublished(check);
  };

  const onFinishFailed = (errorInfo) => {
    error("Ambos campos son requeridos");
  };

  const changePrice = (price) => {
    if (price > maxPriceValue || price < minPriceValue) {
      error(`El precio debe ser entre ${minPriceValue} y ${maxPriceValue}`);
      setIsButtonDisabled(true)
      setPrice(null);
      return;
    };
    setIsButtonDisabled(false)
    setPrice(price);
  }

  const changeDiscount = (discount) => {
    if (discount > maxDiscountPercentage || discount < minDiscountPercentage) {
      error(`El descuento debe ser entre ${minDiscountPercentage}% y ${maxDiscountPercentage}%`);
      setIsButtonDisabled(true);
      setDiscount(null);
      return;
    };
    setDiscount(discount);
    setIsButtonDisabled(false);
  }

  return (
    <Row justify='center'>
      <Col span={22}>
        <h1>Cambiar Precio</h1>
        <h2>{course.name}</h2>
        <Row className={styles.changePriceContainer} justify='space-around' align='middle'>
          {isLoading
            ?
            <>
              <Col span='10'>
                <Form
                name="basic"
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
                initialValues={{
                  price: course.monetization.price,
                  discount: course.monetization.discount,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                >
                  <Form.Item
                    label="Precio"
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingresa el precio!',
                      },
                    ]}
                  >
                    <Input onChange={(e) => changePrice(Number(e.target.value))} handle type='number'/>
                  </Form.Item>

                  <Form.Item
                    label="Descuento %"
                    name="discount"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingresa un descuento!',
                      },
                    ]}
                  >
                    <Input onChange={(e) => changeDiscount(Number(e.target.value))} type='number' />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Row justify='space-evenly' align='middle'>
                      <Col>
                        <Switch
                          className={styles.btn} 
                          defaultChecked={isPublished}
                          checkedChildren="Publicado"
                          unCheckedChildren="No publicado"
                          id="switch_publish"
                          onChange={(checked) => {
                            publishedCheck(checked);
                          }}
                          />
                        <Button 
                          disabled={isButtonDisabled} 
                          className={styles.btn} 
                          type="primary" 
                          htmlType="submit" 
                          loading={isLoadingButton}
                          >
                          Guardar Cambios
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
             
              </Col>
              <Col xs={{span: 24, order:1}} lg={{span: 7, order:2}}>
                <Row justify='center'>
                  <CardPreview course={course} price={price?.toFixed(2)} finalPrice={finalPrice} discount={discount} />
                </Row>
              </Col>
            </>
            :
            <>
              <Col span={11}> <Skeleton active/> <Skeleton active/></Col>
              <Col span={11} offset={2}> <Skeleton active/> <Skeleton active/></Col>
            </>
          }
        </Row>
      </Col>
    </Row>
  )
}

export default Monetization;
