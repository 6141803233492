import React from "react";
import PropTypes from "prop-types";
import Styles from "./priceCourse.module.css";
import { Col, Typography } from "antd";

const { Title } = Typography;

function PriceCourse({ course }) {
  return (
    <>
      {course.monetization && (
        <Col className={Styles.infoPrice}>
          {course.monetization.discount >= 100 ||
          course.monetization.finalPrice === 0 ? (
            <Title style={{ marginLeft: "7px" }} level={3}>
              ¡GRATIS!
            </Title>
          ) : (
            <>
              <Title level={3} className={Styles.tittleMonetization}>
                ${course.monetization.finalPrice}
              </Title>
              <Title
                level={3}
                delete={true}
                className={Styles.tittleMonetization}
              >
                ${course.monetization.price}
              </Title>
              <Title level={4} className={Styles.tittleMonetization}>
                {course.monetization.discount}% off
              </Title>
            </>
          )}
        </Col>
      )}
    </>
  );
}

PriceCourse.propTypes = {
  course: PropTypes.object,
};

export default PriceCourse;
