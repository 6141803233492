import "./Sidebar.css";
import { Menu } from "antd";
import {
  UnorderedListOutlined,
  BarChartOutlined,
  FormOutlined,
  SettingOutlined,
  QuestionOutlined,
  UserOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


export const Sidebar = () => {
  const navigate = useNavigate();

  const selectOption = (option) => { 
    navigate(option);
  }
  return (
    <Menu defaultSelectedKeys={['1']} >
      <Menu.Item key="menu-item1" className="optionsList" onClick={() => {
      selectOption("/admin/course/list");
    }}>
        <UnorderedListOutlined /> Cursos
      </Menu.Item>
      <Menu.Item key="menu-item2" disabled={true}>
        <BarChartOutlined /> Student Performance 
      </Menu.Item>
      <Menu.Item key="menu-item3" onClick={() => {
      selectOption("/admin/categories");
    }}>
        <FormOutlined /> Categorias
      </Menu.Item>
      <Menu.Item key="menu-item4" onClick={() => {
      selectOption("/admin/listQuizes");
    }}>
        <QuestionOutlined /> Quiz
      </Menu.Item>
      <Menu.Item key="menu-item5" onClick={() => {
      selectOption("/admin/users");
    }}>
        <UserOutlined /> Usuarios
      </Menu.Item>
      <Menu.Item key="menu-item6" disabled={true}>
        <SettingOutlined /> Settings
      </Menu.Item>
    </Menu>
  );
};
