
import { Modal } from 'antd';

export default function DeleteModal({ isModalVisible, handleOk, handleCancel, deleteType }) {

    return (
        <>
            <Modal 
                title="Eliminar respuesta" 
                visible={isModalVisible} 
                onOk={handleOk} 
                onCancel={handleCancel} 
                cancelText='Cancelar' 
                okText='Eliminar' 
                okType='danger'
            >
                <p>¿Estas seguro que quieres borrar esta {deleteType === 'answer' ? 'respuesta' : 'pregunta'}?</p>
            </Modal>
        </>
    );
}