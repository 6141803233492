import styles from './Winner.module.css'
const Winner = () => {
  return (
    <>
      <section className={styles.bg}>
        <div className={styles.winnerWrap}>
          <div className={styles.border}></div>
          <div className={styles.medalBox}>
          <i class="fas fa-medal"></i>
          </div>
          <h1>¡Felicidades!</h1>
          <div className={styles.winnerRibbon}>GANASTE</div>
          <div className={styles.rightRibbon}></div>
          <div className={styles.leftRibbon}></div>
        </div>
      </section>

      <p className={styles.winnerInstructions}>Felicidades! Nos pondremos pronto en contacto para coordinar la entrega del premio.</p>
     
    </>
  );
};

export default Winner;
