import { Alert, Col, message, Row } from 'antd'
import { CheckCircleTwoTone, MailOutlined } from '@ant-design/icons'
import './Legacy.css'
import { useEffect } from 'react'
import slotInstructions from '../../assets/img/slot-instructions.jpg'; 

export default function Success() {

    const queryParams = new URLSearchParams(window.location.search)
    const paymentId = queryParams.get('payment_id')
    const status = queryParams.get('status')
    const collection_id = queryParams.get('collection_id')
    useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentId, status, collection_id])

    return(
        <div className="minHeigth center blackBackground">
            <div>
                <Row justify='center'>
                    <Col>
                        <CheckCircleTwoTone twoToneColor='#B7EB8F' className='icons'/>
                    </Col>
                </Row>
                <Row justify='center'>
                    <Alert message={`Su pago fue procesado con éxito - nro de op: ${collection_id}`} type='success'/>
                </Row>
                <Row justify='center'>                  
                    <p className='emailSuccessPayment'> El comprobante te llegará por email  <MailOutlined /> pronto, chekea tu casilla de correo</p>
                </Row>
                <Row justify='center'>                  
                    <p className='emailSuccessPayment'> No te olvide de Jugar a RollingRoulette Escanenado el codigo QR en el recibo de pago</p>
                   
                </Row>
                <Row justify='center'>                  
                   <img src={slotInstructions}></img>
                </Row>
            </div>
        </div>
    )
}