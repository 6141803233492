import React, { useState } from "react";
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import useMercadoPago from "../../hooks/useMercadoPago";
import { Input, Row, Col, Space, Form } from 'antd';
import styles from './mercadoPagoForm.module.css';

const INITIAL_STATE = {
  cvc: "",
  cardExpirationMonth: "",
  cardExpirationYear: "",
  focus: "cardNumber",
  cardholderName: "",
  cardNumber: "",
  issuer: "",
};

export default function MercadoPagoForm({course, setResultError, paymentSuccess, setPaymentProcessing}) {
  const [state, setState] = useState(INITIAL_STATE);
  const resultPayment = useMercadoPago(course.monetization.price, course, setResultError, paymentSuccess, setPaymentProcessing);

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.dataset.name || e.target.name]: e.target.value,
    });
  };

  const handleInputFocus = (e) => {
    setState({ ...state, focus: e.target.dataset.name || e.target.name });
  };

  const handleInputChangeMonth = (e) => {
    let month = e.target.value;

    if ( month > 0 && month <= 12) {
      setResultError();
      if (month.toString().length === 1) {
        setState({
          ...state,
          [e.target.dataset.name || e.target.name]: 0+month,
        });
      } else {
        setState({
          ...state,
          [e.target.dataset.name || e.target.name]: month,
        });
      }
    } else {
      setResultError("Verificar la fecha de vencimiento");
    }
  };

  return (
    <div className="container">
      <Row >
        <Col>
          <Space size={[8, 16]}>
            <Card
              cvc={state.cvc}
              expiry={state.cardExpirationMonth + state.cardExpirationYear}
              name={state.cardholderName}
              number={state.cardNumber}
              focused={state.focus}
              brand={state.issuer}
            />
          </Space>
        </Col>
      </Row>
      <Form
        name="form-checkout"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
      >   
        <Form.Item
          label="Número de tarjeta"
          name="cardNumber"
          rules={[
            { 
              required: true,
              max: 19,
              min: 15,
              maxLength: 19,
              message: "Por favor revisa su número de tarjeta."
            }
          ]}
          style={{marginTop: '50px'}}
        >
          <Input
            type="number"
            id="form-checkout__cardNumber"
            name="cardNumber"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            style={{width: '100%'}}
            />
        </Form.Item>
          <Form.Item
            label="Vencimiento"
            name="month"
            rules={[
              {
                required: true,
              }
            ]}
          >
            <Form.Item
              name="month"
              rules={[
                { 
                  required: true,
                  min: 1,
                  max: 2,
                  message: "Por favor revisar el mes de vencimiento",
                }
              ]}
              style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
            >
              <Input
                type="number"
                name="cardExpirationMonth"
                id="form-checkout__cardExpirationMonth"
                onChange={handleInputChangeMonth}
                onFocus={handleInputFocus}
              />
            </Form.Item>
            <Form.Item
              name="year"
              rules={[
                { 
                  required: true,
                  min: 2,
                  max: 4,
                  message: "Por favor revisa el año de vencimiento",
                }
              ]}
              style={{ display: 'inline-block', width: '50%', marginLeft: 5 }}
            >
              <Input
                type="number"
                name="cardExpirationYear"
                id="form-checkout__cardExpirationYear"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="security"
            label="Código de seguridad"
            rules={[
              { 
                required: true,
                min: 3,
                max: 4,
                message: "Por favor revisa el código de seguridad.",
              }
            ]}
            >
              <Input
                type="number"
                name="cvc"
                id="form-checkout__securityCode"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
          </Form.Item>
          <Form.Item
            name="name"
            label="Titular de la tarjeta"
            rules={[
              { 
                required: true,
                message: "Revisa el nombre del titular de la tarjeta." 
              }
            ]}
          >
            <Input
              type="text"
              name="cardholderName"
              id="form-checkout__cardholderName"
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </Form.Item>
          <Form.Item 
            name="mail"
            label="Correo electrónico"
            rules={[
              {
                type: 'email',
                message: 'No es un email válido',
              },
              {
                required: true,
                maxLength: 320,
                message: 'Revise su correo electónico.',
              },
            ]}
            >
              <Input
                type="email"
                name="cardholderEmail"
                id="form-checkout__cardholderEmail"
                onFocus={handleInputFocus}
              />
          </Form.Item>
          <Form.Item
          >
            <select  className={styles.select}
              name="issuer"
              id="form-checkout__issuer"
              on
            ></select>
            <select  className={styles.select}
              name="identificationType"
              id="form-checkout__identificationType"
            ></select>
          </Form.Item>
          <Form.Item
            name="documentation"
            label="Número de documento"
            rules={[
              { 
                required: true,
                max: 8,
                message: "Por favor revisa su número de documento",
              }
            ]}
            >
              <Input
                type="number"
                name="identificationNumber"
                id="form-checkout__identificationNumber"
              />
          </Form.Item>
        <div className="form-control">
          <select className={styles.select}
            name="installments"
            id="form-checkout__installments"
          ></select>
        </div>
        <div className={styles.hidden}>
          <button type="submit" id="form-checkout__submit">
            Pagar
          </button>
        </div>
        <progress value="0" className={`progress-bar ${styles.progressBar}`}>
          Cargando...
        </progress>
      </Form>
    </div>
  );
}
