import Success from "./Success"
import Failure from "./Failure"
import Pending from "./Pending"
import { useParams } from "react-router-dom"

export default function Legacy() {
    const { status } = useParams()
    return(
        <>
            {
                status === 'success' ?
                    <Success />
                : status === 'failure' ?
                    <Failure />
                : status === 'pending' ?
                    <Pending />
                : 
                    <></>
            }
        </>
    )
}