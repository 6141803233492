import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row, Col,  message, Collapse, Skeleton, Comment, Tooltip, List } from "antd";
import moment from 'moment';
import styles from './detaillCourseId.module.css';
import clientAxios from '../../config/clientAxios';
import PriceCourse from '../PriceCourse/PriceCourse';
import ButtonBuyCourse from '../ButtonBuyCourse/ButtonBuyCourse';

function DetailCourse () {
  const [contentCourse, setContentCourse] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cover, setCover] = useState(null);
  const [videoPlayerUrl, setVideoPlayerUrl] = useState(null);
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const { id } = useParams();
  
  useEffect(() => {
    loadCourse();
  }, [id]);

  const data = [
    {
      actions: [<span key="comment-list-reply-to-0">Reply to</span>],
      author: 'Han Solo',
      avatar: 'https://joeschmoe.io/api/v1/random',
      content: (
        <p>
          We supply a series of design principles, practical patterns and high quality design
          resources (Sketch and Axure), to help people create their product prototypes beautifully and
          efficiently.
        </p>
      ),
      datetime: (
        <Tooltip title={moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')}>
          <span>{moment().subtract(1, 'days').fromNow()}</span>
        </Tooltip>
      ),
    },
    {
      actions: [<span key="comment-list-reply-to-0">Reply to</span>],
      author: 'Han Solo',
      avatar: 'https://joeschmoe.io/api/v1/random',
      content: (
        <p>
          We supply a series of design principles, practical patterns and high quality design
          resources (Sketch and Axure), to help people create their product prototypes beautifully and
          efficiently.
        </p>
      ),
      datetime: (
        <Tooltip title={moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss')}>
          <span>{moment().subtract(2, 'days').fromNow()}</span>
        </Tooltip>
      ),
    },
  ];

  function loadCourse() {
    clientAxios.get(`/course/public/${id}`).then((response) => {
      if (!response || response.data.content.length === 0) {
        if (response.status === 404) {
          message.error('Ups...ha ocurrido un problema. intenta mas tarde.');
          navigate('/my-courses');
        } else {
          message.error('Ups...no se encontro el contenido curso.');
          navigate('/my-courses');
        }
      }
      if (response.data.content) {
        const videoId = response.data.coverVideo ? response.data.coverVideo.match(/[^\/]+$/)[0] : "163758281";
        setVideoPlayerUrl(`https://player.vimeo.com/video/${videoId}?h=8435b03&color=ffffff&title=0&byline=0&portrait=0&badge=0`);      
        setContentCourse(response.data);
        setIsLoading(false);
        setCover(response.data.coverThumbnail);
      }
    });
  }

  useEffect(() => {
    loadCourse();
  },[])
  
  return (
    <Row justify="center">
      <Col span={22}>
        <Row>
          {!isLoading ?
            <>
              <Row justify="space-between" className={styles.collapse}>
                <Col xs={24} md={24} lg={12}>
                  <h1>{contentCourse.name}</h1>
                  <div className={styles.priceCont}>
                    <ButtonBuyCourse finalPrice={contentCourse.monetization.finalPrice} discount={contentCourse.monetization.discount} name={contentCourse.name} />
                    <PriceCourse course={contentCourse} />
                  </div>
                  <div className={styles.containerDescription}>
                    <div className={styles.containerVideo}>
                      <iframe src={videoPlayerUrl} width="auto" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>
                    <div className={styles.containerInfo}>
                      <span>{contentCourse.description}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={24} lg={10}>
                  <h1>Cover</h1>
                  <Col className={styles.contImg}>
                    <img className={styles.img} src={cover} alt="cover"/>
                    <div className={styles.priceCont}>
                      <ButtonBuyCourse finalPrice={contentCourse.monetization.finalPrice} discount={contentCourse.monetization.discount}/>
                      <PriceCourse course={contentCourse} />
                    </div>
                  </Col>
                  <div>
                    <h2>Cursos relacionados</h2>
                    <p>Lorem ipsum dolor sit amet. Est eaque autem sit numquam dignissimos et vero veniam.</p>
                  </div>
                </Col>
              </Row>
              <Row className={styles.collapse} justify="space-between">
                <Col xs={24} md={24} lg={12}>
                  <h1>Contenido</h1>
                  <div>
                    {contentCourse.content.map((collapse, index) => {
                      return(
                      <Collapse key={`collapse-${index}`}>
                        <Panel header={collapse.chapter}>
                          <p>Loreaam ipsum dolor sit amet. Est eaque autem sit numquam dignissimos et vero veniam.</p>
                        </Panel>
                      </Collapse>
                      )
                    })}
                  </div>
                </Col>
                <Col xs={24} md={24} lg={10}>
                  <h1>Reseñas</h1>
                  <div>
                    <List
                      className="comment-list"
                      header={`${data.length} replies`}
                      itemLayout="horizontal"
                      dataSource={data}
                      renderItem={item => (
                        <li>
                          <Comment
                            actions={item.actions}
                            author={item.author}
                            avatar={item.avatar}
                            content={item.content}
                            datetime={item.datetime}
                          />
                        </li>
                      )}
                    />
                  </div>
                </Col>
              </Row>
            </>
            :
            <>
              <Col span={11}> <Skeleton active/> <Skeleton active/></Col>
              <Col span={11} offset={2}> <Skeleton active/> <Skeleton active/></Col>
              <Col span={11}> <Skeleton active/> <Skeleton active/></Col>
              <Col span={11} offset={2}> <Skeleton active/> <Skeleton active/></Col>
            </>
          }
        </Row>
      </Col>
    </Row>
  )
}

export default DetailCourse;
