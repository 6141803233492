import { Alert, Col, Row } from 'antd'
import { InfoCircleTwoTone } from '@ant-design/icons'
import './Legacy.css'

export default function Pending() {
    return(
        <div className="minHeigth center">
            <div>
                <Row justify='center'>
                    <Col>
                        <InfoCircleTwoTone twoToneColor='lightblue' className='icons'/>
                    </Col>
                </Row>
                <Row justify='center'>
                    <Alert message="Su pago está pendiente" type='info'/>
                </Row>
            </div>
        </div>
    )
}