import Multiple from "../../Admin/Quiz/typeFormats/Multiple";
import Simple from "../../Admin/Quiz/typeFormats/Simple";
import clientAxios from "../../../config/clientAxios";
import { useEffect } from "react";
import { useState } from "react";
import { message, Row, Col, Form, Card, Button, Skeleton, Layout } from "antd";
import styles from "./ViewQuiz.module.css";
import { equals } from "../../../helpers/compareHelper"; //IS USED, DONT DELETE
import { getLocalStorage } from "../../../helpers/localStorageHelper";
import Results from "./Results";
import { SIMPLE, MULTIPLE } from "../../../constants/quizType";
import ButtonUp from "../../ButtonUp/ButtonUp";
import Restart from "./Restart";

const { Content } = Layout;

export default function ViewQuiz({ quizId, courseId, setFinished }) {
  const [data, setData] = useState();
  const [send, setSend] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [resultId, setResultId] = useState("");
  const [user, setUser] = useState();
  const [attemps, setAttemps] = useState(0);
  const [MAX_ATTEMPTS, setMAX_ATTEMPTS] = useState();
  const [loadingAttempInfo, setLoadingAttempInfo] = useState(false);

  const getQuiz = () => {
    clientAxios
      .get(`/quiz/${quizId}`)
      .then(({ data }) => {
        setMAX_ATTEMPTS(data.chance || 3);
        setData(data)
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    if(quizId) getQuiz();
  }, [quizId]);

  useEffect(() => {
    setUser(getLocalStorage("user"));
  }, []);

  const verifyAnswers = (actualAnswers) => {
    if (data.questions) {
      const answersSelected = Object.values(actualAnswers);
      let resultAnswers = answersSelected.map((answer, index) => {
        let sortedCorrect = data.questions[index].correctAnswers.sort(
          (a, b) => a - b
        );
        if (data.questions[index].type === SIMPLE) {
          return sortedCorrect.equals([answer]);
        } else if (data.questions[index].type === MULTIPLE) {
          let numericSort = answer.sort(
            (smallest, largest) => smallest - largest
          );
          return sortedCorrect.equals(numericSort);
        }
        return null;
      });
      let correctAnswers = resultAnswers.filter(
        (answer) => answer === true
      ).length;
      let totalQuestions = data.questions.length;
      let successPercentage = (correctAnswers / totalQuestions) * 100;
      return Math.round(successPercentage);
    }
    return null;
  };

  const numberOfAttemps = async () => {
    let userId = user.id;
    setLoadingAttempInfo(true);
    const {data} = await clientAxios.get(`/users/${userId}`)
    setLoadingAttempInfo(false);
    const course = data.courses.find( course => course.course === courseId)
    const attemps = course.quiz.filter(quiz => quiz.id === quizId);
    setAttemps(attemps.length);
  }

  useEffect(() => {
    if(data != null) {
      numberOfAttemps();
    }
  }, [data])
  
  const submitAnswers = async (answers) => {
    try {
      let successPercentage = verifyAnswers(answers)
        ? verifyAnswers(answers)
        : 0;
      let userId = user.id;
        const getQuizData = await clientAxios.patch(`/users/${userId}`, {
          quiz: {
            id: quizId,
            successPercentage,
            attempt: attemps + 1,
          },
          course: {
            id: courseId,
            chance: MAX_ATTEMPTS,
          },
        });
        getQuizData.data.filter((quiz) => {
          if (quiz.id === quizId) {
            return setResultId(quiz.id);
          }
        });
        setSend(false);
        setShowResult(true);
    } catch (error) {
      message.error("Ocurrió un error al cargar las respuestas, por favor intente mas tarde.");
    }
  };

  const errorSubmit = (answers) => {
    console.error(answers, "error");
  };

  if(attemps >= MAX_ATTEMPTS){
    return (
      <Content>
        <Restart user={user} course={courseId} quizId={quizId} title={data.title} getQuiz={() => getQuiz()} />
      </Content>
    );
  }
  else if (data && showResult) {
    return (
      <Results
        user={user}
        id={resultId}
        courseId={courseId}
        setShowResult={(b) => setShowResult(b)}
        setFinished={setFinished}
        numberOfAttemps={numberOfAttemps}
      />
    );
  } else if (data && attemps < MAX_ATTEMPTS) {
    return (
      <Form
        onFinish={submitAnswers}
        onFinishFailed={errorSubmit}
        className={styles.minimalView}
      >
        <Row justify="center">
          <h1>{data.title}</h1>
        </Row>
        {data.questions.map((question, indexQuestion) => (
          <Row justify="center" gutter={16}>
            <Col>
              <Card span={24} className={styles.cardStyle}>
                <Col lg={24} xs={12} md={24}>
                  <h1 className={styles.titleSize}>{question.title}</h1>
                </Col>
                {data.questions[indexQuestion].type === "multiple" ? (
                  <>
                    <Col span={24}>
                      <Multiple
                        form={data.questions[indexQuestion]}
                        indexQuestion={indexQuestion}
                        type="view"
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={24}>
                      <Simple
                        form={data.questions[indexQuestion]}
                        indexQuestion={indexQuestion}
                        type="view"
                      />
                    </Col>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        ))}
        <div className={styles.confirmText}>
          {send ? (
            <>
              <Row>
                <Col xs={24} lg={24}>
                  <p>¿Está seguro que quiere enviar el questionario?</p>
                </Col>
              </Row>
              <div>
                <Row className={styles.justifyContent}>
                  <Col>
                    <Button
                      className={styles.buttonStyle}
                      type="dashed"
                      onClick={() => setSend(false)}
                    >
                      Cancelar
                    </Button>
                  </Col>
                </Row>
                <Row className={styles.justifyContent}>
                  <Col>
                    <Button
                      className={styles.buttonStyle}
                      type="primary"
                      htmlType="submit"
                    >
                      Confirmar
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <Row justifyContent="center" align="center">
              <Col span={12} offset={0}>
                <Button
                  className={styles.buttonStyle}
                  type="primary"
                  onClick={() => setSend(true)}
                >
                  Enviar respuestas
                </Button>
                <ButtonUp />
              </Col>
            </Row>
          )}
        </div>
      </Form>
    );
  }

  return <div><Skeleton active /></div>;
}
