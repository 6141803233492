import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import CourseContent from "./Content/CourseContent";
import clientAxios from "../../../config/clientAxios";
import "./CourseCreate.css";
import {
  Form,
  Tabs,
  Divider,
  Row,
  Col,
  Image,
  Upload,
  Breadcrumb,
  Button,
  Space,
  Input,
  PageHeader,
  message,
  Tag,
  Modal,
  Select
} from "antd";
import { useParams } from "react-router-dom";
import {
  VideoCameraAddOutlined,
  FileTextOutlined,
  SaveOutlined,
  UploadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import useCategoriesFetch from "../../../hooks/useCategoriesFetch";
import { VIMEO_VIDEO } from "../../../constants/contentType";

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Option } = Select;

function CourseCreate() {
  const { id } = useParams();
  const [idParams, setIdParams] = useState(id);
  const [thumbnailData, setThumbnailData] = useState("");
  const [coverData, setCoverData] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  
  const [course, setCourse] = useState({
    name: "",
    description: "",
    coverVideo: "",
    tags: [],
    category: {
      name: ""
    },
    subCategory: {
      name: ""
    },
    content: [
      { chapter: "", topics: [{ name: "", contentType: VIMEO_VIDEO, embededUrl: "", finished: false }] },
    ]
  });
  const [activeKey, setActiveKey] = useState("1");
  const [newTag, setNewTag] = useState("");
  const [titleError, setTitleError] = useState({
    icon: "",
    description: "",
  });
  const [thumbError, setThumbError] = useState({
    icon: "",
    description: "",
  });
  const [tagError, setTagError] = useState({
    icon: "",
    description: "",
  });
  const [subCategoryError, setSubCategoryError] = useState({
    icon: "",
    description: "",
  });

  const topicRef = useRef()

  const { 
    data,
    isLoading,
    getCategory, 
    subCategories, 
    getSubCategories 
  } = useCategoriesFetch()

  useEffect(() => {
    getCategory()
    getSubCategories()
  }, [])

  useEffect(() => {
    id && getCourseDataById();
  }, [idParams]);

  function getCourseDataById() {
    clientAxios.get(`/course/${id}`).then((response) => {
      if (!response || response.data.length === 0) {
        if (response.status === 404) {
          message.error("Ups...ha ocurrido un problema. intenta mas tarde.");
        } else {
          message.error("Ups...no se encontro el curso.");
        }
      }
      if (response.data && response.data.courseData) {
        setCourse(response.data.courseData);
      }
      else {
        message.error("Ups...ha ocurrido un problema. intenta mas tarde.");
      }
    });
  }

  function setCourseData(event) {
    const property = event.target.name;
    const updateCourseData = course;
    updateCourseData[property] = event.target.value;
    setCourse({...updateCourseData});
  }

  function setCourseDataSelects(selectData) {
    const property = selectData.name;
    const updateCourseData = course;
    updateCourseData[property] = selectData.value;
    updateCourseData[property] = {
      name: selectData.value,
      _id: selectData.key
    };
    setCourse({...updateCourseData});
  }

  //Save
  const onKeyChange = (key) => setActiveKey(key);

  function updateCourse(newCourse) {
    clientAxios
      .patch(`/course/${idParams}`, {
        ...newCourse,
      })
      .then(function (response) {
        success();
        setIsSaving(false);
      })
      .catch(function (error) {
        errorMessage();
        console.debug(error);
        setIsSaving(false);
      });
  }

  function saveCourse() {
    let newCourse = {};
    setIsSaving(true);
    if (
      course.tags.length > 0 &&
      course.name !== "" &&
      (course.coverThumbnail || thumbnailData !== "") &&
      ((course.category.name === "") || (course.category.name !== "" && course.subCategory.name !== ""))
    ) {
      newCourse = {
        name: course.name,
        description: course.description,
        published: false,
        completed: 0,
        coverThumbnail: thumbnailData,
        cover: coverData,
        tags: course.tags,
        coverVideo: course.coverVideo,
        carrerpath: 0,
        category: course.category,
        subCategory: course.subCategory,
        monetization: {
          price: 0,
          finalPrice: 0,
          discount: 0,
        }
      };

      newCourse.coverThumbnail = thumbnailData ? thumbnailData : course.coverThumbnail;
      newCourse.cover = coverData ? coverData : course.cover;

      if (idParams) {
        return updateCourse(newCourse);
      }

      clientAxios
        .post("/course", {
          ...newCourse,
        })
        .then(function (response) {
          success();
          setIdParams(response.data._id);
          setActiveKey("2");
          setIsSaving(false);
        })
        .catch(function (error) {
          errorMessage();
          console.debug(error);
          setIsSaving(false);
        });
    } else {
      let focusField = "";
      warning();
      if (course.name === "") {
        focusField = "title";
        setTitleError({
          icon: "error",
          description: "El campo de Titulo es obligatorio",
        });
      }

      if (thumbnailData === "") {
        focusField = focusField === "" ? "thumbnail" : focusField;
        setThumbError({
          icon: "error",
          description: "La imagen de Thumbnail es obligatoria",
        });
      }

      if (course.tags.length === 0) {
        focusField = focusField === "" ? "tagInput" : focusField;
        setTagError({
          icon: "error",
          description: "Se requiere al menos 1 Tag para continuar",
        });
      }

      if(course.category.name !== "" && course.subCategory.name === "") {
        focusField = focusField === "" ? "subCategory" : focusField;
        setSubCategoryError({
          icon: "error",
          description: "Si se selecciona una categoria, debe seleccionar una subcategoria",
        });
      }
      
      setIsSaving(false);
      document.getElementById(focusField).focus();
    }
  }

  function success() {
    Modal.success({
      content: "Curso guardado existosamente",
    });
  }

  function warning() {
    Modal.warning({
      title: "Campos obligatorios",
      content: "Debe rellenar todos los campos obligatorios",
    });
  }

  function errorMessage() {
    Modal.error({
      title: "Error en Carga",
      content:
        "Se produjo un error en la carga, intentelo nuevamente mas tarde",
    });
  }

  function deleteCoverOrThumbnailImage(imageType) {
     const courseUpdated = course;
     delete courseUpdated[imageType];
     setCourse({...courseUpdated});
  }

  function addNewTag() {
    let repeated = course.tags.some((tag) => tag.name === newTag);
    if (newTag !== "" && newTag.length > 2 && !repeated) {
      let addedTag = {
        name: newTag,
        value: newTag,
        visible: true,
      };
      const updatedCourse = course;
      updatedCourse.tags = [...course.tags, addedTag];
      setCourse({...updatedCourse});
      setNewTag("");
    }
    if (repeated) {
      setTagError({
        icon: "warning",
        description: "Este tag ya existe en la lista",
      });
    } else if (newTag.length < 3) {
      setTagError({
        icon: "warning",
        description: "El Tag debe tener al menos un largo de 3 caracteres",
      });
    } else {
      setTagError({
        icon: "",
        description: "",
      });
    }
  }

  function removeTag(removedTag) {
    const updatedCourse = course;
    const newtagList = course.tags.filter((tag) => tag !== removedTag);
    updatedCourse.tags = newtagList;
    setCourse({...updatedCourse});
  }

  function editTag(index, tag) {
    //TODO: add editable option for tags
    console.debug(`Index: ${index}`);
    console.debug(`Tag: ${tag.name}`);
  }

  //dummy function not to get an error on antd upload

  function dummyFunction({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  //set the states fdata from the image uploads
  function saveImageToState(state, imagedata) {
    let base64Data = "";
    let reader = new FileReader();
    reader.onload = () => {
      base64Data = reader.result;
      switch (state) {
        case "thumbnail":
          setThumbnailData(base64Data);
          break;
        case "cover":
          setCoverData(base64Data);
          break;
        default:
          console.log("none selected");
      }
    };
    reader.readAsDataURL(imagedata);
  }

  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
  }

  const routes = [
    {
      path: '../../admin/course/list',
      breadcrumbName: 'Cursos',
    },
    {
      path: 'admin/course/Create',
      breadcrumbName: 'Crear o Actualizar Cursos',
    },
  ];

  return (
    <>
      <PageHeader
        breadcrumb={<Breadcrumb itemRender={itemRender} routes={routes} />}
      />
      <Tabs activeKey={activeKey} onChange={onKeyChange}>
        <TabPane
          tab={
            <span className="tabtitle">
              <VideoCameraAddOutlined />
              Datos
            </span>
          }
          key="1"
          className="tabcontainer"
        >
        
          <Form layout="vertical">
            <Row className="row" gutter={[16, 16]}>
              <Divider>Imagenes</Divider>
              <Col span={12}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                  size="large"
                >
                  {course.coverThumbnail ? (
                    <div>
                      <span>Thumbnail: </span>{" "}
                      <Image width={150} src={course.coverThumbnail} />{" "}
                      <Button
                        onClick={() => {
                          deleteCoverOrThumbnailImage("coverThumbnail");
                        }}
                        shape="circle"
                        type="danger"
                        icon={<DeleteOutlined />}
                      />
                    </div>
                  ) : (
                    <Form.Item
                      label="Thumbnail"
                      name="thumbnail"
                      validateStatus={thumbError.icon}
                      help={thumbError.description}
                      rules={[
                        {
                          required: true,
                          message: "The Thumbnail image is an obligatory field",
                        },
                      ]}
                    >
                      <Upload
                        customRequest={dummyFunction}
                        listType="picture"
                        maxCount={1}
                        accept=".jpg,.png"
                        beforeUpload={(file) => {
                          saveImageToState("thumbnail", file);
                        }}
                        onChange={() => {
                          setThumbError({
                            icon: "",
                            description: "",
                          });
                        }}
                        onRemove={() => {
                          setThumbnailData("");
                        }}
                      >
                        <Button icon={<UploadOutlined />}>Cargar</Button>
                      </Upload>
                    </Form.Item>
                  )}
                </Space>
              </Col>
              <Col span={12}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                  size="large"
                >
                  {course.cover ? (
                    <div>
                      <span>Cover: </span>{" "}
                      <Image width={150} src={course.cover} />{" "}
                      <Button
                        shape="circle"
                        type="danger"
                        onClick={() => {
                          deleteCoverOrThumbnailImage("cover");
                        }}
                        icon={<DeleteOutlined />}
                      />
                    </div>
                  ) : (
                    <Form.Item label="Cover" name="cover">
                      <Upload
                        customRequest={dummyFunction}
                        listType="picture"
                        maxCount={1}
                        accept=".jpg,.png"
                        beforeUpload={(file) => {
                          saveImageToState("cover", file);
                        }}
                        onChange={() => {
                          setThumbError({
                            icon: "",
                            description: "",
                          });
                        }}
                        onRemove={() => {
                          setCoverData("");
                        }}
                      >
                        <Button icon={<UploadOutlined />}>Cargar</Button>
                      </Upload>
                    </Form.Item>
                  )}
                </Space>
              </Col>
            </Row>
            <Row className="row" gutter={[16, 16]}>
              <Divider>Publicacion</Divider>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Titulo"
                      validateStatus={titleError.icon}
                      help={titleError.description}
                      rules={[
                        {
                          required: true,
                          message: "The Title field is required",
                        },
                      ]}
                    >
                      <Input
                        showCount
                        name="name"
                        maxLength={80}
                        className="rounded-soft"
                        value={course.name}
                        onChange={(e) => {
                          setCourseData(e);
                          setTitleError({
                            icon: "",
                            description: "",
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Video Portada (URL)"
                      validateStatus={titleError.icon}
                      help={titleError.description}
                      rules={[
                        {
                          required: true,
                          message: "The Title field is required",
                        },
                      ]}
                    >
                      <Input
                        showCount
                        name="coverVideo"
                        maxLength={80}
                        className="rounded-soft"
                        value={course.coverVideo}
                        onChange={(e) => {
                          setCourseData(e);
                          setTitleError({
                            icon: "",
                            description: "",
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item label="Descripcion">
                      <TextArea
                        value={course.description}
                        className="rounded-soft"
                        name="description"
                        onChange={(e) => {
                          setCourseData(e);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} md={12}>
                    <Form.Item label="Categoria">
                      <Select
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        disabled={isLoading}
                        placeholder='Seleccionar Categoria'
                        name="category"
                        value={course?.category?.name ? course.category.name : ''}
                        className="selects"
                        onChange={(e, values) => {
                          setCourseDataSelects(values);
                        }}
                        >
                          <Option hidden name="category" value="">Seleccione Categoria</Option>
                          {
                            data.map((category) => (
                              <Option key={category._id} name="category" value={category.name}>{category.name}</Option>
                            ))
                          }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item 
                      label="Subcategoria"
                      validateStatus={subCategoryError.icon}
                      help={subCategoryError.description}
                      rules={[
                        {
                          required: course.category ? 
                            course.category.name !== "" ? 
                            true : false 
                          : false,
                          message: "The subCategory field is required",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        disabled={!course.category || course.category?.name === ""}
                        placeholder='Seleccionar Subcategoria'
                        name="subCategory"
                        value={course.subCategory ? course.subCategory.name : ''}
                        className="selects"
                        onChange={(e, values) => {
                          setCourseDataSelects(values);
                        }}
                        >
                          <Option hidden name="subCategory" value="">Seleccione Subcategoria</Option>
                          {
                            subCategories.map((subCategory) => (
                              <Option key={subCategory._id} name="subCategory" value={subCategory.name}>{subCategory.name}</Option>
                            ))
                          }
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="row" gutter={[16, 0]}>
              <Divider>Tags</Divider>
              <Col span={24}>
                <Row gutter={16}>
                  <Col span={{ xs: 24, md: 22 }}>
                    <Form.Item
                      rules={[{ type: "string", min: 3 }]}
                      validateStatus={tagError.icon}
                      help={tagError.description}
                      style={{ width: "100%" }}
                    >
                      <Input
                        style={{ width: "100%" }}
                        value={newTag}
                        onChange={(e) => {
                          setNewTag(e.target.value);
                        }}
                        onPressEnter={(e) => {
                          addNewTag(e);
                        }}
                        maxLength={30}
                        placeholder={"Nuevo Tag"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={{ xs: 24, md: 2 }}>
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        addNewTag();
                      }}
                    />
                  </Col>
                </Row>
              </Col>

              <Col span={24} className="tagList">
                {course.tags.length > 0 ? (
                  ""
                ) : (
                  <label> Al Menos un Tag se debe agregar</label>
                )}
                {course.tags.map((tag, index) => {
                  return (
                    <Tag
                      closable
                      key={index}
                      onClose={() => removeTag(tag)}
                      onDoubleClick={() => editTag(index, tag)}
                    >
                      <span className="tagdescription">{tag.name}</span>
                    </Tag>
                  );
                })}
              </Col>
            </Row>
            <Row className="row" gutter={[16, 16]}>
              <Divider></Divider>
              <Col span={24} className="centerButtons">
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={() => {
                    saveCourse();
                  }}
                  loading={isSaving}
                >
                  Guardar
                </Button>
                <Link to='/admin/course/list'>
                  <Button>Cancelar</Button>
                </Link>
              </Col>
            </Row>
          </Form>
        </TabPane>
        <TabPane
          tab={
            <span className="tabtitle">
              <FileTextOutlined />
              Contenido
            </span>
          }
          key="2"
        >
          <CourseContent courseId={idParams} content={course.content} topicRef={topicRef}/>
        </TabPane>
      </Tabs>
    </>
  );
}

export default CourseCreate;
