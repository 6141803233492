import {  Modal } from "antd";

const ModalForVideoProgress = ({ openModal, confirmReturn, startVideo, secondsToShow, title, closable, maskClosable, textButtonCancel, textButtonOK}) => {
  return (
    <Modal 
    title={title}
    closable={closable}
    maskClosable={ maskClosable}
    visible={openModal} 
    onOk={confirmReturn}
    onCancel={startVideo}
    okText={textButtonCancel}
    cancelText={textButtonOK}
  >
    <p>Continuar el video desde el minuto {secondsToShow}</p>
  </Modal>
  )
}

export default  ModalForVideoProgress
