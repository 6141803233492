import "./Invoice.css";
import { useEffect, useState } from "react";
import clientAxios from "../../config/clientAxios";
import { message, Button, Spin, Skeleton } from "antd";
import { useSearchParams } from "react-router-dom";
import Winner from "./Winner/Winner";
import { Alert } from "antd";
import Confetti from "react-confetti";

export default function Invoice() {
  const [playButtonVisible, setPlayButtonVisible] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [slotData, setSlotData] = useState({});
  const [didWin, setDidWin] = useState(null);
  const [didPlay, setDidPlay] = useState(null);
  const [confettiActive, setConfettiActive] = useState(true);
  const [festEnable, setfestEnable] = useState(true);
  const [settingsLoading, setSettingsLoading] = useState(true);
  const billId = searchParams.get("bill_id");
  const festId = searchParams.get("fest_id");


  const getFestResults = async() => {
    try {
    const { data } = await clientAxios.get(`/slot/fest-winners?festId=${festId}`);
    setSettingsLoading(false);
    if(data.festDisable) {
      setfestEnable(false);
      message.warning(
        "El juego aun no esta habilitado, podras particiar el dia de la RollingFest. Nos vemos pronto !!!"
      );
    }

    if (data?.alreadyPlayed) {
      setDisabled(true);
      setDidPlay(data?.alreadyPlayed);
      message.error("El QR ya fue jugado");
    }
    setItems(data.results || data.result);
    setSlotData(data);
    setIsLoading(false);

  } catch (e) {
    console.log(e);
    setSettingsLoading(false);
    setIsLoading(false);
    setDisabled(true);
    message.error(
      "La entrada ya fue jugada o el codigo es invalido"
    );
  }
  }

  const getResults = async () => {
    try {
      setIsLoading(true);
      if (festId) {
        await getFestResults();
        return;
      }
      const { data } = await clientAxios.get(`/slot/winners?billId=${billId}`);
      setItems(data.results || data.result);
      if (data?.alreadyPlayed) {
        setDisabled(true);
        setDidPlay(data?.alreadyPlayed);
        message.error("El comprobante ya fue jugado");
      }
      if (data?.MaxWinners) {
        setDisabled(true);
        message.error(
          "premios agotados ! no te pierdas la oportunidad de jugar el próximo mes abonando tu cuota en los primeros dias del mes"
        );
      }
      setSlotData(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setDisabled(true);
      message.error(
        "Tu comprobante no se encuentra habilitado u ocurrio un error interno por favor intenta mas tarde"
      );
    }
  };

  useEffect(() => {
    getResults();
  }, [billId]);

  const init = (firstInit = true, groups = 1, duration = 1) => {
    const doors = document.querySelectorAll(".door");
    let n = 0;
    items.forEach((item) => {
      if (firstInit) {
        doors[n].dataset.spinned = "0";
      } else if (doors[n].dataset.spinned === "1") {
        return;
      }

      const boxes = doors[n].querySelector(".boxes");
      const boxesClone = boxes.cloneNode(false);

      const pool = ["❓"];
      if (!firstInit) {
        const arr = [];
        for (let n = 0; n < (groups > 0 ? groups : 1); n++) {
          arr.push(...item);
        }
        pool.push(...arr);
        boxesClone.addEventListener(
          "transitionstart",
          function () {
            this.querySelectorAll(".box").forEach((box) => {
              box.style.filter = "blur(1px)";
            });
          },
          { once: true }
        );
        boxesClone.addEventListener(
          "transitionend",
          function () {
            this.querySelectorAll(".box").forEach((box, index) => {
              box.style.filter = "blur(0)";
              if (index > 0) this.removeChild(box);
            });
          },
          { once: true }
        );
      }

      for (let i = pool?.length - 1; i >= 0; i--) {
        const box = document.createElement("div");
        box.classList.add("box");
        box.style.width = doors[n].clientWidth + "px";
        box.style.height = doors[n].clientHeight + "px";
        box.textContent = pool[i];
        boxesClone.appendChild(box);
      }
      boxesClone.style.transitionDuration = `${duration > 0 ? duration : 1}s`;
      boxesClone.style.transform = `translateY(-${
        doors[n].clientHeight * (pool.length - 1)
      }px)`;
      doors[n].replaceChild(boxesClone, boxes);
      n++;
    });
  };

  const spin = async () => {
    const doors = document.querySelectorAll(".door");
    init(false, 1, 1);
    for (const door of doors) {
      const boxes = door.querySelector(".boxes");
      const duration = parseInt(boxes.style.transitionDuration);
      boxes.style.transform = "translateY(0)";
      await new Promise((resolve) => setTimeout(resolve, duration * 100));
      setPlayButtonVisible(false);
    }
    setTimeout(async () => {
      await new Promise((resolve) => setTimeout(resolve, 400));
      if (slotData && slotData.winner) {
        document.getElementById("app").classList.add("fadeOutAnitmation");
        await new Promise((resolve) => setTimeout(resolve, 800));
      }
      setDidPlay(true);
      setDidWin(slotData?.winner);
      await new Promise((resolve) => setTimeout(resolve, 4000));
      setConfettiActive(false);
    }, 1500);
  };

  return (
    <>
      {didWin ? (
        <div className="minHeigth center">
          <div>
            <Confetti recycle={confettiActive} />
            <Winner />
            {festId && ( <p>Por favor guarda una captura de esta pantalla y presenta el código <strong>{festId}</strong> para obtener tu premio</p> )}
          </div>
        </div>
      ) : (
        <div className="minHeigth center">
          <div id="app">
            {billId ? (
              <div className="rouletteInstructions">
                <h1 className="">
                  Bienvenid@ a <strong> 🎮 Rolling Roulette</strong>{" "}
                </h1>
                <p>
                  Con cada cuota pagada durante el mes de Noviembre 2022 podrás
                  participar de 1 oportunidad para ganar merchandaising
                  exclusivo.{" "}
                </p>
                <p>
                  Solo tenes que presionar el botón <strong>JUGAR</strong> si
                  este se encuentra habilitado. Para ganar tenes que obtener los
                  3 casilleros con el logo de Rolling ({"<"} {">"})<p></p>{" "}
                  Suerte y a jugar !!!{" "}
                </p>
              </div>
            ) : (
              <div className="rouletteInstructions">
                <h1 className="">
                  🎈 Rolling Fest 2022 <strong> 🥳🎉 </strong>{" "}
                </h1>
                <p>Tenes una oportunidad de ganar un importante premio !</p>
                <p>
                  Solo tenes que presionar el botón <strong>JUGAR</strong> si
                  este se encuentra habilitado. Para ganar tenes que obtener los
                  3 casilleros con el logo de Rolling ({"<"} {">"})<p></p>{" "}
                  Suerte y a jugar !!!{" "}
                </p>
              </div>
            )}
            {festId && !festEnable && !settingsLoading  && (
              <div id="area">
                <div id="land-area">
                  <div className="land-1"></div>
                  <div className="land-2"></div>
                  <div className="land-3"></div>
                  <div className="land-text-post"></div>
                  <div className="land-text">
                    RollingFest 2022 - Regresa el 2 de Diciembre 23:59 hs
                  </div>
                </div>

                <div id="boat-area">
                  <div className="boat-cabin-3"></div>
                  <div className="boat-cabin-2"></div>
                  <div className="boat-cabin-1"></div>
                  <div className="boat-hull"></div>
                  <div className="boat-hull-cont"></div>
                </div>

                <div id="water-area">
                  <div className="water-area-2"></div>
                  <div className="water-area-1"></div>
                </div>
              </div>
              
            ) }
              { festId && festEnable && settingsLoading && ( 
                      <Skeleton active />
              )}

              { ((billId) || (festId && festEnable && !settingsLoading)) && ( 
              <div className="doors">
                <div className="door">
                  <div className="boxes">
                    <p className="boxIcon">❓</p>
                  </div>
                </div>
                <div className="door">
                  <div className="boxes">
                    <p className="boxIcon">❓</p>
                  </div>
                </div>
                <div className="door">
                  <div className="boxes">
                    <p className="boxIcon">❓</p>
                  </div>
                </div>
              </div>
              
            )}
            {playButtonVisible && (
              <div className="buttons">
                {isLoading ? (
                  <Spin />
                ) : (
                  <Button
                    loading={isLoading}
                    disabled={disabled}
                    className="spinnerButton spinButton"
                    id="spinner"
                    onClick={() => {
                      spin();
                    }}
                  >
                    <span className="txtSpinBtn">Jugar</span>
                  </Button>
                )}
              </div>
            )}
            {didPlay && !didWin && (
              <Alert
                className="AlertMarginTop"
                message={festId ? "Sigue participando" : "Gracias por participar !"}
                description={festId ? "Visita nuetras redes @rollingcodeschool y enterate de todas las novedades" : "Con cada cuota pagada tienes 1 oportunidad" }
                type="error"
                showIcon
              />
            )}
          </div>
          <div className="footer">
            <p>Powered by RollingCode Labs 2022</p>
          </div>
        </div>
      )}
    </>
  );
}
