import clientAxios from '../config/clientAxios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

export default function useCategoriesFetch () {
  
  const [subCategories, setSubCategories] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const getCategory = () => {
    clientAxios.get('/category')
      .then(response => {
        setIsLoading(true);
        setData(response.data);
      })
      .catch(err => {
        message.error('Ha ocurrido un error. intenta mas tarde');
        navigate('/admin');
      })
      .finally(() => {
        setIsLoading(false)
      })
  };

  const getSubCategories = () => {
    clientAxios.get('/subcategory')
    .then(response => {
      setSubCategories(response.data);
    })
    .catch(err => {
      message.error('Ha ocurrido un error. intenta mas tarde');
      navigate('/admin');
    })
  };

  return {
    data,
    isLoading,
    getCategory,
    subCategories,
    getSubCategories
  }

}