import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import clientAxios from '../../../config/clientAxios';
import { Layout, Menu, Skeleton, message, Button,Col } from 'antd';
import {
  BarsOutlined,
  CaretRightOutlined,
  MenuOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  QuestionOutlined,
  IdcardOutlined
} from '@ant-design/icons';
import VideoFrame from '../VideoFrame/VideoFrame';
import ViewQuiz from '../ViewQuiz/ViewQuiz';
import ViewPDF from '../ViewPDF/ViewPDF';
import ViewCertificate from '../ViewCertificate/ViewCertificate';
import { activateNextVideo } from '../../../helpers/sidebarTransitions.js';
import styles from './sidebarVideo.module.css';
import { VIMEO_VIDEO, PDF, QUIZ, CERTIFICATE } from '../../../constants/contentType';


const { Content, Sider } = Layout;
const { SubMenu } = Menu;

function SidebarVideo({user}) {
  const [courseNavigationTree, updateCourseNavigationTree] = useState([]); //coleccion de los videos
  const [courseProgress, updateCourseProgress] = useState([]);
  const [activeVideo, setActiveVideo] = useState({}); //video actual
  const [isLoading, setIsLoading] = useState(false);
  const [cover, setCover] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const [collapsed, setCollapsed] = useState(false); //state del botón para ocultar el sidebar  
  const [contentType, setContentType] = useState(VIMEO_VIDEO);
  const [pdfURL, setPdfURL] = useState('');
  const [quizId, setQuizId] = useState('');
  const [indexes, setIndexes] = useState({});
  const [isFinished, setIsFinished] = useState(false);


  if (!user) {
    navigate(`/explore/course/${id}`);
  }
  //Carga inicial desde API a Estado y LocalStorage(Si es que no hay Data ya en el Local);
  function loadNavagitationTree() {
    clientAxios.get(`/course/${id}`).then((response) => {
      if (!response || response.data.courseData?.content?.length === 0) {
        if (response.status === 404) {
          message.error('Ups...ha ocurrido un problema. intenta mas tarde.');
          navigate('/my-courses');
        } else {
          message.error('Ups...no se encontro el contenido curso.');
          navigate('/my-courses');
        }
      }
      if (response.data.courseData.content) {
        updateCourseNavigationTree([...response.data.courseData.content]);
        if(!response.data.progress || response.data.progress.length === 0) {
          updateCourseProgress([...response.data.courseData.content]);
        }
        else {
          updateCourseProgress([...response.data.progress]);
        }
        setIsLoading(!isLoading);
      }
      if (response.data.cover) {
        setCover(response.data.cover);
      }
    });
  }


  useEffect(() => {}, [courseNavigationTree]);

  // Comportamiento de cambio de video segun barra lateral
  const handleChange = (parentId, topic, type) => {
    setContentType(type)
    if(type === VIMEO_VIDEO){
      topic.selectedVideoMeta = {
        parentActive: parentId,
        videoId: topic.id,
        loaded: true,
        finished: false,
        progres: 0,
      };
      setActiveVideo(topic);
    }else if(type === PDF){
      setPdfURL(topic.embededUrl)
    }else{
      setQuizId(topic.quizId)
    }
  };

  function getAllLocalData() {
    // Devuelve un Array de Objetos JSON
    let result = [];
    result = localStorage.getItem('userData');
    result = JSON.parse(result);
    return result;
  }

  const checkNextContentType = (courseNavigationTreeCopy, {chapterIndex, topicIndex}) => {
    let courseLength = courseNavigationTreeCopy.length;
    let topicLength = courseNavigationTreeCopy[chapterIndex].topics.length;
    if((topicIndex + 1) < topicLength){
      return courseNavigationTreeCopy[chapterIndex].topics[topicIndex + 1].contentType
    } else if((chapterIndex + 1) < courseLength){
      return courseNavigationTreeCopy[chapterIndex + 1].topics[0].contentType
    }
    return null
  }

  async function setFinished({chapterIndex, topicIndex}, type, userId) {
    let courseNavigationTreeCopy = courseNavigationTree;
    courseNavigationTreeCopy[chapterIndex].topics[topicIndex].finished = true;
    await clientAxios.patch(`/users/progress/${userId ? userId : id}`, {
        course: id,
        chapterIndex,
        topicIndex,
        progress: courseNavigationTreeCopy[chapterIndex].topics[topicIndex],
        tree: courseNavigationTreeCopy 
    }).then(({data}) => {
      updateCourseNavigationTree([...courseNavigationTreeCopy]);
      updateCourseProgress([...data]);
      activateNextVideo();
    })
    .catch((err) => {
      message.error(err.message);
      console.debug(err);
    })
    document.getElementById("top").scrollIntoView();
  }

  const handleChangeCertificate = (courseProgress, type) => {
    let response = [];
    courseProgress.map((chapter, indexParent) => {
      chapter.topics.map((topic, indexTopic) => {
        response.push(courseProgress[indexParent].topics[indexTopic]?.finished)
      })
    })
    setIsFinished(!isFinished);
    setContentType(type);
  };

  useEffect(() => {
    loadNavagitationTree();
    document.getElementById("top").scrollIntoView();
  }, []);
  
  if(!user) {
    return <div /> 
  } else {
  return (
    <Layout id="top">
      <Sider
        trigger={null}
        breakpoint="lg"
        collapsedWidth="0"
        theme="light"
        onBreakpoint={() => {}}
        onCollapse={() => {}}
        collapsed={collapsed}
      >
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['4']}
        >
          {/* Carga de los elementos de la barra lateral */}
          {isLoading ?
          <> 
            {courseNavigationTree.map((subMenu, indexParent) => {
              return (
                <SubMenu
                  key={subMenu.id}
                  icon={<BarsOutlined />}
                  title={subMenu.chapter}
                >
                  {subMenu.topics.map((topic, indexSubmenu) => {
                    if(topic.contentType && topic.contentType === PDF){
                      return(
                        <Menu.Item
                          icon={<FilePdfOutlined />}
                          value={topic}
                          key={topic.id}
                          onClick={() => {
                            handleChange(subMenu.id, topic, PDF)
                            setIndexes({
                              chapterIndex: indexParent,
                              topicIndex: indexSubmenu
                            })
                          }}
                        >
                          { 
                            courseProgress[indexParent].topics[indexSubmenu]?.finished && 
                            (
                              <span className={`${styles.spanCheck}`}>
                                <CheckCircleOutlined className={`video-finished ${styles.checkCircle}`}/>
                              </span>
                            )
                          }
                          {topic.name}
                        </Menu.Item>
                      )
                    }else if(topic.contentType && topic.contentType === VIMEO_VIDEO) {
                      return (
                        <Menu.Item
                          icon={<CaretRightOutlined />}
                          value={topic}
                          key={topic.id}
                          onClick={() => {
                              handleChange(subMenu.id, topic, VIMEO_VIDEO)
                              setIndexes({
                                chapterIndex: indexParent,
                                topicIndex: indexSubmenu
                              })
                            }
                          }
                        >
                          { 
                            courseProgress[indexParent]?.topics[indexSubmenu]?.finished && 
                            (
                              <span className={`${styles.spanCheck}`}>
                                <CheckCircleOutlined className={`video-finished ${styles.checkCircle}`}/>
                              </span>
                            )
                          }
                          {topic.name}
                        </Menu.Item>
                      );
                    } else {
                      return (
                        <Menu.Item
                          icon={<QuestionOutlined />}
                          value={topic}
                          key={topic.id}
                          onClick={() => {
                              handleChange(subMenu.id, topic, QUIZ)
                              setIndexes({
                                chapterIndex: indexParent,
                                topicIndex: indexSubmenu
                              })
                            }
                          }
                        >
                          { 
                            courseProgress[indexParent].topics[indexSubmenu]?.finished && 
                            (
                              <span className={`${styles.spanCheck}`}>
                                <CheckCircleOutlined className={`video-finished ${styles.checkCircle}`}/>
                              </span>
                            )
                          }
                          {topic.name}
                        </Menu.Item>
                      );
                    }                    
                  })}
                </SubMenu>
              );
            })
          }
          <Menu.Item
            icon={<IdcardOutlined /> }
            value="Certificado"
            onClick={() => {
              handleChangeCertificate(courseProgress, CERTIFICATE);
            }}
          >
            Certificado
          </Menu.Item>
          </>
           : (
            <>
              <Skeleton
                className={styles.skeleton}
                active={true}
                loading={true}
              />
              <Skeleton
                className={styles.skeleton}
                active={true}
                loading={true}
              />
              <Skeleton
                className={styles.skeleton}
                active={true}
                loading={true}
              />
            </>
          )}
        </Menu>
      </Sider>
      <Content className={styles.content}>
        <Col>
          <Button
            className={styles.collapseButton}
            type='primary'
            onClick={() => setCollapsed(!collapsed)}
          >
            <MenuOutlined />
          </Button>
        </Col>
        <Col>
          {
            contentType === PDF ? 
              <ViewPDF 
                url={pdfURL}
                indexes={indexes}
                setFinished={() => setFinished(indexes, PDF)}
              />
            : contentType === VIMEO_VIDEO ?
              <VideoFrame
                cover={cover}
                data={activeVideo}
                navigationTree={courseNavigationTree}
                updateNavigationTree={() => setFinished(indexes, VIMEO_VIDEO)}
                courseId={id}
              />
            : contentType === QUIZ ?
              <ViewQuiz
                quizId={quizId}
                courseId={id}
                setFinished={(userId) => setFinished(indexes, QUIZ, userId)}
              />
            : <ViewCertificate
                progress={courseProgress}
                courseId={id}
                finished={isFinished}
              />
          }
        </Col>
      </Content>
    </Layout>
  ); }
}

export default SidebarVideo;
