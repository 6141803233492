import { useState } from "react";
import { Layout, Row, Col, message, Button } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import styles from "./ViewPDF.module.css";
import clientAxios from "../../../config/clientAxios";
import { useEffect } from "react";
import ButtonUp from "../../ButtonUp/ButtonUp";

pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js";

export default function ViewPDF({ url, indexes, setFinished }) {
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState({});

  const { Content } = Layout;

  useEffect(() => {
    getDocument(url.split(".com/")[1]);
  }, [url]);

  const getDocument = (id) => {
    clientAxios
      .get(`/course/course-pdf/${id}`)
      .then(function (response) {
        setData(response.data.Body);
      })
      .catch(function (error) {
        message.error(error.message);
        console.debug(error);
      });
  };

  const onLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };
	
  const errorLog = (error) => {
    message.error(error);
  };

  if (data) {
    return (
      <Layout>
        <Content className={styles.content}>
          <Row justify="center">
            <Col>
              <div className={totalPages <= 0 ? styles.minimalView : ""}>
                <Document
                  file={data}
                  onLoadSuccess={(success) => onLoadSuccess(success)}
                  onLoadError={(error) => errorLog(error)}
                >
                  {Array.from(Array(totalPages)).map((page, pageIndex) => (
                    <Page
                      pageNumber={pageIndex + 1}
                      className={styles.marginPages}
                    />
                  ))}
                </Document>
              </div>
            </Col>
          </Row>
          <Row justify="end">
            <Button onClick={() => setFinished(indexes)}>
              Marcar como visto
            </Button>
						<ButtonUp />
          </Row>
        </Content>
      </Layout>
    );
  }
  return (
    <div className={styles.minimalView}>
      <p>Loading...</p>
    </div>
  );
}
