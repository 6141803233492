import { useEffect, useState } from 'react';
import { Col, Row, Table, message, Input, Layout, Button, Modal } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import styles from './users.module.css';
import useGetUsers from '../../../hooks/useGetUsers.js';
import clientAxios from '../../../config/clientAxios';
import Register from '../../Register/Register';

const { Search } = Input;
const { Content } = Layout;

const Users = () => {
  const { usersData: fields, refetch } = useGetUsers();
  const [dataTable, setDataTable] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onSearch = (value) => {
    let result = true;
    if (value) {
      for (let i = 0 ; i < fields.length; i++) {
        if(fields[i].email === value || fields[i].firstName === value || fields[i].lastName === value) {
          setStatus([fields[i]]);
          result = true;
          break;
        } else {
          result = false;
        }
      }
    }else{
      result = false;
    }
    if(!result){
      message.error("Usuario no encontrado, intente de nuevo.");
      setDataTable(fields);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    const loadTable = () => {
      if(fields) {
        setIsLoading(false);
        setDataTable(fields);
      }
      if(status) {
        setIsLoading(false);
        setDataTable(status);
      }
    }
    loadTable();
  }, [fields,status]);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      key: 'nombre',
      width: 280,
      render: (text, record) => ( record.active ? <span>{text}</span> : (
        <span style={{color: 'grey'}}>{text}</span>
      )),
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      key: 'apellido',
      width: 280,
      render: (text, record) => ( record.active ? <span>{text}</span> : (
        <span style={{color: 'grey'}}>{text}</span>
      )),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 280,
      render: (text, record) => ( record.active ? <span>{text}</span> : (
        <span style={{color: 'grey'}}>{text}</span>
      )),
    },
    {
      title: 'Acción',
      key: 'accion',
      render: (record) =>
        <Button type="link" style={{fontSize: '22px'}} onClick={() => {onChangeStatus(record.id, record.active)}}>
        {
          record.active ? (
            <EyeInvisibleOutlined  />
          ) : (
            <EyeOutlined />
          )
        }
        </Button>
    },
  ];

  const onChangeStatus = (id) => {
    clientAxios.patch(`users/manage-users/${id}`)
    .then(response => {
      if (response.status === 200) {
        message.success(`Usuario modificado con éxito.`);
        setStatus();
        refetch();
      } else {
        message.error('Ha ocurrido un error. intenta mas tarde');
      }
    })
    .catch((err) => console.log(err));
  };

  const openModal = () => {
    setIsModalVisible(true);
  }

  return (
    <Content>
      <Row justify="center" className={styles.rowTopUsers}>
        <Col span={8}>
          <Search placeholder="Email o usuario" onSearch={onSearch} size="large" enterButton loading={isLoading}/>
        </Col>
        <Col span={1}>
        <Button
          className={styles.createUserBtn}
          shape='round' 
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => openModal()}
        >
          Crear
      </Button>


      
        <Modal 
          visible={isModalVisible}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          footer={null}
        >
          <Register dropdown={true} admin={true}  setIsModalVisible={setIsModalVisible}/>
        </Modal>


        </Col>
      </Row>
      <Row justify="center">
        <Col span={18}>
          <h2>Usuarios</h2>
          <Table columns={columns} dataSource={dataTable} loading={isLoading}/>
        </Col>
      </Row>
    </Content>
  );
};

export default Users;
