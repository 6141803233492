import { useEffect, useState } from "react";
import useScript from "./useScript";
import { formConfig } from "../components/MercadoPago/formConfig.js";
import clientAxios from "../config/clientAxios";
import {
  CONTINGENCY,
  REVIEW_MANUAL,
  FILLED_CARD_NUMBER,
  FILLED_DATE,
  FILLED_OTHER,
  FILLED_SECURITY_CODE,
  BLACKLIST,
  CALL_FOR_AUTHORIZE,
  CARD_DISABLED,
  CARD_ERROR,
  DUPLICATE_PAYMENT,
  HIGH_RISK,
  INSUFFICIENT_AMOUNT,
  INVALID_INSTALLMENTS,
  MAX_ATTEMPS,
  OTHER_REASON,
  FILLED_IDNUMBER,
  FILLED_NAME,
  SERVER_ERROR,
  EMAIL_INVALID,
  EMAIL_INVALID_MESSAGE,
} from "../constants/mpCheckoutResponseCodes";

export default function useMercadoPago(
  amount,
  course,
  setResultError,
  paymentSuccess,
  setPaymentProcessing
) {
  const [resultPayment, setResultPayment] = useState(undefined);

  const { MercadoPago } = useScript(
    "https://sdk.mercadopago.com/js/v2",
    "MercadoPago"
  );

  const scrollUp = () => {
    const stepCardElement = document.getElementById("step-card");
    stepCardElement.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (MercadoPago) {
      const mp = new MercadoPago(process.env.REACT_APP_PUBLIC_KEY_MP);
      const cardForm = mp.cardForm({
        amount: amount.toFixed(2),
        autoMount: true,
        form: formConfig,
        callbacks: {
          onFormMounted: (error) => {
            if (error)
              return console.warn("Form Mounted handling error: ", error);
          },

          onSubmit: (event) => {
            event.preventDefault();
            setPaymentProcessing(true);
            cardForm.createCardToken().then(({ data }) => {
              const {
                paymentMethodId: payment_method_id,
                issuerId: issuer_id,
                cardholderEmail: email,
                amount,
                token,
                installments,
                identificationNumber,
                identificationType,
              } = cardForm.getCardFormData();

              clientAxios
                .post(`/checkout/${course._id}`, {
                  token,
                  issuer_id,
                  payment_method_id,
                  transaction_amount: amount,
                  installments: Number(installments),
                  description: "Descripción del producto",
                  payer: {
                    email,
                    identification: {
                      type: identificationType,
                      number: identificationNumber,
                    },
                  },
                })

                .then(({ data }) => {
                  setPaymentProcessing(false);
                  if (data.status === "approved") {
                    paymentSuccess(data);
                  } else {
                    if (data.status === "in_process") {
                      if (data.status_detail === "pending_contingency") {
                        setResultError(CONTINGENCY);
                      } else {
                        setResultError(REVIEW_MANUAL);
                      }
                    } else {
                      if (data.status === "rejected") {
                        switch (data.status_detail) {
                          case "cc_rejected_bad_filled_card_number":
                            setResultError(FILLED_CARD_NUMBER);
                            break;
                          case "cc_rejected_bad_filled_date":
                            setResultError(FILLED_DATE);
                            break;
                          case "cc_rejected_bad_filled_other":
                            setResultError(FILLED_OTHER);
                            break;
                          case "cc_rejected_bad_filled_security_code":
                            setResultError(FILLED_SECURITY_CODE);
                            break;
                          case "cc_rejected_blacklist":
                            setResultError(BLACKLIST);
                            break;
                          case "cc_rejected_call_for_authorize":
                            setResultError(CALL_FOR_AUTHORIZE);
                            break;
                          case "cc_rejected_card_disabled":
                            setResultError(CARD_DISABLED);
                            break;
                          case "cc_rejected_card_error":
                            setResultError(CARD_ERROR);
                            break;
                          case "cc_rejected_duplicated_payment":
                            setResultError(DUPLICATE_PAYMENT);
                            break;
                          case "cc_rejected_high_risk":
                            setResultError(HIGH_RISK);
                            break;
                          case "cc_rejected_insufficient_amount":
                            setResultError(INSUFFICIENT_AMOUNT);
                            break;
                          case "cc_rejected_invalid_installments":
                            setResultError(INVALID_INSTALLMENTS);
                            break;
                          case "cc_rejected_max_attempts":
                            setResultError(MAX_ATTEMPS);
                            break;
                          case "cc_rejected_other_reason":
                            setResultError(OTHER_REASON);
                            break;
                          default:
                            break;
                        }
                        scrollUp();
                      }
                    }
                  }

                  setResultPayment(data);
                })

                .catch((err) => {
                  cardForm.createCardToken().then(({ data }) => {
                    setPaymentProcessing(false);
                    scrollUp();
                    if (err && err.responseError === EMAIL_INVALID) {
                      setResultError(EMAIL_INVALID_MESSAGE);
                    } else if (
                      err &&
                      err.responseError === "Invalid card_number_validation"
                    ) {
                      setResultError(FILLED_CARD_NUMBER);
                    } else {
                      setResultError(SERVER_ERROR);
                    }
                  });
                });
            });
          },

          onFetching: (resource) => {
            // Animate progress bar
            const progressBar = document.querySelector(".progress-bar");
            progressBar.removeAttribute("value");
            return () => {
              progressBar.setAttribute("value", "0");
            };
          },

          onError: (error) => {
            setPaymentProcessing(false);
            for (let i = 0; i < error.length; i++) {
              switch (error[i].message) {
                case "parameter cardNumber can not be null/empty":
                  setResultError(FILLED_CARD_NUMBER);
                  break;
                case "Invalid card_token_id":
                  setResultError(FILLED_CARD_NUMBER);
                  break;
                case "Invalid card_number_validation":
                  setResultError(FILLED_CARD_NUMBER);
                  break;
                case "invalid parameter cardNumber":
                  setResultError(FILLED_CARD_NUMBER);
                  break;
                case "invalid parameter bin":
                  setResultError(FILLED_CARD_NUMBER);
                  break;
                case "invalid expiration_year":
                  setResultError(FILLED_DATE);
                  break;
                case "parameter cardExpirationYear can not be null/empty":
                  setResultError(FILLED_DATE);
                  break;
                case "invalid parameter cardExpirationYear":
                  setResultError(FILLED_DATE);
                  break;
                case "paparameter cardExpirationMonth can not be null/empty":
                  setResultError(FILLED_DATE);
                  break;
                case "invalid parameter cardExpirationMonth":
                  setResultError(FILLED_DATE);
                  break;
                case "parameter securityCode can not be null/empty":
                  setResultError(FILLED_SECURITY_CODE);
                  break;
                case "Invalid security_code_length":
                  setResultError(FILLED_SECURITY_CODE);
                  break;
                case "invalid parameter securityCode":
                  setResultError(FILLED_SECURITY_CODE);
                  break;
                case "parameter cardholderName can not be null/empty":
                  setResultError(FILLED_NAME);
                  break;
                case "parameter identificationNumber can not be null/empty":
                  setResultError(FILLED_IDNUMBER);
                  break;
                default:
                  setResultError(FILLED_OTHER);
                  break;
              }
            }
          },
        },
      });
    }
  }, [MercadoPago, amount, course._id, paymentSuccess, setResultError]);

  return resultPayment;
}
