import React, { useState } from 'react';
import { Form, Input, Button, Radio, Space } from 'antd';

export default function Simple({ form, type, changeNames, indexQuestion, addAnswers, setAnswers, showModal  }) {
  const [value, setValue] = useState(0);

  const onChange = (e) => {
      setValue(e.target.value);
      setAnswers(e.target.value, indexQuestion);
  };

  return(
    <>
      {
        type === 'create' ?
          <Form.Item>
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                {
                  form.answers.map((answer, index) => {
                    return(
                      <Radio value={index} key={index}>
                        <Form.Item>
                          <Input value={answer.value} placeholder='...' onChange={(e) => changeNames(e.target.value, index, indexQuestion)}/>
                          <Button onClick={() => showModal(index)}>Eliminar respuesta</Button>
                        </Form.Item>
                      </Radio>
                    )
                  })
                }
                <Button type="primary" onClick={() => addAnswers(indexQuestion)}>Agregar respuesta</Button>
              </Space>
            </Radio.Group>
          </Form.Item>
      :
                      
        <Form.Item
          name={`${indexQuestion}-simple`}
          rules={[{ required: true, message: 'Es obligatorio elegir una respuesta' }]}
        >
          <Radio.Group value={value}>
            <Space direction="vertical">
              {
                form.answers.map((answer, index) => {
                  return(
                    <Radio value={index} key={index}>{answer.value}</Radio>
                  )
                })
              }
            </Space>
          </Radio.Group>
        </Form.Item>
      }
    </>
  )
}