import React from "react";
import AdminStart from "../components/Admin/AdminStart";

function adminpanel({user}) {
 
  return (
    <div style={{minHeight:'100vh'}}>
    <AdminStart user={user} />
    </div>
  );
}

export default adminpanel;
