import axios from 'axios';
import { getLocalStorage, removeAllLocalStorage } from '../helpers/localStorageHelper';
import { removeAllSessionStorage } from '../helpers/sessionHelper';

const defaultOptions = {
  baseURL: process.env.REACT_APP_COURSE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const clientAxios = axios.create(defaultOptions);

clientAxios.interceptors.response.use( (response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    removeAllLocalStorage();
    removeAllSessionStorage();
    window.location.href = '/';
  }
  error.responseError = error.request?.response
  return Promise.reject(error);
})

clientAxios.interceptors.request.use(function (config) {
  const token = getLocalStorage('token');
  config.headers.Authorization = token ? token : '';
  return config;
});


export default clientAxios;