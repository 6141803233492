import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import clientAxios from '../../config/clientAxios';
import { Button, message } from "antd";
import { ShoppingFilled, CheckOutlined } from '@ant-design/icons';

function BuyCourse({finalPrice, discount, name}) {
  const [loadings, setLoadings] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const token = localStorage.getItem('token');

  const purchase = () => {
    navigate(`/cart/checkout/${id}`);
  };
  
  const purchaseFree = async (i) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[i] = true;
      return newLoadings;
    });
    clientAxios.post(`/checkout/${id}`)
    .then(() => {
      message.success(`Se ha agregado el curso ${name} a tu lista de cursos`);
      navigate(`/my-courses`);
    })
    .catch(() => {
      message.error(`Error inesperado, por favor intente más tarde.`);
    })
  };

  return (
    <>
    {finalPrice === 0 || discount === 100
      ? <Button type="primary" icon={<CheckOutlined />} loading={loadings[1]} onClick={() => token ? purchaseFree(1) : navigate(`/sign-up/?redirecToTocheckout=${id}`)}>Inscribirme</Button>
      : <Button type="primary" icon={<ShoppingFilled />} onClick={() => token ? purchase() : navigate(`/sign-up/?redirecToTocheckout=${id}`)}>Comprar</Button>
    }
    </>
  );
};

BuyCourse.propTypes = {
  finalPrice: PropTypes.number,
  discount: PropTypes.number,
  name: PropTypes.string,
};

export default BuyCourse;
