import { React, useState, useEffect } from "react";
import clientAxios from "../config/clientAxios";
import {
  Row,
  Col,
  Carousel,
  Divider,
  Image,
  message,
  Skeleton,
  Card,
  Avatar,
  Typography,
} from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import slide1 from "../assets/img/slide1.jpg";
import slide2 from "../assets/img/slide2.jpg";
import slide3 from "../assets/img/slide3.jpg";
import slide4 from "../assets/img/slide4.jpg";
import noContentImage from "../assets/img/undraw_towing_re_wesa.svg";
const { Meta } = Card;
const { Title } = Typography;
const base64Placeholder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mPkFFxcDwACJgE+S4F7fQAAAABJRU5ErkJggg==";

function Home() {
  const [coursesList, setCoursesList] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [noContentVisible, setNoContentVisible] = useState(false);

  useEffect(() => {
    loadCoursesData();
  }, []);

  function loadCoursesData() {
    setNoContentVisible(false);
    setisLoading(true);
    clientAxios
      .get("/course/public")
      .then((response) => {
        setisLoading(false);
        setCoursesList(response.data);
      })
      .catch((err) => {
        setNoContentVisible(true);
        setisLoading(false);
        if (err.response?.status === 500) {
          message.error("Error...vuelve a intentar luego.");
        } else {
        }
      });
  }

  const contentStyle = {
    color: "#fff",
    lineHeight: "160px",
    top: "10px",
    textAlign: "center",
    background: "lightgray",
  };
  return (
    <div>
      <Carousel autoplay effect="fade">
        <div>
          <h3 style={contentStyle}>
            <Image preview={false} src={slide1} />
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            {" "}
            <Image preview={false} src={slide2} />{" "}
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            {" "}
            <Image preview={false} src={slide3} />{" "}
          </h3>
        </div>
        <div>
          <h3 style={contentStyle}>
            {" "}
            <Image preview={false} src={slide4} />{" "}
          </h3>
        </div>
      </Carousel>

      <Divider plain>
        {" "}
        <Title level={3}>Ultimos Cursos Agregados</Title>
      </Divider>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row justify="center">
          {noContentVisible && (
            <div>
              <img alt="card ilustration" src={noContentImage}></img>
              <p>
                No pudimos mostrar el contenido, por favor refresca la página e
                intenta de nuevo
              </p>
            </div>
          )}
          {coursesList.slice(0, 4).map((courseData, index) => (
            <Col key={index}>
              <Card
                style={{ width: 300, margin: "0.5rem" }}
                cover={
                  <img
                    alt={`cover of ${courseData.name} course`}
                    src={courseData.coverThumbnail || base64Placeholder}
                  />
                }
                actions={[
                  <SettingOutlined key="setting" />,
                  <EditOutlined key="edit" />,
                  <EllipsisOutlined key="ellipsis" />,
                ]}
              >
                <Meta
                  avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                  title={courseData.name}
                  description={courseData.description}
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

export default Home;
