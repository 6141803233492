const VIMEO_VIDEO = 'vimeoVideo'
const PDF = 'pdf-attachment'
const QUIZ = 'quiz'
const CERTIFICATE = 'certificate'

export {
    VIMEO_VIDEO,
    PDF,
    QUIZ,
    CERTIFICATE
}
