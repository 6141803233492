import { message } from 'antd';
import { useEffect, useState } from 'react';
import clientAxios from '../config/clientAxios';

const useGetUsers = () => {
  const [fields, setFields] = useState(null);

  const refetch =  async () => {
    await getAllUsers();
  };

  const getAllUsers = () => {
    clientAxios.get('/users')
    .then(response => setFields(response.data))
    .catch(err => {
      message.error('Ha ocurrido un error. Intenta más tarde');
    })
  }
  
  useEffect(()=>{
    getAllUsers()
  }, [])

  return (
    { usersData: fields, setUserData: setFields, refetch }
  )
}

export default useGetUsers;
