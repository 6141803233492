import React from 'react';
import { Form, Input, Button, Checkbox, Space } from 'antd';

export default function Multiple({ form, type, changeNames, indexQuestion, addAnswers, setAnswers, showModal }) {

  const onChange = (checkedValues) => {
      setAnswers(checkedValues, indexQuestion);
  };

  return(
    <>
      {                            
        type === 'create' ?
          <Form.Item>
            <Checkbox.Group
              onChange={onChange}
            >
              <Space direction="vertical">
              {
                form.answers.map((answer, index) => {
                  return(
                    <Checkbox value={index} key={index}>
                      <Form.Item>
                        <Input value={answer.value} placeholder='...' onChange={(e) => changeNames(e.target.value, index, indexQuestion)}/>
                        <Button onClick={() => showModal(index)}>Eliminar respuesta</Button>
                      </Form.Item>
                    </Checkbox>
                  )
                })
              }
              <Button onClick={() => addAnswers(indexQuestion)}>Agregar respuesta</Button>
              </Space>
            </Checkbox.Group>
          </Form.Item>
        :
          <Form.Item
            name={`${indexQuestion}-multiple`}
            rules={[{ required: true, message: 'Es obligatorio elegir una respuesta' }]}
          >
            <Checkbox.Group>
              <Space direction="vertical">
                {
                  form.answers.map((answer, index) => {
                    return(
                      <Checkbox value={index} key={index}>{answer.value}</Checkbox>
                    )
                  })
                }
              </Space>
            </Checkbox.Group>
          </Form.Item>
      }
    </>
  )
}