import CoursesList from '../../../MyCourses/CoursesList/CoursesList';

function AdminCourseList({user}) {
  return (
    <>
      <CoursesList user={user} />
    </>
  );
};

export default AdminCourseList;
