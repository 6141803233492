const CONTINGENCY = 'No te preocupes, estamos procesando tu pago. No te preocupes, menos de 2 días hábiles te avisaremos por e-mail si se acreditó.';
const REVIEW_MANUAL = 'No te preocupes, menos de 2 días hábiles te avisaremos por e-mail si se acreditó o si necesitamos más información.';
const FILLED_CARD_NUMBER = 'Revisá el número de tarjeta.';
const FILLED_DATE = 'Revisá la fecha de vencimiento.';
const FILLED_OTHER = 'Revisá los datos ingresados.';
const FILLED_SECURITY_CODE = 'Revisá el código de seguridad de la tarjeta.';
const FILLED_IDNUMBER = 'Revisá tu número de documento.';
const FILLED_NAME = 'Revisá el titular de la tarjeta.';
const BLACKLIST = 'No pudimos procesar tu pago.';
const CALL_FOR_AUTHORIZE = 'Debes autorizar previamente el pago.';
const CARD_DISABLED = 'Debes activar su tarjeta o utilizar otro medio de pago.';
const CARD_ERROR = 'No pudimos procesar su pago.';
const DUPLICATE_PAYMENT = 'Ya hiciste un pago por ese valor, si necesitas hacer otro utiliza otro medio de pago.';
const HIGH_RISK = 'Elige otro de los medios de pago, te recomendamos con medios en efectivo.'
const INSUFFICIENT_AMOUNT = 'Saldo insuficiente.';
const INVALID_INSTALLMENTS = 'Su tarjeta no procesa pagos en cuotas.';
const MAX_ATTEMPS = 'Superó el límite de intentos permitidos.';
const OTHER_REASON = 'Su tarjeta no procesó el pago.';
const SERVER_ERROR = 'Hubo un inconveniente con el pago, por verifique los datos de su tarjeta en intente de nuevo.';
const EMAIL_INVALID = 'The next fields are failing on validation: ".payer.email": should match format "email".'
const EMAIL_INVALID_MESSAGE = 'El email ingresado no es valido'


export {
  CONTINGENCY,
  REVIEW_MANUAL,
  FILLED_CARD_NUMBER,
  FILLED_DATE,
  FILLED_OTHER,
  FILLED_SECURITY_CODE,
  BLACKLIST,
  CALL_FOR_AUTHORIZE,
  CARD_DISABLED,
  CARD_ERROR,
  DUPLICATE_PAYMENT,
  HIGH_RISK,
  INSUFFICIENT_AMOUNT,
  INVALID_INSTALLMENTS,
  MAX_ATTEMPS,
  OTHER_REASON,
  FILLED_IDNUMBER,
  FILLED_NAME,
  SERVER_ERROR,
  EMAIL_INVALID,
  EMAIL_INVALID_MESSAGE
}
