import React, { useEffect, useState } from 'react';
import { Layout, Skeleton, Image, Alert } from 'antd';
import clientAxios from '../../../config/clientAxios';
import Confetti from "react-confetti";


const { Content } = Layout;

function ViewCertificate({progress, courseId}) {
  
  const [active, setActive] = useState(false);
  const [url, setUrl] = useState();

  const getCertificate = async () => {
    clientAxios.post(`/certificate/`, {courseId})
    .then((response) => {
      setActive(true);
      setUrl(response.data.certificateUrl)
    })
    .catch((error) => console.log(error));
  }

  useEffect(() => {
    getCertificate();
  }, []);

  return (
    <Content style={{display: 'flex', justifyContent: 'center'}}>
    {
      active
      ?
        <>
          <Confetti recycle={!active} />
          <Image src={url}/>
        </>
      :
      <Content style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
        <Alert message="Aun no tienes certificado! Completa el contenido y quiz del curso para obtener tu primer certificado" type="info" />
        <Skeleton.Image style={{width: '70vw', height: '70vh', margin: 'auto'}} active />
      </Content>
    }
    </Content>
  )
}

export default ViewCertificate;
