import { Input, Form, Button } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const InputHeader = ({ chapters, index, setChapterFormData, addChapter }) => {
  
  const onChange = (e) => {
    const actualInputValue = e.target.value;
    const newChapters = chapters;
    newChapters[index].chapter = actualInputValue;
    setChapterFormData([...newChapters]);
  };

  const deleteTopic = (e) => {
    const newChapters = chapters;
    newChapters.splice(index, 1);
    setChapterFormData([...newChapters]);
  };
  return (
    <>
      <Form layout="inline">
        <Form.Item>
          <Input
            name="chapterName"
            placeholder="...."
            addonBefore={chapters[index].chapter.length === 0 ? "Nombre del Capitulo... " : chapters[index].chapter}
            value={chapters[index].chapter}
            onChange={onChange}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item>
          <Button shape="circle" icon={<PlusOutlined />} onClick={addChapter} />
          {index > 0 && (
            <Button
              style={{ float: "right", color: "red" }}
              shape="circle"
              onClick={deleteTopic}
              icon={<MinusCircleOutlined />}
            />
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default InputHeader;
