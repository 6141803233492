import { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import { message } from "antd";
import Navbar from "./components/Navbar/Navbar";
import FooterPage from "./components/Footer/FooterPage";
import CourseCreate from "./components/Admin/Course/CourseCreate";
import AdminCourseList from "./components/Admin/Course/CourseList/AdminCourseList";
import CoursePage from "./pages/course";
import MyCoursesPage from "./pages/myCourses";
import AdminPanel from "./pages/adminpanel";
import Home from "./pages/home";
import HomeLegacyPayments from "./pages/homeLegacyPayments";
import Certified from "./pages/certified";
import UniverseLoading from "./pages/universeLoading";
import PaymentPage from "./pages/PaymentPage";
import DetaillCourse from "./pages/detaillCourse";
import Login from "./components/Login/Login";
import ROLE from "./config/roles";
import { getLocalStorage } from "./helpers/localStorageHelper";
import CheckoutSuccess from "./components/Payment/checkoutSuccess";
import Monetization from "./components/Admin/Monetization/Monetization";
import Categories from "./components/Admin/Categories/Categories";
import RegisterPage from './pages/registerPage';
import ExplorePage from './pages/explorePage';
import CreateQuiz from "./components/Admin/Quiz/CreateQuiz";
import UserProfile from './components/UserProfile/UserProfile';
import EditProfile from './components/UserProfile/EditProfile';
import Legacy from "./components/Legacy/Legacy";
import Invoice from "./components/Live/Invoice";
import RestorePassword from './components/RestorePassword/RestorePassword';
import ListQuizes from "./components/Admin/Quiz/listQuizes";
import VerifyEmail from "./pages/VerifyEmail";
import Users from './components/Admin/Users/Users';
import LiveVideo from "./pages/LiveVideo";
import BuyCourse from './components/BuyCourse/BuyCourse';

const PrivateRoute = ({ user, role, children }) => {
  const authed = user && user.role === role;
  if (!authed) {
    message.error('Pagina no existe.');
    return <Navigate to='/login' />;
  }
  return children;
};

function App() {
  const [user, setUser] = useState(getLocalStorage('user'));
  const addCourseToUser = (newCourseId) => {
    const userToUpdate = user;
    userToUpdate.courses.push(newCourseId);
    setUser(userToUpdate);
  }
  return (
    <BrowserRouter>
      <Navbar user={user} setUser={setUser} />
      <div id='maincontent'>
        <Routes>
          <Route
            path='*'
            element={
              <main style={{ padding: '1rem', minHeight: '100vh' }}>
                <p>There's nothing here! change path /home</p>
              </main>
            }
          />
           {process.env.REACT_APP_PLATFORM_CERTIFIED_PAGE && ( 
            <Route path='' element={<Navigate to='/' />} />
           )}
           {!process.env.REACT_APP_PLATFORM_CERTIFIED_PAGE && ( 
          <Route path='' element={<Navigate to='/home' />} /> )}
          {process.env.REACT_APP_PLATFORM_LEGACYPAYMENTS_MODE && ( 
            <Route path='/home' element={<HomeLegacyPayments />} />
          )}
          {process.env.REACT_APP_PLATFORM_CERTIFIED_PAGE && ( 
            <Route path='/:id' element={<Certified />} />
          )}
          {process.env.REACT_APP_PLATFORM_LIVE_COURSES && ( 
            <Route path='/home' element={<UniverseLoading />} />
          )}
          {(!process.env.REACT_APP_PLATFORM_LIVE_COURSES && 
          !process.env.REACT_APP_PLATFORM_LEGACYPAYMENTS_MODE &&
          !process.env.REACT_APP_PLATFORM_CERTIFIED_PAGE
          )  && ( 
            <Route path='/home' element={<Home />} />
          )
          }
          <Route 
            path='/legacy/payment/:status' 
            element={<Legacy />}
          />
          <Route 
            path='/live/invoice' 
            element={<Invoice />}
          />
          <Route
            path='/login'
            element={<Login user={user} setUser={setUser} />}
          />
          <Route path='/sign-up' element={<RegisterPage />} />
          <Route path='/sign-up/success' element={<VerifyEmail />} />
          <Route path='/restore-password' element={<RestorePassword />} />
          <Route path='/profile' element={<UserProfile user={user} />} />
          <Route path='/profile/edit' element={<EditProfile user={user} />} />
          <Route path='/course/:id' element={<CoursePage user={user} />} />
          <Route path='/my-courses' element={<MyCoursesPage user={user} />} />
          <Route path='/explore' element={<ExplorePage user={user} />} />
          <Route path='/live/videos/:comision_ID' element={<LiveVideo user={user} />} />
          <Route path='/certified/:id' element={<Certified user={user} />} />
          <Route path='/live-courses/checkout/:classroomName' element={<BuyCourse/>} />
          <Route
            path='/cart/checkout/:id'
            element={
              <PaymentPage user={user} addCourseToUser={addCourseToUser} />
            }
          />
          <Route
            path='/explore/course/:id'
            element={<DetaillCourse user={user} />}
          />
          <Route
            path='/checkout/success/:id'
            element={<CheckoutSuccess user={user} />}
          />
          <Route
            path='/admin'
            element={
              <PrivateRoute user={user} role={ROLE.ADMIN}>
                <AdminPanel />
              </PrivateRoute>
            }
          >
            <Route path="/admin/course/create" element={<CourseCreate />} />
            <Route path="/admin/course/list" element={<AdminCourseList user={user} />}/>
            <Route path="/admin/course/monetization/:id" element={<Monetization user={user} />}/>
            <Route path="/admin/course/edit/:id" element={<CourseCreate />} />
            <Route path="/admin/categories" element={<Categories />} />
            <Route path="/admin/quiz" element={<CreateQuiz />} />
            <Route path="/admin/listQuizes" element={<ListQuizes />} />
            <Route path="/admin/quiz/update/:id" element={<CreateQuiz isUpdating={true} />} />
            <Route path="/admin/users" element={<Users />} />
          </Route>
        </Routes>
      <FooterPage />
      </div>
    </BrowserRouter>
  );
}

export default App;
