import React from 'react'
import SidebarVideo from '../components/Course/SidebarVideo/SidebarVideo'

function course({user}) {
  return (
    <>
      <SidebarVideo user={user}/>
    </>
  )
}

export default course
