import React, { useState, useEffect } from 'react';
import styles from './modalsCategory.module.css';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Input, message, Divider, List, Popconfirm, Form } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import clientAxios from '../../../config/clientAxios';

function ListSubCategory(props) {
  const { setReloadCollaps, reloadCollaps } = props.flag;
  const [isModalVisibleSub, setIsModalVisibleSub] = useState(false);
  const [reloadSubCategory, setReloadSubCategory] = useState(false);
  const [subCategories, setSubCategories] = useState([false]);
  const [inputEdit, setInputEdit] = useState("");
  const subCategory = { name: '' };
  const navigate = useNavigate();

  const getSubCategories = () => {
    clientAxios.get('/subcategory')
    .then(response => {
      setSubCategories(response.data);
    })
    .catch(err => {
      message.error('Ha ocurrido un error. intenta mas tarde');
      navigate('/admin');
    })
  };

  useEffect(() => {
    getSubCategories();
  }, [reloadSubCategory])
  
  
  const showModalSub = () => {
    setIsModalVisibleSub(true);
  };

  const createSubCategory = () => { 
    if (subCategory.name.length >= 1 && subCategory.name.length <= 100) {
      clientAxios.post('subcategory', { name: subCategory.name })
      .then(response => {
        if(response.status === 201){
          subCategory.name = "";
          message.success('Sub categoria creada con exito')
          setReloadSubCategory(!reloadSubCategory)
          setReloadCollaps(!reloadCollaps);
        }else {
          message.error('Ha ocurrido un error. intenta mas tarde')
        };
      })
      .catch((err) => {
        if (err.response.status === 409) {
          message.error('La sub categoria ya existe')
        }
      })
    } else {
      message.warn('Ingresa un nombre para la sub categoria');
    }
  };

  const deleteSubCategory = (id) => {
    clientAxios.delete(`subcategory/${id}`)
    .then(response => {
      setReloadSubCategory(!reloadSubCategory);
      if (response.status === 200) {
        message.success('Eliminado con exito');
        setReloadCollaps(!reloadCollaps);
      } else {
        message.error('Ha ocurrido un error. intenta mas tarde');
      }
    })
  };

  const editSubCategory = (id) => {
    if (inputEdit.length >= 1 && inputEdit.length <= 100) {
      clientAxios.patch(`subcategory/${id}`, { name: inputEdit })
      .then(response => {
        setReloadSubCategory(!reloadSubCategory);
        if (response.status === 200) {
          message.success('Editado con exito');
          setReloadCollaps(!reloadCollaps);
        } else {
          message.error('Ha ocurrido un error. intenta mas tarde');
        }
      })
    } else {
      message.warn("Completa el campo por favor.")
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModalSub}>
        Administrar sub categorias
      </Button>
      <Modal title="Agregar Sub Categoria - para cerrar presiona ESC" footer={null} onCancel={() =>  setIsModalVisibleSub(false)} visible={isModalVisibleSub}>
        <Input onPressEnter={createSubCategory} maxLength="25" placeholder="Sub categoria (Para guardar presiona enter)" onChange={(e) => subCategory.name = e.target.value} prefix={<PlusOutlined />} />
        <Divider />
        <List
        header={<div>Lista de sub categorias. (Para guardar presiona enter)</div>}
        bordered
        dataSource={subCategories}
        renderItem={item => (
          <List.Item
            className={styles.list}
            actions={[
              <>
                <Popconfirm
                  title="¿Estas seguro que quieres borrar esta sub categoria?"
                  onConfirm={() => deleteSubCategory(item._id)}
                  >
                  <Button className={styles.buttonDelete} danger><DeleteOutlined /></Button>
                </Popconfirm>
              </>
            ]}
          >
            <List.Item.Meta
              title={
                <Form.Item className={styles.form}>
                  <Input placeholder="Sub categoria" maxLength="25" defaultValue={item.name} onPressEnter={() => editSubCategory(item._id)} onChange={(e) => setInputEdit(e.target.value)} prefix={ <EditOutlined />} />
                </Form.Item>
              }
            />
          </List.Item>
        )}
        />
      </Modal>
    </>
  )
}

export default ListSubCategory;
