import { Result, Button } from 'antd';
import { useParams, Link } from 'react-router-dom';
function CheckoutSucess({user}) {
  const { id } = useParams();
  const subTitle = `Order number: ${id} puedes verificar tu pago cuando este acreditado`;
  return ( <Result
    style={{minHeight:'70vh'}}
    status="success"
    title="Compra exitosa!"
    subTitle={subTitle}
    extra={[
      <Link to="/my-courses">
      <Button> Regresar a Mis Cursos</Button>
      </Link>,
      <Button key="buy">Explorar Mas cursos</Button>,
    ]}
  />)
}
export default CheckoutSucess;