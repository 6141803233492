import React from "react";
import { Card, Row, Col } from "antd";
import styles from './cardPreview.module.css';
import { Typography } from 'antd';
const { Meta } = Card;
const { Title } = Typography;

function CourseCard(props) {
  const { name, description, coverThumbnail } = props.course;
  const freeTxt = "¡GRATIS!"
  return (
    <Card
      hoverable
      cover={
        <img
          alt="course"
          src={coverThumbnail}
        />
      }
    >
      <Row>
        <Col className={styles.containerPrice}>
          {
            props.discount === 100 || props.finalPrice === 0 || props.price === 0
            ?
              <Title level={3}>{freeTxt}</Title>
            :
              <>
                <Title level={4} className={styles.finalPrice}>${props.finalPrice}</Title>
                <Title level={4} delete={true}>${props.price}</Title>
                <Title level={5} className={styles.discount}>{props.discount}% off</Title>
              </>
          }
        </Col>
      </Row>
      <Meta title={name} description={description} />
    </Card>
  );
}

export default CourseCard;
