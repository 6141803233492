import React, { useState } from "react";
import Topic from "./Topic";
import { Collapse, Col, Row, Button, message, Modal } from "antd";
import InputHeader from "./InputHeader";
import { SaveOutlined,  DownOutlined, UpOutlined } from "@ant-design/icons";
import clientAxios from "../../../../config/clientAxios";
import { VIMEO_VIDEO } from "../../../../constants/contentType";
import styles from "./courseContent.module.css";

const { Panel } = Collapse;
function CourseContent({ courseId, content, topicRef }) {
  const [chapterFormData, setChapterFormData] = useState(content);
  const [loadings, setLoadings] = useState(false);

  function addChapter() {
    let data = chapterFormData;
    data.push({ chapter: "", topics: [{ name: "", contentType: VIMEO_VIDEO, embededUrl: "" }] });
    setChapterFormData([...data]);
  }

  function warning() {
    Modal.warning({
      title: "Campos obligatorios",
      content: "Debe rellenar todos los campos obligatorios",
    });
  }

  const downLineChapter = (index, chapterFormData) => {
    let newArray = [];
    for(let i = 0; i < chapterFormData.length; i++) {
      if(i === index){
        newArray[i+1] = chapterFormData[i];
        newArray[i] = chapterFormData[i+1];
        i++;
      } else {
        newArray[i] = chapterFormData[i];
      }
    }
    setChapterFormData(newArray);
  }

  const upLineChapter = (index, chapterFormData) => {
    let newArray = [];
    for(let i = 0; i < chapterFormData.length; i++) {
      if(i !== index){
        newArray[i] = chapterFormData[i];
      } else {
        newArray[i] = chapterFormData[i-1];
        newArray[i-1] = chapterFormData[i];
      }
    }
    setChapterFormData(newArray);
  }

  const saveContent = () => {
    setLoadings(true);
    clientAxios
    .patch(`/course/${courseId}`, {
      ...{
        content: chapterFormData
      },
    })
    .then(function (response) {
      setLoadings(false);
      message.success("Contenido guardado!");
    })
    .catch(function (error) {
      console.debug(error);
    });
  }


  return (
    <>
      {chapterFormData.length
        ? chapterFormData.map((_, index) => {
            return (
              <Row justify="center" key={`row-${index}`} className={styles.topicLine}>
                <Col justify="center" align="middle" className={styles.containerButton}>
                  {index > 0 && index < chapterFormData.length - 1
                    ?<>
                      <Button className={styles.btnOrder} type="link">
                        <DownOutlined onClick={() => downLineChapter(index, chapterFormData)}/>
                      </Button>
                      <Button className={styles.btnOrder} type="link">
                        <UpOutlined  onClick={() => upLineChapter(index, chapterFormData)}/>
                      </Button>
                    </>
                    : index === chapterFormData.length - 1 && index !== 0 
                      ? <Button className={styles.btnOrder} type="link">
                          <UpOutlined onClick={() => upLineChapter(index, chapterFormData)}/>
                        </Button>
                      : index === 0 && index !== chapterFormData.length - 1 
                        ? <Button className={styles.btnOrder} type="link">
                          <DownOutlined onClick={() => downLineChapter(index, chapterFormData)}/>
                        </Button>
                        : <></>
                    }
                </Col>
                <Col span={20} key={`col-${index}`}>
                  <Collapse key={`collapse-${index}`}>
                    <Panel key={`panel-${index}` }
                      header={
                        <InputHeader
                          key={`inputHeader-${index}`}
                          chapters={chapterFormData}
                          index={index}
                          setChapterFormData={setChapterFormData}
                          addChapter={addChapter}
                        />                        
                      }
                    >
                      <div key={`topics-${index}`}>
                        <Topic
                          key={`topic-${index}`}
                          chapters={chapterFormData}
                          indexChapter={index}
                          setChapterFormData={setChapterFormData}
                          ref={topicRef}
                        />
                      </div>
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            );
          })
        : ""}
      <Row justify="center" key="btn-Row">
        <Col span={12}>
          <Button style={{ margin: "10px auto 15px" }}
            type="primary"
            onClick={saveContent}
            icon={<SaveOutlined  />}
            loading={loadings}
            disabled={!courseId}
          >
            Guardar
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default CourseContent;
