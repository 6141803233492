import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from 'antd';
import { useState } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';
import styles from './buyCourse.module.css';
const { Option } = Select;
const initialCountry = 'Argentina';
const dateFormat = 'YYYY/MM/DD';
const BuyCourse = () => {
  const [form] = Form.useForm();
  const [country, setCountry] = useState(initialCountry);
  const [region, setRegion] = useState('');
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const onFinish = (values) => {
    values['birthDate'] = moment(values.birthDate).format('YYYY-MM-DD');
  };
  const onValuesChange = (changedValues, allValues) => {
    const { name, surname, email, phone, address, dni, birthDate, tyc } =
      allValues;
    if (
      name != undefined &&
      surname != undefined &&
      email != undefined &&
      phone != undefined &&
      dni != undefined &&
      address != undefined &&
      birthDate != undefined &&
      tyc
    ) {
      setIsBtnDisabled(false);
    }
  };
  return (
    <>
      <Row>
        <Col span={12} offset={6}>
          <h2>FullStack</h2>
          <div>
            <p>
              Curso ideal para principiantes con o sin experiencia. En 6 meses
              te preparamos para poder trabajar con el set de tecnologías más
              demandado por la industria, te formamos para que incrementes al
              máximo tus posibilidades laborales.
            </p>
            <p>
              <b>
                No es necesario que estés graduado para comenzar a aprender.
              </b>
            </p>
          </div>
          <Form
            form={form}
            layout='vertical'
            name='buyCourse'
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              name='name'
              label='Nombre'
              rules={[
                {
                  type: 'text',
                },
                {
                  required: true,
                  message: 'Por favor ingresa tu nombre.',
                },
              ]}
            >
              <Input maxLength={30} />
            </Form.Item>

            <Form.Item
              name='surname'
              label='Apellido'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu apellido.',
                },
              ]}
            >
              <Input maxLength={30} />
            </Form.Item>

            <Form.Item
              name='email'
              label='Email'
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Por favor ingresa un email.',
                  pattern: '[a-z0-9]+@[a-z]+.[a-z]{2,3}',
                },
              ]}
            >
              <Input maxLength={40} />
            </Form.Item>

            <Form.Item
              name='phone'
              label='Teléfono (sin el 0 y sin el 15)'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu número.!',
                },
              ]}
            >
              <PhoneInput defaultCountry='AR' maxLength={15} />
            </Form.Item>

            <Form.Item
              name='dni'
              label='DNI'
              rules={[
                {
                  type: 'number',
                  required: true,
                  message: 'Por favor ingresa tu dni.',
                },
              ]}
            >
              <InputNumber
                style={{
                  width: '100%',
                }}
                maxLength={10}
                min={0}
              />
            </Form.Item>

            <Form.Item
              name='address'
              label='Dirección'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu dirección.',
                },
              ]}
            >
              <Input maxLength={50} />
            </Form.Item>

            <Form.Item name='country' label='País'>
              <CountryDropdown
                name='country'
                defaultOptionLabel={'Seleccionar país'}
                showDefaultOption={false}
                priorityOptions={['AR']}
                value={country}
                onChange={(country) => setCountry(country)}
              />
            </Form.Item>

            <Form.Item name='region' label='Provincia'>
              <RegionDropdown
                defaultOptionLabel={'Seleccionar país'}
                country={country}
                className={styles.antInput}
                value={region}
                onChange={(val) => setRegion(val)}
              />
            </Form.Item>

            <Form.Item name='birthDate' label='Fecha de nacimiento'>
              <DatePicker
                rules={[
                  {
                    required: true,
                  },
                ]}
                format={dateFormat}
                placeholder='Fecha'
              />
            </Form.Item>

            <Form.Item
              name='tyc'
              valuePropName='checked'
              rules={[
                {
                  required: true,
                  message:
                    'Debes aceptar los términos y condiciones para poder continuar.',
                },
              ]}
            >
              <Checkbox id='checkboxForm'>
                Aceptar los terminos y condiciones
              </Checkbox>
            </Form.Item>
            <Form.Item shouldUpdate>
              <Button type='primary' htmlType='submit' disabled={isBtnDisabled}>
                Inscribirme
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default BuyCourse;
