export const setRandomAnimal = () => {
  const animalsList = [
    "alligator",
    "anteater",
    "armadillo",
    "auroch",
    "axolotl",
    "badger",
    "bat",
    "beaver",
    "buffalo",
    "camel",
    "capybara",
    "chameleon",
    "cheetah",
    "chinchilla",
    "chipmunk",
    "chupacabra",
    "cormorant",
    "coyote",
    "crow",
    "dingo",
    "dinosaur",
    "dolphin",
    "duck",
    "elephant",
    "ferret",
    "fox",
    "frog",
    "giraffe",
    "gopher",
    "grizzly",
    "hedgehog",
    "hippo",
    "hyena",
    "ibex",
    "ifrit",
    "iguana",
    "jackal",
    "kangaroo",
    "koala",
    "kraken",
    "lemur",
    "leopard",
    "liger",
    "llama",
    "manatee",
    "mink",
    "monkey",
    "moose",
    "narwhal",
    "orangutan",
    "otter",
    "panda",
    "penguin",
    "platypus",
    "pumpkin",
    "python",
    "quagga",
    "rabbit",
    "raccoon",
    "rhino",
    "sheep",
    "shrew",
    "skunk",
    "squirrel",
    "tiger",
    "turtle",
    "walrus",
    "wolf",
    "wolverine",
    "wombat",
  ];
  const randomIndex = Math.floor(Math.random() * animalsList.length);
  localStorage.removeItem("profilePic");
  localStorage.setItem("animalAvatarProfile", animalsList[randomIndex]);
};

export const setProfilePic = (urlPhoto) => {
  localStorage.removeItem("animalAvatarProfile");
  localStorage.setItem("profilePic", urlPhoto);
};

export const isProfilePicSet = () => {
  if(localStorage.getItem("profilePic")) {
    return true;
  }
  return false;
};


export const getProfilePic = () => {
  if(localStorage.getItem("animalAvatarProfile")) {
    return localStorage.getItem("animalAvatarProfile");
  }
  return localStorage.getItem("profilePic");
};
