import { Alert, Col, Row } from 'antd'
import { CloseCircleTwoTone } from '@ant-design/icons'
import './Legacy.css'

export default function Failure() {
    return(
        <div className="minHeigth center">
            <div>
                <Row justify='center'>
                    <Col>
                        <CloseCircleTwoTone twoToneColor='red' className='icons'/>
                    </Col>
                </Row>
                <Row justify='center'>
                    <Alert message="Su pago fue fallido" type='error'/>
                </Row>
            </div>
        </div>
    )
}