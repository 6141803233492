import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import styles from './verifyEmailComp.module.css';

const VerifyEmailComp = () => {
  const { container, textPostion, btn } = styles;
  const navigate = useNavigate();
  let values = window.location.search;
  let urlParams = new URLSearchParams(values);
  let emailParams = urlParams.get('email');

  return (
    <div className={container}>
      <p className={textPostion}>Tu usuario con el email <b>{emailParams}</b> se ha registrado con exito ! Para poder ingresar por favor revisa tu casilla de correo para activar tu cuenta con el link de activacion, luego podes ingresar haciendo click en continuar”</p>
      <Button
        onClick={()=>navigate('/login')}
        type='primary'
        className={btn}
      >
        Continuar al login
      </Button>
    </div>
  )
}

export default VerifyEmailComp