import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from 'antd';
import clientAxios from '../../config/clientAxios';


const TextEditor = ({ setIsAddingReview, videoId, setUpdateVideo }) => {
  const [value, setValue] = useState('');
  const [isUpdatingComment, setIsUpdatingComment] = useState(false);
  
  
  const updateVideoReview = async (value) => {
    try  {
    setIsUpdatingComment(true);  
    const updateReview = await clientAxios.patch(`/liveVideo/live/videos`, {
      videoId: videoId,
      summary: value
    })
    setIsUpdatingComment(false);  
    setIsAddingReview(false);
    setUpdateVideo(value);

    // msge ok
    // spinner disable
    // render comment
  }
  catch(e) {
    setIsUpdatingComment(false);  

    //msge error
  }
  };

  return (
    <>
      <ReactQuill theme="snow" value={value} onChange={setValue}/>
      <Button type="primary" loading={isUpdatingComment} onClick={() => {
        updateVideoReview(value);
       
     
      }}>Guardar</Button>
      <Button  type="secondary" onClick={() => setIsAddingReview(false)}>Cancelar</Button>
    </>
  )
}

export default TextEditor
