import { useEffect, useState } from "react";
import clientAxios from "../config/clientAxios";

const useLiveClassroom = () => {
  const [classrooms, setClassrooms] = useState(null);
  const [classroomsActive, setClassroomsActive] = useState(null);
  const [liveClassRoomLoading, setLiveClassRoomLoading] = useState(true);

  const refetch = async () => {
    getUserLiveClassroom();
  };

  const getUserLiveClassroom = () => {
    clientAxios
      .get("/liveVideo")
      .then((response) => {
        setClassrooms(response.data.classrooms);
        setClassroomsActive(response.data.classroomActive);
        setLiveClassRoomLoading(false);
      })
      .catch((err) => {
        setLiveClassRoomLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getUserLiveClassroom();
  }, []);

  return { classrooms, setClassrooms, classroomsActive, setClassroomsActive, refetch, liveClassRoomLoading };
};

export default useLiveClassroom;
