import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Upload,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Option } from 'antd/lib/mentions';
const { Dragger } = Upload;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const normFile = (e) => {
  console.log('Upload event:', e);

  if (Array.isArray(e)) {
    return e;
  }

  return e?.fileList;
};
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
const EditProfile = ({ user }) => {
  const [profilePic, setProfilePic] = useState('');
  const [loading, setLoading] = useState(false);

  const prefixSelector = (
    <Form.Item name='prefix' noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value='54'>+54</Option>
      </Select>
    </Form.Item>
  );
  const onFinish = (values) => {
    console.log(values);
  };
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Clic haz para subir
      </div>
    </div>
  );

  function saveImageToState(state, imagedata) {
    let base64Data = '';
    let reader = new FileReader();
    reader.onload = () => {
      base64Data = reader.result;
      switch (state) {
        case 'profilePic':
          setProfilePic(base64Data);
          break;
        default:
          console.log('none selected');
      }
    };
    reader.readAsDataURL(imagedata);
  }

  return (
    <div style={{ minHeight: '90vh', padding: '15px' }}>
      <Row justify='center'>
        <Col>
          <Space direction='vertical' size='middle'>
            <h2 style={{ textAlign: 'center' }}>Edición de perfil</h2>
            <Form
              {...layout}
              name='nest-messages'
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={['user', 'name']}
                label='Name'
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['user', 'email']}
                label='Email'
                rules={[
                  {
                    type: 'email',
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='phoneNumber'
                label='Teléfono'
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{
                    width: '100%',
                  }}
                />
              </Form.Item>
              <Form.Item label='Avatar'>
                <Form.Item
                  name='avatar'
                  valuePropName='fileList'
                  getValueFromEvent={normFile}
                  noStyle
                >
                  <Upload.Dragger
                    name='avatarFile'
                    maxCount={1}
                    accept='.jpg,.png'
                  >
                    <p className='ant-upload-drag-icon'>
                      <PlusOutlined />
                    </p>
                    <p className='ant-upload-text'>
                      Clickea o arrastra la imagen a esta área
                    </p>
                    <p className='ant-upload-hint'>
                      formatos soportados: .jpg, .png
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button type='primary' htmlType='submit' >
                  Guardar
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default EditProfile;
