import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row, Form, Input, Button, Select, message, Tooltip, Collapse, Skeleton } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import clientAxios from '../../../config/clientAxios';
import DeleteModal from './modals/DeleteModal';
import useModal from '../../../hooks/useModal';
import Multiple from './typeFormats/Multiple';
import Simple from './typeFormats/Simple';
import { SIMPLE, MULTIPLE } from '../../../constants/quizType';

const { Panel } = Collapse;
const { Option } = Select;


export default function CreateQuiz({isUpdating}) {
  const {id} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { isModalVisible, showModal, handleOk, handleCancel } = useModal();
  const [form, setForm] = useState();
  const [statusPercentage, setStatusPercentage] = useState('');
  const [statusChance, setStatusChance] = useState('');

  const getQuizToUpdate = () => {
    clientAxios.get(`/quiz/${id}`)
    .then(response => {
        setForm(response.data);
      })
    .catch(err => console.log(err));
  }

  useEffect(() => {
    if(isUpdating) {
      getQuizToUpdate();
    } else {
      setForm({
        title: '',
        percentage: 90,
        chance: 3,
        questions: [{
          title: 'Cuales tag modifican los textos',
          type: SIMPLE,
          answers: [{
            value: 'Option A'
          },{
            value: 'Option B'
          },{
            value: 'Option C'  
          }],
          correctAnswers: [0]
        }]
      })
    }
  }, [])

  const [deleteIndex, setDeleteIndex] = useState({
    question: 0,
    answer: 0
  });

  const [deleteType, setDeleteType] = useState('')

  function setType(value, indexQuestion) {
    const updateQuizData = form;
    updateQuizData.questions[indexQuestion].type = value;
    setForm({...updateQuizData})
  };

  const addQuestion = () => {
    const updateQuizData = form;
    updateQuizData.questions.push({
      title: '',
      type: SIMPLE,
      answers: [{
        value: 'Option A'
      },{
        value: 'Option B'
      },{
        value: 'Option C'  
      }],
      correctAnswers: [0]
    })
    setForm({...updateQuizData})
  };

  const changeNames = (e, index, indexQuestion) => {
    const updateQuizAnswer = form;
    updateQuizAnswer.questions[indexQuestion].answers[index].value = e
    setForm({...updateQuizAnswer})
  };

  const changeQuizTitle = (e) => {
    const updateQuizTitle = form;
    updateQuizTitle.title = e.target.value
    setForm({...updateQuizTitle})
  };

  const changePercentageQuiz = (e) => {
    if(e.target.value <= 0 || e.target.value > 100 || e.target.value === 'e') {
      message.error('Verifique los valores ingresados');
      setStatusPercentage('error');
    } else {
      setStatusPercentage('');
      const updateQuizPercentage = form;
      updateQuizPercentage.percentage = e.target.value;
      setForm({...updateQuizPercentage});
    }
  };

  const changeChanceQuiz = (e) => {
    if(e.target.value <= 0 || e.target.value === 'e') {
      message.error('Verifique los valores ingresados');
      setStatusChance('error');
    } else {
      setStatusChance('');
      const updateQuizChance = form;
      updateQuizChance.chance = e.target.value;
      setForm({...updateQuizChance})
    }
    
  };

  const changeTitle = (e, indexQuestion) => {
    const updateQuizTitle = form;
    updateQuizTitle.questions[indexQuestion].title = e.target.value
    setForm({...updateQuizTitle})
  };

  const addAnswers = (indexQuestion) => {
    const updateQuizAnswers = form;
    updateQuizAnswers.questions[indexQuestion].answers.push({
      value: ''
    })
    setForm({...updateQuizAnswers})
  };

  const setAnswersMultiple = (answers, indexQuestion) => {
    const updateQuizAnswers = form;
    updateQuizAnswers.questions[indexQuestion].correctAnswers = answers
    setForm({...updateQuizAnswers})
  }

  const setAnswersSimple = (answer, indexQuestion) => {
    const updateQuizAnswers = form;
    updateQuizAnswers.questions[indexQuestion].correctAnswers = [answer]
    setForm({...updateQuizAnswers})
  };

  const saveQuiz = () => {
    setIsLoading(true);
    if( form.title.length !== 0 ){
      clientAxios.post('quiz', form)
        .then(response => {
          if(response.data.length !== 0){
            message.success('La quiz fue creada con éxito!');
          }
          setIsLoading(false);
          navigate('/admin/listQuizes');
        })
        .catch((err) => {
          setIsLoading(false);
          message.error('Ha ocurrido un error, quiz ya existente');
        })  
    } else {
      setIsLoading(false);
      message.error('El campo titulo de quiz es obligatorio');
    }
  };

  const deleteData = (type, indexQuestion, index) => {
    showModal();
    setDeleteType(type);
    setDeleteIndex({
      question: indexQuestion,
      answer: index
    });
  };

  const deleteAnswer = () => {
    const updateForm = form
    if(deleteType === 'answer'){
      updateForm.questions[deleteIndex.question].answers.splice(deleteIndex.answer, 1)
      setForm({...updateForm})
    }else{
      updateForm.questions.splice(deleteIndex.question, 1)
      setForm({...updateForm})
    }
    handleOk();
  };

  const updateQuiz = () => {
    setIsLoading(true);
    delete form.createdBy; 
    if(form.title.length !== 0){
      clientAxios.patch(`/quiz/${id}`, form)
        .then(response => {
          if(response.data.length !== 0){
            message.success('La quiz fue modificada con éxito!');
          }
          setIsLoading(false);
          navigate('/admin/listQuizes');
        })
        .catch((err) => {
          setIsLoading(false);
          message.error('Ha ocurrido un error, quiz ya existente');
        })
    } else {
      setIsLoading(false);
      message.error('El campo titulo de quiz es obligatorio');
    }
  };

  return (
    form
    ?
      <>
        <DeleteModal 
          isModalVisible={isModalVisible} 
          showModal={showModal}
          handleOk={deleteAnswer}
          handleCancel={handleCancel}
          deleteType={deleteType}
        />
        <Form layout="vertical">
          <Row justify='center' gutter={[100]} style={{marginTop: '50px'}}>
            <Col span={8}>
              <Form.Item
                label="Titulo de Quiz"
              >
                <Input
                  showCount
                  name="name"
                  maxLength={80}
                  className="rounded-soft"
                  placeholder="Titulo de Quiz"
                  value={form.title}
                  onChange={(e) => changeQuizTitle(e)}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Row>
                <Form.Item
                    label="Porcentaje aprobado"
                    name="percentage"
                    rules={[
                      { 
                        required: true,
                        min: 1,
                        max: 100,
                        maxLength: 2,
                        message: 'El número ingresado debe ser un número de 1 a 100', 
                      }
                    ]}
                  >
                    <Input
                      name="percentage"
                      type='number'
                      defaultValue={!form.percentage ? '90' : form.percentage}
                      onChange={(e) => changePercentageQuiz(e)}
                      status={statusPercentage}
                    />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item
                  label="Número de intentos"
                  name="chance"
                  rules={[
                    { 
                      required: true,
                      min: 1,
                      message: 'El número ingresado debe ser un número entero', 
                    }
                  ]}
                >
                  <Input
                    type='number'
                    defaultValue={!form.chance ? '3' : form.chance}
                    onChange={(e) => changeChanceQuiz(e)}
                    status={statusChance}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col span={2}>
              <Tooltip placement="bottom" title="Agregar pregunta">
                <Button onClick={addQuestion} icon={<PlusCircleOutlined />}></Button>
              </Tooltip>
            </Col>
          </Row>
          <Row justify='center'>
            <Collapse>
              { form.questions.map((question, indexQuestion) => (
                  <Panel style={{width: '50vw'}}
                    header={
                      <Form.Item>
                        <Input 
                          placeholder='Escriba el titulo de la pregunta'
                          value={question.title}
                          className="rounded-soft"
                          onChange={(e) => {
                            changeTitle(e, indexQuestion)
                          }}
                        />
                      </Form.Item>
                    }
                  >
                    <Col>
                      <Form.Item>
                        <Select
                          showSearch
                          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                          placeholder='Seleccionar un tipo'
                          name="type"
                          value={form.questions[indexQuestion].type ? form.questions[indexQuestion].type : SIMPLE}
                          onChange={(e) => {
                            setType(e, indexQuestion)
                          }}
                        >
                          <Option hidden name="type" value="">Seleccione un tipo</Option>
                          <Option name="type" value={SIMPLE}>Una respuesta</Option>
                          <Option name="type" value={MULTIPLE}>Varias respuestas</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    { form.questions[indexQuestion].type === MULTIPLE 
                      ?
                      <>
                        <Col>
                          <Multiple 
                            form={form.questions[indexQuestion]}
                            type='create' 
                            changeNames={changeNames} 
                            indexQuestion={indexQuestion}
                            addAnswers={addAnswers}
                            setAnswers={setAnswersMultiple}
                            showModal={(index) => deleteData('answer', indexQuestion, index)}
                          />
                        </Col>
                      </>
                      :
                      <>
                        <Col>
                          <Simple 
                            form={form.questions[indexQuestion]} 
                            type='create' 
                            changeNames={changeNames} 
                            indexQuestion={indexQuestion}
                            addAnswers={addAnswers}
                            setAnswers={setAnswersSimple}
                            showModal={(index) => deleteData('answer', indexQuestion, index)}
                          />
                        </Col>
                        <Col offset={20}>
                          <Button type='danger' onClick={() => deleteData('question', indexQuestion)}>Eliminar pregunta</Button>
                        </Col>
                      </>
                    }
                  </Panel>
                ))
              } 
            </Collapse>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Col>
              <Button 
                type="primary" 
                onClick={ isUpdating ? updateQuiz : saveQuiz }
                loading={isLoading}
              >
                { isUpdating ? "Actualizar quiz" : "Guardar Quiz"}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    : <Skeleton avatar paragraph={{ rows: 4 }} />
  );
}