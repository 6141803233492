import React, { useState, useEffect } from 'react';
import styles from './modalsCategory.module.css';
import { Button, Modal, Input, message, Divider, List, Popconfirm, Form } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import clientAxios from '../../../config/clientAxios';
import { useNavigate } from 'react-router-dom';

function ListCategory(props) {
  const { reloadCollaps, setReloadCollaps } = props.flag;
  const [isModalVisibleCat, setIsModalVisibleCat] = useState(false);
  const [categories, setCategories] = useState([]);
  const [reloadCategory, setReloadCategory] = useState(false);
  const [inputEdit, setInputEdit] = useState("");
  const category = { name: "", subcategory: [] };
  const navigate = useNavigate();

  const getCategories = () => {
    clientAxios.get('/category')
    .then(response => {
      setCategories(response.data);
    })
    .catch(err => {
      message.error('Ha ocurrido un error. intenta mas tarde');
      navigate('/admin');
    })
  };

  useEffect(() => {
    getCategories();
  }, [reloadCategory])
  

  const showModalCat = () => {
    setIsModalVisibleCat(true);
  };
  
  const createCategory = () => {
    if (category.name.length >= 1 && category.name.length < 100) {
      clientAxios.post('category', { name: category.name, subcategory: category.subcategory })
      .then(response => {
        if(response.status === 201){
          category.name = "";
          message.success('Categoria creada con exito')
          setReloadCategory(!reloadCategory);
          setReloadCollaps(!reloadCollaps);
        }else {
          message.error('Ha ocurrido un error. intenta mas tarde')
        };
      })
      .catch((err) => {
        if (err.response.status === 409) {
          message.error('La categoria ya existe')
        }
      })  
    } else {
      message.warn('Ingresa un nombre para la categoria');
    }
  };

  const deleteCategory = (id) => {
    clientAxios.delete(`category/${id}`)
    .then(response => {
      setReloadCategory(!reloadCategory);
      if (response.status === 200) {
        message.success('Eliminado con exito');
        setReloadCollaps(!reloadCollaps);
      } else {
        message.error('Ha ocurrido un error. intenta mas tarde');
      }
    })
  }

  const editCategory = (id) => {
    if (inputEdit.length >= 1 && inputEdit.length <= 100) {
      clientAxios.patch(`category/${id}`, { name: inputEdit })
      .then(response => {
        setReloadCategory(!reloadCategory);
        if (response.status === 200) {
          message.success('Editado con exito');
          setReloadCollaps(!reloadCollaps);
        } else {
          message.error('Ha ocurrido un error. intenta mas tarde');
        }
      })
    } else {
      message.warn("Completa el campo por favor.")
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModalCat}>
        Administrar categorias
      </Button>
      <Modal title="Agregar Categoria - para cerrar presiona ESC" footer={null} visible={isModalVisibleCat}  onCancel={() => setIsModalVisibleCat(false)}>
        <Input onPressEnter={createCategory} maxLength="25" placeholder="Categoria (Para guardar presiona enter)" onChange={(e) => category.name = e.target.value} prefix={<PlusOutlined />} />
        <Divider />
        <List
        header={<div>Lista de categorias (Para guardar presiona enter)</div>}
        bordered
        dataSource={categories}
        renderItem={item => (
          <List.Item
            className={styles.list}
            actions={[
              <>
                <Popconfirm
                  title="¿Estas seguro que quieres borrar esta categoria completa?"
                  onConfirm={() => deleteCategory(item._id)}
                  >
                  <Button className={styles.buttonDelete} danger><DeleteOutlined /></Button>
                </Popconfirm>
              </>
            ]}
          >
            <List.Item.Meta
              title={
                <Form.Item className={styles.form}>
                  <Input placeholder="Sub categoria" maxLength="25" defaultValue={item.name} onPressEnter={() => editCategory(item._id)} onChange={(e) => setInputEdit(e.target.value)} prefix={ <EditOutlined />} />
                </Form.Item>
              }
            />
          </List.Item>
        )}
        />
      </Modal>
    </>
  )
}

export default ListCategory