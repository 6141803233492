import React, { useState } from "react";
import { Button, Form, Input, Row, Col, message } from "antd";
import { signInWithEmail, auth } from "../../config/firebase";
import clientAxios from "../../config/clientAxios";
import { getAuth, updatePassword } from "firebase/auth";
import { PATTERN } from "../../constants/pattern";

const ChangePassword = ({ userEmail, setCardToRender }) => {
  const [isLoading, setIsLoading] = useState(false);
  const email = userEmail;
  const onFinish = async (values) => {
    setIsLoading(true);
    const { password, newPassword, confirmPassword } = values;
    try {
      const {
        user: { accessToken },
      } = await signInWithEmail(email, password);
      const {
        data: { token, user },
      } = await clientAxios.post("auth/login", {
        accessToken,
      });
      if (user && newPassword === confirmPassword) {
        try {
          const auth = getAuth();
          const user = auth.currentUser;
          updatePassword(user, newPassword)
          .then(() => {
            setIsLoading(false);
            message.success({
              content: "La contraseña fue cambiada con exito",
            });
            setCardToRender("1");
          });
        } catch (error) {
          message.error({
            content:
              "Error, no fue posible actualizar tu contraseña, intenta de nuevo mas tarde",
          });
          setIsLoading(false);
        }
      } else {
        message.error({
          content: "Las Contraseñas no coinciden",
        });
        setIsLoading(false);
      }
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        message.error({
          content: "Verifica tus credenciales",
          duration: 4,
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Row type="flex" justify="start">
      <Col style={{ marginTop: "1.8rem" }}>
        <h1>Cambio de contraseña</h1>
      </Col>
      <Col xs={22} md={22} lg={22}>
        <Form
          layout="vertical"
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                pattern: PATTERN,
                message:
                  "La contraseña debe tener al menos 8 caracteres e incluir un número, una letra mayúscula y un simbolo(#?!@$%^&*-)",
              },
            ]}
          >
            <Input.Password
              maxLength={20}
              autoComplete="off"
              placeholder="Ingresa tu contraseña"
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                pattern: PATTERN,
                message:
                  "La contraseña debe tener al menos 8 caracteres e incluir un número, una letra mayúscula y un simbolo(#?!@$%^&*-)",
              },
            ]}
          >
            <Input.Password
              maxLength={20}
              autoComplete="off"
              placeholder="Ingresa una nueva contraseña"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Confirmar contraseña es obligatorio",
              },
            ]}
          >
            <Input.Password
              maxLength={20}
              autoComplete="off"
              placeholder="Confirmar contraseña"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading} block>
              Cambiar contraseña
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ChangePassword;
