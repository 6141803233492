import { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Form, Input, Button, Space, Row, Col, Select } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { VIMEO_VIDEO, PDF, QUIZ } from '../../../../constants/contentType';
import { PDFAttachment } from "./contentTypes/PDFAttachment";
import { Quiz } from "./contentTypes/Quiz";
import styles from './topics.module.css';
const VIMEO_URL = "vimeo.com/";
const VIDEO_ID_LENGHT = 4;

const Topic = forwardRef(({ chapters, indexChapter, setChapterFormData }, ref) => {
  const { Option } = Select;
  const [videoUrlValidationArray, setVideoUrlValidationArray] = useState([
    false,
  ]);
  const [visible, setVisible] = useState(false);
  const [quizError, setQuizError] = useState({
    icon: "",
    description: "",
  });
  const [chapter, setChapter] = useState();

  useEffect(() => {
    if(chapters) setChapter(chapters[indexChapter]);
  }, [chapters]);

  useImperativeHandle(ref, () => ({
    validate() {
      let errors = []
      chapters[indexChapter].topics.map((topic) => {
        if (topic.contentType === QUIZ && !topic.quizId) {
          let error = {
            icon: "error",
            description: "El campo de nombre de quiz es obligatorio",
          }
          setQuizError(error);
          errors.push(error)
        }
      })
      return errors
    }
  }))
  
  const onFinish = () => {
    const newChapters = chapters;
    newChapters[indexChapter].topics.push({ name: "", contentType: VIMEO_VIDEO, embededUrl: "" });
    setChapterFormData([...newChapters]);
  };

  const setContentType = (values, indexTopics) => {
    let { name, value } = values;
    const updatedChapter = chapters;
    updatedChapter[indexChapter].topics[indexTopics][name] = value;
    setChapterFormData([...updatedChapter]);
  }

  const handleMenuClick = (e) => {
    if (e.key === '3') {
      setVisible(false);
    }
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleUrlChange = (event, indexTopic) => {
    const urlValue = event.target.value ? event.target.value.replace("https://", "") : event.target.value;
    if (urlValue && urlValue.includes(VIMEO_URL)) {
      const vimeoId = urlValue.replace(VIMEO_URL, "");
      vimeoId.length >= VIDEO_ID_LENGHT
        ? checkVimeoUrl(vimeoId, indexTopic)
        : setSpecificInputVideoUrlStatus(false, indexTopic);
    } else {
      setSpecificInputVideoUrlStatus(false, indexTopic);
    }
    updateTopicState(urlValue, indexTopic, "embededUrl");
  };

  const deleteTopic = (indexTopic) => {
    const newChapters = chapters;
    newChapters[indexChapter].topics.splice(indexTopic, 1);
    setChapterFormData([...newChapters]);
  };

  const setSpecificInputVideoUrlStatus = (value, index) => {
    const updatedVideoUrlValidationArray = videoUrlValidationArray;
    videoUrlValidationArray[index] = value;
    setVideoUrlValidationArray([...updatedVideoUrlValidationArray]);
  };

  const updateTopicState = (value, index, fieldName) => {
    const updatedChapter = chapters;
    updatedChapter[indexChapter].topics[index][fieldName] = value;
    setChapterFormData([...updatedChapter]);
  };

  const handleChangeName = (event, index) => {
    updateTopicState(event.target.value, index, "name");
  };

  //Validar URL con API de Vimeo
  const checkVimeoUrl = (id, index) => {
    const videoURL = `https://vimeo.com/${id}`;
    fetch(`https://vimeo.com/api/oembed.json?url=${videoURL}`)
      .then((response) => {
        if (!response.ok) {
          // Invalid URL
          setSpecificInputVideoUrlStatus(false, index);
        } else {
          // valid URL
          setSpecificInputVideoUrlStatus(true, index);
        }
      })
      .catch((err) => {
        // Invalid URL
        setSpecificInputVideoUrlStatus(false, index);
      });
  };

  const setPDFData = (value, index) => {
    const updatedChapter = chapters;
    updatedChapter[indexChapter].topics[index].embededUrl = value;
    setChapterFormData([...updatedChapter]);
  }

  const updateTopicIndex = (topics) => {
    const updatedChapter = chapters;

    updatedChapter[indexChapter].topics = topics;
    setChapterFormData([...updatedChapter]);
  };

  const upLineTopic = (index, topic) => {
    let newArray = [];
    for(let i = 0; i < topic.length; i++) {
      if(i !== index){
        newArray[i] = topic[i];
      } else {
        newArray[i] = topic[i-1];
        newArray[i-1] = topic[i];
      }
    }
    updateTopicIndex(newArray);
  }

  const downLineTopic = (index, topic) => {
    let newArray = [];
    for(let i = 0; i < topic.length; i++) {
      if(i === index){
        newArray[i+1] = topic[i];
        newArray[i] = topic[i+1];
        i++;
      } else {
        newArray[i] = topic[i];
      }
    }
    updateTopicIndex(newArray);
  }

  return (
    <>
      <Form
        name="topics"
        labelCol={{
          span: 10,
        }}
        wrapperCol={{
          span: 14,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {chapter
          ? chapter.topics.map((topic, indexTopics) => {
            console.log(topic, indexTopics, chapter.topics.length);
              return (
                <Row className={styles.containerTopic}>
                  <Col span={3} style={{display: 'flex', justifyContent: 'center'}} >
                    {indexTopics > 0 && indexTopics < chapter.topics.length - 1
                      ?<>
                        <Button type="link">
                          <DownOutlined onClick={() => downLineTopic(indexTopics, chapter.topics)}/>
                        </Button>
                        <Button type="link">
                          <UpOutlined  onClick={() => upLineTopic(indexTopics, chapter.topics)}/>
                        </Button>
                      </>
                      : indexTopics === chapter.topics.length - 1 && indexTopics !== 0 
                        ? <Button type="link">
                            <UpOutlined onClick={() => upLineTopic(indexTopics, chapter.topics)}/>
                          </Button>
                        : indexTopics === 0 && indexTopics !== chapter.topics.length - 1 
                          ? <Button type="link">
                            <DownOutlined onClick={() => downLineTopic(indexTopics, chapter.topics)}/>
                          </Button>
                          : <></>
                    }
                  </Col>
                  <Col span={12} offset={2}>
                    <Form.Item
                      label="Titulo del tema"
                      rules={[
                        {
                          required: true,
                          message: "Ingresa el nombre del tema",
                        },
                      ]}
                    >
                      <Input
                        name={`name-${indexTopics}`}
                        value={topic.name}
                        onChange={(value) =>
                          handleChangeName(value, indexTopics)
                        }
                        maxLength={80}
                        minLength={3}
                      />
                    </Form.Item>
                    <Form.Item
                    label="Tipo de contenido"
                    placeholder="Tipo de contenido"
                    rules={[
                      {
                        required: false,
                        message: "Ingresa la url",
                      },
                    ]}
                    >
                      <Select
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        name="contentType"
                        value={topic.contentType ? topic.contentType : VIMEO_VIDEO}
                        className="selects"
                        defaultActiveFirstOption={true}
                        onChange={(e, values) => {
                          setContentType(values, indexTopics);
                        }}
                        >
                          <Option name="contentType" key={1} value={VIMEO_VIDEO}>Video de Vimeo</Option>
                          <Option name="contentType" key={2} value={PDF}>PDF</Option>
                          <Option name="contentType" key={3} value={QUIZ}>Quiz</Option>
                      </Select>
                    </Form.Item>
                    {
                      topic.contentType === VIMEO_VIDEO ? 
                        <Form.Item
                          label="Video URL https://"
                          placeholder="vimeo.com/id"
                          rules={[
                            {
                              required: true,
                              message: "Ingresa la url",
                            },
                          ]}
                        >
                          <Input
                            name={`embededUrl-${indexTopics}`}
                            value={topic.embededUrl}
                            onChange={(value) =>
                              handleUrlChange(value, indexTopics)
                            }
                            suffix={
                              videoUrlValidationArray[indexTopics] ? (
                                <CheckCircleTwoTone twoToneColor="#52c41a" />
                              ) : (
                                <span />
                              )
                            }
                          />
                        </Form.Item>
                      : topic.contentType === PDF ?
                        <PDFAttachment 
                          setPDFData={(value) => setPDFData(value, indexTopics)} 
                          previousEmbebed={
                            chapter.topics[indexTopics].embededUrl ? 
                            chapter.topics[indexTopics].embededUrl : ''
                          }
                        />
                      : 
                        <Quiz 
                          chapters={chapters}
                          indexChapter={indexChapter}
                          setChapterFormData={setChapterFormData}
                          indexTopics={indexTopics}
                          quizError={quizError}
                          setQuizError={setQuizError}
                        />
                    }
                    <Form.Item>
                      {chapter.topics.length > 1 && (
                        <Button
                          style={{ float: "right", color: "red" }}
                          shape="circle"
                          onClick={() => {
                            deleteTopic(indexTopics);
                          }}
                          icon={<MinusCircleOutlined />}
                        />
                      )}
                    </Form.Item>

                    <Form.Item
                      wrapperCol={{
                        offset: 8,
                        span: 16,
                      }}
                    ></Form.Item>
                  </Col>
                </Row>
              );
            })
          : ""}

        <Col>
          <Row justify="end">
            <Button
              style={{ float: "right" }}
              shape="circle"
              htmlType="submit"
              icon={<PlusCircleOutlined />}
            />
          </Row>
        </Col>
      </Form>
    </>
  );
});

export default Topic;
