import React, { useState, useEffect } from "react";
import Player from "@vimeo/player";
import { Layout, Row, Col, Typography, Button } from "antd";
import styles from "./videoFrame.module.css";
import Vimeo from "@u-wave/react-vimeo";
import courseCover from "../../../assets/img/defaultCover.png";
import {
  setLocalStorage,
  getLocalStorage,
} from "../../../helpers/localStorageHelper";
import { getNativeVideoPlayer } from "../../../helpers/resumeVideo";
import ModalForVideoProgress from "../../ModalForVideoProgress/ModalForVideoProgress";
const { Content } = Layout;
const { Title } = Typography;

function VideoFrame(props) {
  const INIT_VALUE = "686417852";
  const videoId = props.data?.embededUrl?.slice(10);
  const { name, embededUrl } = props.data;
  const cover = props.cover;
  const updateNavigationTree = props.updateNavigationTree;
  const [activeVideoRequiredData, setActiveVideoRequiredData] = useState({
    id: INIT_VALUE,
  });
  const [videoProgress, setVideoProgress] = useState(0);
  const courseId = props.courseId;
  const [timeLapsedIsModalActive, setTimeLapsedIsModalActive] = useState(false);
  function handleOnEnd() {
    updateNavigationTree();
  }

  useEffect(() => {
    preparePlayer();
  }, []);

  function preparePlayer() {
    if (embededUrl) {
      let activeVideoWithId = {
        ...activeVideoRequiredData,
        id: embededUrl.replace("vimeo.com/", ""),
      };
      setActiveVideoRequiredData(activeVideoWithId);
    }
  }

  useEffect(() => {
    preparePlayer();
  }, [embededUrl]);

  const updateVideoProgress = () => {
    setVideoProgress(getLocalStorage("currentVideoProgress")?.time?.seconds);
  };

  const convertSecondsToMinutes = () => {
    let minute = Math.floor((videoProgress / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    let second = videoProgress % 60;
    second = Math.round(second < 10 ? "0" + second : second);
    return minute + ":" + second;
  };

  useEffect(() => {
    updateVideoProgress();
    convertSecondsToMinutes();
  }, []);

  const confirmReturnToPlayedTime = (e) => {
    const player = getNativeVideoPlayer();
    setTimeLapsedIsModalActive(false);
    if (player) {
      player.play();
    }
  };

  const startVideoFromBeginning = (e) => {
    const player = getNativeVideoPlayer();
    if (player) {
      player.setCurrentTime(0);
      player.play();
    }
    setLocalStorage("currentVideoProgress", {
      time: { seconds: 0 },
      idVideo: activeVideoRequiredData.id,
      idCourse: courseId,
    });
    updateVideoProgress();
    setTimeLapsedIsModalActive(false);
  };

  return (
    <Layout>
      <Row gutter={24}>
        <Col span={24}>
          <div className={styles.contVideo}>
            {
              <div className="player-container">
                {embededUrl == null ? (
                  <img
                    alt="portada curso"
                    src={cover || courseCover}
                    className={styles.imgNoVideo}
                  />
                ) : (
                  <>
                    <Title className={styles.title} level={1}>
                      {name}
                    </Title>
                    <Vimeo
                      video={activeVideoRequiredData.id}
                      onEnd={() => handleOnEnd()}
                      onReady={() => {
                        if (
                          getLocalStorage("currentVideoProgress").time.seconds >
                            0 &&
                          getLocalStorage("currentVideoProgress").idVideo ===
                            videoId &&
                          getLocalStorage("currentVideoProgress").idCourse ===
                            courseId
                        ) {
                          setTimeLapsedIsModalActive(true);
                        }
                      }}
                      start={
                        getLocalStorage("currentVideoProgress")?.idVideo ===
                        activeVideoRequiredData.id
                          ? videoProgress
                          : 0
                      }
                      onProgress={(timeValue) => {
                        setLocalStorage("currentVideoProgress", {
                          time: timeValue,
                          idVideo: activeVideoRequiredData.id,
                          idCourse: courseId,
                        });
                      }}
                    />
                    <ModalForVideoProgress
                      openModal={timeLapsedIsModalActive}
                      confirmReturn={confirmReturnToPlayedTime}
                      startVideo={startVideoFromBeginning}
                      handleClickCancel={startVideoFromBeginning}
                      handleClickOK={confirmReturnToPlayedTime}
                      secondsToShow={convertSecondsToMinutes()}
                      title={"Video en Progreso"}
                      closable={false}
                      maskClosable={false}
                      textButtonCancel={"Continuar"}
                      textButtonOK={"Ver desde el comienzo."}
                    />
                  </>
                )}
              </div>
            }
          </div>
        </Col>
        <Col span={24}>
          <div className={styles.cont}>
            <Title className={styles.title} level={2}>
              Contenido📝
            </Title>
          </div>
        </Col>
      </Row>
    </Layout>
  );
}

export default VideoFrame;
