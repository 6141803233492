/*
   If ended video was last video in the chapter, look for next toggle element
   if exist and activate first entry
 */
 function toggleNextChapterAndActivate(activeMenuElement) {
  const liMenuElement = activeMenuElement.parentElement.parentElement;
  if (liMenuElement.nextSibling) {
    const toggableDropdownMenu = liMenuElement.nextElementSibling.children[0];
    if (toggableDropdownMenu) {
      toggableDropdownMenu.click();
      activateFistElementWhenDropDownExpand(toggableDropdownMenu);
    }
  }
};

/*
   If tree element is expand and there is at least one child the fist element will be activated
   given a small delay to allow the children elements to be visible at DOM level
 */
function activateFistElementWhenDropDownExpand(toggableDropdownMenu) {
  if (toggableDropdownMenu) {
    setTimeout(() => {
      if (
        toggableDropdownMenu.nextElementSibling &&
        toggableDropdownMenu.nextElementSibling.hasChildNodes()
      ) {
        toggableDropdownMenu.nextElementSibling.children[0].click();
      }
    }, 10);
  }
};

exports.activateNextVideo = function () {
  let activeMenuElement = document.getElementsByClassName(
    "ant-menu-item ant-menu-item-selected"
  )[0];
  if (activeMenuElement && activeMenuElement.nextSibling) {
    activeMenuElement.nextSibling.click();
  } else if (
    activeMenuElement.parentElement &&
    activeMenuElement.parentElement.parentElement
  ) {
    toggleNextChapterAndActivate(activeMenuElement);
  }
};
