import { React, useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import CourseCard from "../CourseCard/CourseCard";
import { PageHeader, Row, Col, message, Typography, Button, Skeleton, Tabs, Card, Space } from "antd";
import { PlusCircleOutlined } from '@ant-design/icons'
import clientAxios from "../../../config/clientAxios";
import styles from "./coursesList.module.css";
import ROLE from "../../../config/roles";
import emptyBox from '../../../assets/img/explore-draw.svg'; 
import useLiveClassroom from '../../../hooks/useLiveClassroom';
import cover from '../../../assets/img/defaultCover.png';

function CoursesList({ user }) {
  const [coursesList, setCoursesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isContent, setIsContent] = useState(true);
  const [reloadCoursesFlag, setReloadCoursesFlag] = useState(true);
  const { classrooms, setClassrooms, classroomsActive, setClassroomsActive, liveClassRoomLoading } = useLiveClassroom();
  const navigate = useNavigate();
  const { Meta } = Card;
  const { Title } = Typography;
  const titleText = user && user.role === ROLE.ADMIN ? 'Administrar Cursos' : 'Mis Cursos';
  const [activeKey, setActiveKey] = useState(process.env.REACT_APP_PLATFORM_LIVE_COURSES ? "2" : "1");
  const disableOnDemand = process.env.REACT_APP_PLATFORM_LIVE_COURSES ? true : false
  
  useEffect(() => {
    loadCoursesData();
  }, [reloadCoursesFlag]); // eslint-disable-line react-hooks/exhaustive-deps

  function loadCoursesData() {
    clientAxios
      .get('/course')
      .then((response) => {
        setCoursesList(response.data);
       
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 500) {
          message.error("Error...vuelve a intentar luego.");
          navigate("/home");
        } 
        else if (err.response?.status === 404) {
          setIsLoading(false);
          setIsContent(false);
        } 
        else {
          setIsContent(!isContent);
        }
      });
  }

  function closeDropDownFromOuterContainer(event) {
    const dropDownParentElement =
      document.getElementsByClassName("ant-dropdown");

    if (dropDownParentElement && dropDownParentElement.length > 0) {
      for (let i = 0; i < dropDownParentElement.length; i++) {
        dropDownParentElement[i].classList.add("ant-dropdown-hidden");
      }
    }
  }

  const routes = [
    {
      breadcrumbName: 'Admin',
    },
    {
      path: '/admin/course/list',
      breadcrumbName: 'Cursos',
    },
  ];

  const checkClassStatus = (classroom) => {
    if (classroomsActive.find((classRoom) => (classRoom.status === 'suspended' && classRoom._id === classroom._id))) {
      return `#`;
    }
    return `/live/videos/${classroom._id}`;
  }

  return (
    <Row justify="center" onClick={closeDropDownFromOuterContainer}>
      <Col xs={20} md={20} lg={20}>
      { user && user.role === ROLE.ADMIN ? (
        <PageHeader
          className={`site-page-header ${styles.header}`}
          title={titleText}
          breadcrumb={{ routes }}
          extra={[
            <Link to='/admin/course/create'>
            <Button 
              key="pageHeader-1" 
              type='primary' 
              shape='round' 
              icon={<PlusCircleOutlined />}
              className={styles.btnText}
            >
                Crear
            </Button>
              </Link>
            ]}
        />
      )
      :
      <Title level={3}>{titleText}</Title>
      }
    <Tabs className={styles.myCourseTabs} defaultActiveKey={activeKey}> 
      <Tabs.TabPane tab="On demand" key="1"  disabled={disableOnDemand}>
        {isLoading && <Skeleton active avatar paragraph={{ rows: 6 }} style={{minHeight:'100vh'}} ></Skeleton> }
          {isContent ? (
            <div style={{minHeight:'100vh'}} className="site-card-wrapper">
              <Row gutter={[16, 16]} style={{minHeight:'100%'}}>
                {coursesList?.map((courseData, index) => (
                  <Col xs={24} md={12} lg={6} key={`courseCol-${index}`}>
                    <Row justify="center" key={`courseRow-${index}`}>
                      <CourseCard
                        flag={ { setReloadCoursesFlag , reloadCoursesFlag } }
                        updateCourseList={loadCoursesData}
                        key={`courseCard-${index}`}
                        course={courseData}
                        isLoading={isLoading}
                        user={user}
                      />
                    </Row>
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <Row className={styles.message} justify="center" align="middle">
              <Col>
                <img src={emptyBox} alt="course list empty"></img>
                <h2>¿Aún no te inscribiste a ningun Curso?  <Link className={styles.underline} to='/explore'>
                  Explora ahora mismo nuestras ofertas!
                </Link></h2>
              </Col>
            </Row>
          )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="🔴Clases en vivo" key="2">
            <Row 
              justify='center' align='middle'
              style={{
                minHeight: '70vh'
              }} 
            >
              {
              (classrooms && classrooms.length>0 && !liveClassRoomLoading) && ( 
                <>
                    {classrooms?.map((classroom, index)=>
                      (<Col key={index}>
                        <Link to={checkClassStatus(classroom)}>
                          <Card
                          hoverable
                          style={{
                            width: 240,
                            margin: "0.5rem",
                          }}
                          className={classroomsActive && classroomsActive.map((classRoom) => (classRoom.status === 'suspended' && classRoom._id === classroom._id) && styles.disabled)}
                          cover={
                            <img
                            alt='course thumbnail'
                            src={cover}
                            />
                          }
                          >
                            <Meta title={classroom.classroom} description='Curso con clases en vivo' />
                          </Card>
                        </Link>
                      </Col>)
                    )}
                  </>
                  )}
                   {
                       liveClassRoomLoading &&( 
                  
                        <Skeleton active avatar paragraph={{ rows: 6 }} style={{minHeight:'100vh'}} ></Skeleton>
                  )}
                  
                    {
                      !classrooms && !liveClassRoomLoading &&( 
                  
                    <p>No estas inscripto aún en una comision con clases en vivo....</p>
                  )}
        
            </Row>
          </Tabs.TabPane>
          {user.role !== ROLE.ADMIN  &&  (<Tabs.TabPane tab="🏆 Logros y Certificados" key="3">
            <Row 
              justify='center' align='middle'
              style={{
                minHeight: '70vh'
              }} 
            >
              {
              classrooms ? 
                <>
                    <p>Aqui podrás ver tu certificados🎓 de finalizacion de curso y puntos obtenidos🥇!</p>
                  </>
                  : (
                    <p>Aqui podrás ver tu certificados🎓 de finalizacion de curso y puntos obtenidos🥇!</p>
                  )
                }
            </Row>
          </Tabs.TabPane>)}
        </Tabs>
      </Col>
    </Row>
  );
}

export default CoursesList;
