import React from "react";
import Register from "../components/Register/Register";
import { Col, Row } from "antd";

function registerPage() {
  return (
    <>
      <Row justify="center">
        <Col span={12}>
          <div justify="center">
            <Register />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default registerPage;
